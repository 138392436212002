html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, main, nav, section {
  display: block;
}

html {
  overflow-y: scroll;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 15px;
}

body {
  font-weight: 400;
  font-family: YakuHanJP, 'Inter', YuGothic, 'Yu Gothic Medium', 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ ゴシック', sans-serif;
  font-style: normal;
  color: #1F2C5C;
  font-size: 1rem;
  line-height: 1.86;
  letter-spacing: .02em;
  min-width: 320px;
  text-align: left;
  font-variant-ligatures: none;
  font-feature-settings: "palt" 1;
  -webkit-font-feature-settings: "palt" 1;
  overflow-wrap: break-word;
  overflow: hidden;
  -webkit-font-smoothing: antialiased;
}

main {
  position: relative;
  transition: margin-top 0.05s;
}

ol, ul, ol li, ul li {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote {
  &:before, &:after {
    content: '';
    content: none;
  }
}

q {
  &:before, &:after {
    content: '';
    content: none;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;

  tr {
    border-collapse: collapse;
    border-spacing: 0;

    th, td {
      border-collapse: collapse;
      border-spacing: 0;
    }
  }
}

p, h1, h2, h3, h4, h5, h6, ul, ol, li, dl {
  clear: none;
}

hr {
  margin: 0;
  padding: 0;
  border: none;
}

img {
  overflow: hidden;
  vertical-align: bottom;
  margin: 0;
  padding: 0;
  border: none;
  max-width: 100%;
  height: auto;
  line-height: 1em;
  -ms-interpolation-mode: bicubic;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

a {
  color: #1F2C5C;
  margin: 0;
  padding: 0;
  text-decoration: none;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;

  &:hover {
    opacity: .7;
  }

  &:focus {
    outline: auto;
  }
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.js-lazyload {
  min-height: 1px;
}

@media only screen and (max-width: 750px) {
  .pc {
    display: none;
  }
}

.sp {
  display: none;
}

@media only screen and (max-width: 750px) {
  .sp {
    display: inline;
  }
}

.l-loader {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  opacity: 1;
  overflow: hidden;
}

.l-all-wrapper {
  height: 100%;
  overflow: hidden;
}

.l-attention {
  position: relative;

  &.nolink_wrapp {
    background-color: #F08300;
    overflow: hidden;
    height: 30px;
  }

  a {
    background-color: #F08300;
    display: block;
    height: 30px;
  }

  .nolink {
    display: block;
    height: 30px;
    display: -webkit-flex;
    display: flex;
  }
}

.l-attention_inner {
  width: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;

  &:first-child {
    will-change: transform;
    animation: anime-slide1 120s -60s linear infinite;
  }

  &:last-child {
    will-change: transform;
    animation: anime-slide2 120s linear infinite;
  }
}

.l-attention_link {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  overflow: hidden;
  height: 30px;
}

.l-attention_line {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  width: auto;
  position: relative;
}

.l-attention_text {
  display: inline-block;
  font-size: .6666666667rem;
  line-height: 1;
  white-space: nowrap;
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.l-attention_icon {
  display: inline-block;
  vertical-align: top;
  margin: 0 7px;
  font-size: .8rem;
}

.l-attention_close {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 100%;
  cursor: pointer;

  img {
    vertical-align: top;
    margin: 8px 0 0 10px;
  }
}

@keyframes anime-slide1 {
  0% {
    transform: translateX(100%);
  }

  to {
    transform: translateX(-100%);
  }
}

@keyframes anime-slide2 {
  0% {
    transform: translateX(0);
  }

  to {
    transform: translateX(-200%);
  }
}

.l-attention2 {
  position: relative;

  a, .nolink {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    color: #E3E0D9;
    font-size: .8rem;
    line-height: 1.4545454545;
    letter-spacing: .02em;
    display: block;
    width: 100%;
    background-color: #1F2C5C;
    overflow: hidden;
    display: -webkit-flex;
    display: flex;
  }
}

.l-attention2_inner {
  width: auto;
  padding: 7px 60px 7px 75px;
}

@media only screen and (max-width: 750px) {
  .l-attention2_inner {
    padding: 10px 37px 10px 10px;
  }
}

.l-attention2_close {
  position: absolute;
  right: 0;
  top: 0;
  width: 36px;
  height: 100%;
  cursor: pointer;

  img {
    vertical-align: top;
    margin: 8px 0 0 10px;
  }
}

.l-contents-intro {
  position: relative;
  padding: 169px 0 0 0;
  margin-bottom: 170px;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro {
    padding-top: 107px;
    margin-bottom: 98px;
  }
}

.l-contents-intro_inr {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_inr {
    display: block;
  }
}

.l-contents-intro_block {
  width: 26.56%;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_block {
    width: 80.8vw;
    margin: 0 auto;
  }
}

.l-contents-intro_fig-block1 {
  width: 36.72%;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig-block1 {
    width: auto;
  }
}

.l-contents-intro_fig-block2 {
  width: 36.72%;
  align-self: flex-end;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig-block2 {
    width: auto;
  }
}

.l-contents-intro_ttl {
  margin-bottom: 66px;
  font-size: 7.3333333333rem;
  letter-spacing: -.04em;
  line-height: .7454545455;
  text-align: center;
  white-space: nowrap;
}

@media only screen and (max-width: 1200px) {
  .l-contents-intro_ttl {
    font-size: calc((100vw - 376px) / 826 * 60 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_ttl {
    margin-bottom: 56px;
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-intro_ttl {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .8;
  }
}

.l-contents-intro_dtl {
  line-height: 2.375;
  margin-bottom: 59px;
  text-align: center;
  font-size: 1.0666666667rem;
}

@media only screen and (max-width: 1200px) {
  .l-contents-intro_dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 860px) {
  .l-contents-intro_dtl {
    letter-spacing: normal;
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_dtl {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 36px;
    letter-spacing: .02em;
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-intro_dtl {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 2.1428571429;
  }
}

.l-contents-intro_btn {
  width: 100%;
  max-width: 265px;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_btn {
    width: 235px;

    a {
      height: 82px !important;
    }
  }
}

.l-contents-intro_fig01, .l-contents-intro_fig02, .l-contents-intro_fig03, .l-contents-intro_fig04, .l-contents-intro_fig05 {
  position: relative;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig01, .l-contents-intro_fig02, .l-contents-intro_fig03, .l-contents-intro_fig04, .l-contents-intro_fig05 {
    position: absolute;
    top: 108px;
  }
}

.l-contents-intro_fig01 {
  margin-top: 117px;
  width: 23.4375vw;
  height: 33.59375vw;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig01 {
    margin-top: 0;
    width: 18.6666666667vw;
    height: 26.6666666667vw;
    -webkit-transform: translateY(-28%);
    transform: translateY(-28%);
    left: 0;
  }
}

.l-contents-intro_fig02 {
  width: 14.0625vw;
  height: 19.53125vw;
  -webkit-transform: translate(105%, -40%);
  transform: translate(105%, -40%);
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig02 {
    width: 11.2vw;
    height: 15.4666666667vw;
    -webkit-transform: translate(105%, 55.72%);
    transform: translate(105%, 55.72%);
  }
}

.l-contents-intro_fig03 {
  width: 23.4375vw;
  height: 28.4375vw;
  margin-left: auto;
  -webkit-transform: translateY(1.8%);
  transform: translateY(1.8%);
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig03 {
    width: 20vw;
    height: 24.5333333333vw;
    -webkit-transform: translateY(23.73%);
    transform: translateY(23.73%);
    right: 0;
  }
}

.l-contents-intro_fig04 {
  margin-top: 81px;
  width: 25vw;
  height: 35.15625vw;
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig04 {
    margin-top: 0;
    width: 21.3333333333vw;
    height: 29.8666666667vw;
    -webkit-transform: translateY(-25%);
    transform: translateY(-25%);
  }
}

.l-contents-intro_fig05 {
  width: 25vw;
  height: 35.15625vw;
  margin-left: auto;
  -webkit-transform: translateY(5.74%);
  transform: translateY(5.74%);
}

@media only screen and (max-width: 750px) {
  .l-contents-intro_fig05 {
    width: 21.3333333333vw;
    height: 29.8666666667vw;
    -webkit-transform: translateY(-157%);
    transform: translateY(-157%);
    right: 0;
    bottom: 0;
  }
}

.l-contents-large-group {
  position: relative;
  padding: 0 0 128px 0;
  text-align: center;
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group {
    padding-bottom: 65px;
  }
}

.l-contents-large-group_inr {
  position: relative;
  padding: 130px 0 125px 0;
  color: #E3E0D9;
  z-index: 1;

  figure {
    position: relative;
    width: 14.84375vw;
    margin: -68px auto -7.8125vw auto;
    position: relative;
    z-index: 1;

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_inr {
    padding: 85px 0 80px 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_inr figure {
    width: 42.6666666667vw;
    margin: -9.0666666667vw auto -27.2vw auto;
  }
}

.l-contents-large-group_bk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-color: #000;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group .c-para {
    width: 1280px;
    max-width: none;
    left: calc(50% - 640px);
  }
}

.l-contents-large-group_block {
  position: relative;
  width: 42.34375vw;
  min-width: 493px;
  margin: 0 auto;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_block {
    width: 80.8vw;
    min-width: 0;
  }
}

.l-contents-large-group_ttl {
  position: relative;
  font-size: 7.3333333333rem;
  line-height: 1.1;
  letter-spacing: -.03em;
  z-index: 2;
}

@media only screen and (max-width: 1200px) {
  .l-contents-large-group_ttl {
    font-size: calc((100vw - 376px) / 826 * 50 + 60px);
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-large-group_ttl {
    font-size: calc(10px + 13.3333333333vw);
  }
}

.l-contents-large-group_dtl {
  line-height: 2.1428571429;
  padding-bottom: 130px;
  font-size: .9333333333rem;
  z-index: 2;
  letter-spacing: -.02em;
}

.l-contents-large-group_sbttl {
  position: relative;
  font-size: 1.6rem;
  line-height: 1.5833333333;
  margin-bottom: 32px;
  z-index: 2;

  br {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .l-contents-large-group_sbttl {
    font-size: calc((100vw - 376px) / 826 * 0 + 24px);
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_sbttl {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-large-group_sbttl {
    font-size: calc(10px + 3.7333333333vw);
    line-height: 1.3333333333;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_sbttl br {
    display: inline;
  }
}

.l-contents-large-group p {
  position: relative;
  z-index: 2;
  margin: 0 4.765625vw;
  letter-spacing: -.06em;
  line-height: 2;
}

@media only screen and (max-width: 1200px) {
  .l-contents-large-group p {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group p {
    margin-bottom: 36px;
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-large-group p {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.8333333333;
    letter-spacing: .02em;
  }
}

.l-contents-large-group_btn {
  position: absolute;
  bottom: 129px;
  right: 9.375vw;
  text-align: right;

  .c-btn-small_inr {
    line-height: 1.27;
  }

  a {
    color: #E3E0D9;
  }
}

@media only screen and (max-width: 860px) {
  .l-contents-large-group_btn {
    bottom: 90px;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_btn {
    position: static;

    .c-btn-small {
      margin: 0 auto;
    }
  }
}

.l-contents-large-group_list {
  margin: -40px 0 0 0;
  position: relative;
  z-index: 2;

  .c-item-name {
    margin: 26px 0 0 0;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    width: 81.25vw;
    margin: 0 auto;
  }

  li {
    width: 24.42%;
    margin-right: 5.585%;

    &:nth-of-type(2) {
      width: 35.57%;

      .c-item-name {
        font-size: 1.2rem;
        margin: 26px 0 0 0;
      }
    }

    &:nth-of-type(3) {
      width: 28.84%;
      margin-right: 0;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_list {
    margin: -33px 0 0 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_list ul {
    display: block;
    width: auto;
    margin-left: 9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_list li {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_list li:nth-of-type(2) {
    width: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .l-contents-large-group_list li:nth-of-type(2) .c-item-name {
    font-size: calc((100vw - 376px) / 826 * 5 + 13px);
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_list li:nth-of-type(2) .c-item-name {
    font-size: .8666666667rem;
  }
}

.l-contents-large-group_list-btn {
  width: 81.25vw;
  margin: 28px auto 0 auto;

  .c-btn-small {
    margin-left: auto;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_list-btn {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-large-group_list-btn .c-btn-small {
    margin: 0 auto;
  }
}

.l-contents-care {
  width: 100vw;
  padding-bottom: 220px;
}

@media only screen and (max-width: 750px) {
  .l-contents-care {
    width: auto;
    padding-bottom: 70px;
  }
}

.l-contents-care_inr {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: flex-start;
  align-items: flex-start;

  > figure {
    width: 50%;
    height: 53.125vw;
    position: relative;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care_inr {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care_inr > figure {
    width: auto;
    margin-right: 19.4666666667vw;
    height: 85.8666666667vw;
  }
}

.l-contents-care_dtl {
  width: 50%;
  -webkit-transform: translate(-9.375vw, 5.46875vw);
  transform: translate(-9.375vw, 5.46875vw);
  background-color: #1F2C5C;
  padding: 131px 10.9375vw 130px 10.9375vw;
  text-align: center;
  color: #E3E0D9;
  font-size: .8666666667rem;
  line-height: 1.8461538462;

  p {
    margin: 26px 0 35px 0;
  }
}

@media only screen and (max-width: 860px) {
  .l-contents-care_dtl {
    padding: 131px 3.125vw 130px 3.125vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care_dtl {
    position: relative;
    width: auto;
    z-index: 1;
    margin: -53.8666666667vw 0 0 9.6vw;
    -webkit-transform: none;
    transform: none;
    padding: 60px 8.2666666667vw 55px 8.2666666667vw;
  }
}

@media only screen and (max-width: 1200px) {
  .l-contents-care_dtl p {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care_dtl p {
    margin: 21px 0 25px 0;
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-care_dtl p {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.6666666667;
  }
}

.l-contents-care_ttl {
  font-size: 5.3333333333rem;
  line-height: .7375;
  white-space: nowrap;

  .min {
    display: block;
    font-size: .6666666667rem;
    line-height: 1;
    margin-bottom: 26px;
    letter-spacing: .02em;
  }
}

@media only screen and (max-width: 1200px) {
  .l-contents-care_ttl {
    font-size: calc((100vw - 376px) / 826 * 30 + 50px);
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-care_ttl {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .78;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care_ttl .min {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    margin-bottom: 20px;
  }
}

.l-contents-care_btn {
  width: 228px;
  margin: 0 auto;

  .c-btn-middle a {
    height: 82px;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care_btn {
    width: 193px;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care_btn .c-btn-middle a {
    height: 68px;
  }
}

.l-footer {
  color: #E3E0D9;
  background-color: #1F2C5C;
  pointer-events: auto;
}

.l-footer_join {
  position: relative;
  padding: 147px 9.375vw 100px 9.375vw;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: -30px;
    left: 0;
    background-image: url("../img/cover_btm.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join {
    padding: 94px 9.6vw 80px 9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join:after {
    width: 1280px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.l-footer_join-bk {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-bk-pc {
    display: none;
  }
}

.l-footer_join-bk-sp {
  display: none;
}

@media only screen and (max-width: 750px) {
  .l-footer_join-bk-sp {
    display: inline;
  }
}

.l-footer_join-inr {
  position: relative;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .l-footer_join-inr {
    width: auto;
  }
}

.l-footer_join-ttl {
  position: relative;
  font-size: 7.3333333333rem;
  margin-bottom: 40px;
  line-height: 1.06;

  .up {
    line-height: .85;
    display: inline-block;
    -webkit-transform: translateY(-1.4em);
    transform: translateY(-1.4em);
  }

  .min {
    display: block;
    font-size: .6666666667rem;
    line-height: 2;
    margin-bottom: 8px;
    letter-spacing: .02em;
  }
}

@media only screen and (max-width: 1200px) {
  .l-footer_join-ttl {
    font-size: calc((100vw - 376px) / 826 * 50 + 60px);
  }
}

@media only screen and (max-width: 375px) {
  .l-footer_join-ttl {
    font-size: calc(10px + 13.3333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-ttl .min {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    margin-bottom: 10px;
  }
}

.l-footer_join-dtl {
  font-size: .9333333333rem;
  line-height: 1.7142857143;
  margin-top: -180px;
  width: 30.3125vw;
}

@media only screen and (max-width: 1200px) {
  .l-footer_join-dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
    margin-top: -120px;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-dtl {
    margin-top: -106px;
    width: auto;
  }
}

@media only screen and (max-width: 375px) {
  .l-footer_join-dtl {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.6666666667;
  }
}

.l-footer_join-dtl-att {
  margin-top: 11px;
  font-size: .6666666667rem;
  line-height: 1.2;
}

@media only screen and (max-width: 750px) {
  .l-footer_join-dtl-att {
    margin-top: 15px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

.l-footer_join-btn1 {
  margin-top: 39px;
  position: relative;
  z-index: 1;

  .c-btn-large, .c-btn-large_outline {
    width: 280px;
    max-width: 280px;
    min-width: 184px;
  }

  .c-btn-large a {
    height: 110px;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-btn1 {
    margin-top: 34px;

    .c-btn-large {
      width: 202px;

      a {
        height: 74px;
      }
    }

    .c-btn-large_outline {
      width: 202px;
      top: 0;
    }
  }
}

.l-footer_join-btn2 {
  display: table;
  margin-left: auto;
  -webkit-transform: translateY(-86%);
  transform: translateY(-86%);
  position: relative;
  z-index: 2;

  .c-btn-small a {
    color: #E3E0D9;
    font-size: .9333333333rem;
  }

  .c-btn-small_arrow {
    right: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .l-footer_join-btn2 {
    -webkit-transform: none;
    transform: none;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-btn2 {
    width: 69.3333333333vw;
    margin: 34px 0 0 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-btn2 .c-btn-small a {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.6666666667;
  }
}

.l-footer_join-ttl-fig1 {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  float: right;
  position: relative;
  margin-left: 23px;
  max-width: 196px;
  width: 15.3125vw;
  height: 1em;
  z-index: 1;
  margin-top: -1.45em;

  img {
    vertical-align: top;
  }
}

@media only screen and (max-width: 1200px) {
  .l-footer_join-ttl-fig1 {
    vertical-align: -0.18em;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-ttl-fig1 {
    max-width: 137px;
    max-height: none;
    width: 27.4666666667vw;
    margin-left: 10px;
  }
}

.l-footer_join-ttl-fig2 {
  width: 60px;
  height: 60px;
  display: block;
  position: absolute;
  bottom: .18em;
  right: 0;
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
  z-index: -1 !important;

  .c-ico-circle {
    width: 60px;
    height: 60px;
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-ttl-fig2 {
    width: 44px;
    height: 44px;
    right: -1px;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_join-ttl-fig2 .c-ico-circle {
    width: 44px;
    height: 44px;
  }
}

.l-footer {
  .c-ttl-divide {
    display: inline-block;
  }

  .c-btn-small_arrow {
    background-image: url("../img/ico_arrow_w_dig.svg") !important;
    background-size: 13px 13px !important;
  }
}

.l-footer_inr {
  width: 81.25vw;
  margin: 0 auto;
  padding: 122px 0 92px 0;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .l-footer_inr {
    width: auto;
    padding: 63px 9.6vw 49px 9.6vw;
  }
}

.l-footer_nav {
  text-align: right;
  font-size: 4.6666666667rem;
  line-height: .8857142857;

  a {
    color: #E3E0D9;
  }
}

@media only screen and (max-width: 1200px) {
  .l-footer_nav {
    font-size: calc((100vw - 376px) / 826 * 20 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_nav {
    text-align: left;
    margin-bottom: 43px;
  }
}

@media only screen and (max-width: 375px) {
  .l-footer_nav {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .88;
  }
}

.l-footer_logo {
  width: 220px;
  position: absolute;
  bottom: 175px;
  left: 12px;
}

@media only screen and (max-width: 750px) {
  .l-footer_logo {
    position: static;
    width: 37.3333333333vw;
    max-width: 140px;
    margin-bottom: 50px;
  }
}

.l-footer_btm-nav {
  text-align: right;
  font-size: .8rem;
  width: 52.34375vw;
  margin-left: auto;
  margin-top: 1rem;

  a {
    color: #777D95;
  }
}

@media only screen and (max-width: 1200px) {
  .l-footer_btm-nav {
    font-size: calc((100vw - 376px) / 826 * 1 + 11px);
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_btm-nav {
    width: auto;
    text-align: left;
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 375px) {
  .l-footer_btm-nav {
    font-size: calc(10px + .5333333333vw);
  }
}

.l-footer_btm-nav-primary {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 6px;

  li {
    display: inline-block;
    margin-right: 9px;
    line-height: 1;

    &:last-child {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_btm-nav-primary {
    margin-bottom: 66px;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_btm-nav-primary li {
    display: block;
    margin-right: 0;
    line-height: 1.9;
  }
}

.l-footer_btm-nav-sns {
  margin-left: 4.6875vw;
  display: inline-block;
  vertical-align: middle;

  li {
    margin-right: 22px;
    display: inline-block;
    line-height: 1;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    vertical-align: middle;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_btm-nav-sns {
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer_btm-nav-sns li {
    margin-right: 23px;
  }
}

.l-footer {
  .ln {
    width: 20px;
  }

  .nt {
    width: 16px;
  }

  .ig, .tw, .fb {
    width: 18px;
  }

  footer {
    position: absolute;
    bottom: 106px;
    left: 0;
    font-size: .6666666667rem;
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    line-height: 1;
    color: #777D95;
    letter-spacing: normal;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer footer {
    width: 140%;
    position: static;
    -webkit-transform: scale(0.6);
    transform: scale(0.6);
  }
}

.js-megadrop-btn {
  position: relative;
  cursor: pointer;

  &:hover:before {
    content: "";
    display: block;
    width: 120%;
    height: 100px;
    position: absolute;
    top: 0;
    left: -10%;
  }
}

.l-header-group {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;
  -webkit-transform: translateY(0);
  transform: translateY(0);

  img {
    &.white {
      opacity: 1;
      pointer-events: auto;
    }

    &.blue {
      opacity: 0;
      pointer-events: none;
    }
  }

  header {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 140px;
    height: 69px;
    -webkit-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    text-align: center;
    z-index: 10;

    h1 {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    a {
      display: block;
      width: 140px;
      height: 69px;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
}

@media only screen and (max-width: 860px) {
  .l-header-group header {
    width: 120px;
    height: 59.14px;
  }
}

@media only screen and (max-width: 750px) {
  .l-header-group header {
    width: 100px;
    height: 49.28px;
    padding-top: 20px;
  }
}

@media only screen and (max-width: 860px) {
  .l-header-group header a {
    width: 120px;
    height: 59.14px;
  }
}

@media only screen and (max-width: 750px) {
  .l-header-group header a {
    width: 100px;
    height: 49.28px;
  }
}

.js-megadrop--active .l-header-group, .js-search--active .l-header-group {
  -webkit-transform: translateY(0) !important;
  transform: translateY(0) !important;
}

.js-loaderclose {
  &.js-megadrop--active .l-header-group:before, &.js-search--active .l-header-group:before {
    opacity: 1;
  }
}

.l-header-group--und {
  position: relative !important;
}

.l-all-cover {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
}

@media only screen and (max-width: 750px) {
  .l-all-cover {
    display: none;
  }
}

.l-nav_wrapper {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 89px;
  @screen lg {
    height: 150px;
  }
}

.l-nav_primary {
  max-width: 1280px;
  margin: 0 auto;
  color: #E3E0D9;
  line-height: 1;
  font-size: .7333333333rem;
  letter-spacing: normal;
  display: none;
  @screen lg {
    display: block;
  }

  a {
    color: #E3E0D9;

    &:hover {
      opacity: 1;
    }
  }

  ul {
    position: absolute;
    top: 50%;
    left: 4.6875vw;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  li {
    display: inline-block;
    position: relative;
    margin-right: 28px;
    vertical-align: middle;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #E3E0D9;
      position: absolute;
      bottom: -6px;
      left: 0;
      -webkit-transform-origin: right top;
      transform-origin: right top;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
    }

    &:hover:after, &.js-current:after {
      -webkit-transform-origin: left top;
      transform-origin: left top;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }
}


.l-nav_secondary {
  max-width: 1280px;
  margin: 0 auto;
  color: #E3E0D9;
  line-height: 1;
  font-size: .7333333333rem;
  letter-spacing: normal;
  display: none;
  @screen lg {
    display: block;
  }

  a {
    color: #E3E0D9;

    &:hover {
      opacity: 1;
    }
  }

  ul {
    position: absolute;
    top: 50%;
    right: 2.34375vw;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }

  li {
    display: inline-block;
    margin-right: 28px;
    vertical-align: middle;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #E3E0D9;
      position: absolute;
      bottom: -6px;
      left: 0;
      -webkit-transform-origin: right top;
      transform-origin: right top;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
    }

    &:hover:after, &.js-current:after {
      -webkit-transform-origin: left top;
      transform-origin: left top;
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  .search {
    width: 14px;
    cursor: pointer;

    &:after {
      display: none;
      -webkit-transform: none;
      transform: none;
      -webkit-transition: none;
      transition: none;
    }

    &:hover:after {
      -webkit-transform: none;
      transform: none;
      -webkit-transition: none;
      transition: none;
    }
  }

  .cart-num {
    width: 38px;
    text-align: center;
    font-size: .7333333333rem;

    &:after {
      display: none;
      -webkit-transform: none;
      transform: none;
      -webkit-transition: none;
      transition: none;
    }

    &:hover:after {
      -webkit-transform: none;
      transform: none;
      -webkit-transition: none;
      transition: none;
    }

    a {
      display: block;
      height: 24px;
      border: 1px solid #E3E0D9;
      border-radius: 50%;
    }
  }

  .cart-num-count {
    line-height: 22px;
  }
}

.l-nav_nav-cover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s cubic-bezier(0.55, 0.01, 0.45, 1);
  z-index: 60;
}

.js-megadrop--active .l-nav_nav-cover, .js-search--active .l-nav_nav-cover {
  pointer-events: auto;
  opacity: 1;
}

.l-nav_megadrop {
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none;
  max-height: calc(100vh - 104px);
  overflow-y: scroll;
}

.js-megadrop--active .l-nav_megadrop {
  pointer-events: auto;
}

.l-nav_megadrop-inr {
  background-image: url("../img/bg.png");
  background-repeat: repeat;
  background-position: left top;
  background-size: 300px 300px;
  padding: 0 9.375vw;
  position: relative;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  transition: transform 0.2s cubic-bezier(0.55, 0.01, 0.45, 1) 0.2s;
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

.js-megadrop--active .l-nav_megadrop-inr {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  transition: transform 0.3s cubic-bezier(0.55, 0.01, 0.45, 1);
}

.l-nav_megadrop-inr {
  &:after {
    content: "";
    display: block;
    width: 118.75vw;
    height: 1px;
    position: absolute;
    top: 0;
    left: -9.375vw;
    margin: 0 -9.375vw;
    background-color: #DCD8D0;
  }

  p {
    font-size: 1.8666666667rem;
    margin-bottom: 20px;
    line-height: 1;
  }
}

.l-nav_megadrop-inr2 {
  padding: 0 0 79px 0;
  display: flex;
  flex-wrap: wrap;
  width: auto;
  transition: transform 0.2s cubic-bezier(0.55, 0.01, 0.45, 1), opacity 0.1s cubic-bezier(0.55, 0.01, 0.45, 1);
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
}

.js-megadrop--active .l-nav_megadrop-inr2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.3s cubic-bezier(0.55, 0.01, 0.45, 1) 0.2s, opacity 0.3s cubic-bezier(0.55, 0.01, 0.45, 1) 0.2s;
}

.l-nav_megadrop-main {
  -webkit-flex: 1;
  flex: 1;
  margin-right: 12.96875vw;
  padding: 43px 0 0 0;

  > ul {
    padding: 0 0 29px 0;
    border-bottom: 1px solid #BFBCB4;
    font-size: 1.0666666667rem;
    display: flex;
    flex-wrap: wrap;

    li {
      width: 33.14%;
      margin-right: .29%;

      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }

  a {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .l-nav_megadrop-main > ul {
    font-size: calc((100vw - 376px) / 826 * 4 + 12px);
  }
}

.l-nav_megadrop-fig {
  display: block;
  margin-bottom: 18px;
}

.l-nav_megadrop-sub {
  font-size: .8666666667rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 24px;

  ul {
    width: 33.3%;
    padding-right: 20px;
  }

  li {
    margin-bottom: 6px;
  }
}

.l-nav_megadrop-side {
  width: 150px;
  padding: 46px 0 0 0;

  p {
    font-size: 1.4666666667rem;
    margin-bottom: 23px;
  }

  ul {
    font-size: .6666666667rem;
  }

  li {
    margin-bottom: 27px;

    .l-nav_megadrop-fig {
      margin-bottom: 7px;
    }
  }

  a {
    display: block;
  }
}

.l-nav_search {
  position: absolute;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.js-search--active .l-nav_search {
  pointer-events: auto;
}

.l-nav_search-inr {
  background-image: url("../img/bg.png");
  background-repeat: repeat;
  background-position: left top;
  background-size: 300px 300px;
  padding: 0 9.375vw;
  position: relative;
  -webkit-transform: scale(1, 0);
  transform: scale(1, 0);
  transition: transform 0.2s cubic-bezier(0.55, 0.01, 0.45, 1) 0.2s;
  -webkit-transform-origin: center top;
  transform-origin: center top;
}

.js-search--active .l-nav_search-inr {
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
  transition: transform 0.3s cubic-bezier(0.55, 0.01, 0.45, 1);
}

.l-nav_search-inr {
  &:after {
    content: "";
    display: block;
    width: 118.75vw;
    height: 1px;
    position: absolute;
    top: 0;
    left: -9.375vw;
    margin: 0 -9.375vw;
    background-color: #DCD8D0;
  }

  p {
    font-size: 1.8666666667rem;
    margin-bottom: 24px;
    line-height: 1;
  }
}

.l-nav_search-inr2 {
  padding: 1px 0 91px 0;
  display: flex;
  flex-wrap: wrap;
  width: auto;
  transition: transform 0.2s cubic-bezier(0.55, 0.01, 0.45, 1), opacity 0.1s cubic-bezier(0.55, 0.01, 0.45, 1);
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  opacity: 0;
}

.js-search--active .l-nav_search-inr2 {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
  transition: transform 0.3s cubic-bezier(0.55, 0.01, 0.45, 1) 0.2s, opacity 0.3s cubic-bezier(0.55, 0.01, 0.45, 1) 0.2s;
}

.l-nav_search-field {
  position: relative;
  width: 100%;
  padding: 60px 0 52px 0;

  .search_box {
    form {
      position: relative;
    }

    input[type=text] {
      background-color: #DCD8D0;
      border-radius: 6px;
      padding: 30px 20px 30px 75px;
      line-height: 1;

      &:focus {
        outline: none;
      }
    }

    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 75px;
      height: 100%;
      z-index: 1;
      cursor: pointer;

      svg {
        width: 14px;
        height: 15px;
        margin-left: 18px;
        margin-top: 3px;
      }
    }
  }
}

.l-nav_search-btm {
  position: relative;
  width: 81.25vw;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;

  p {
    width: 100%;
    font-size: 1.7333333333rem;
    margin-bottom: 21px;
    line-height: 1;
  }
}

.l-nav_search-sub {
  width: 51.875vw;
  margin-right: 8.59375vw;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    width: 33.13%;
    margin-right: .305%;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

.l-nav_search-side {
  -webkit-flex: 1;
  flex: 1;
  font-size: .8666666667rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: -6px;

  ul {
    width: 50%;
    padding-right: 18px;
  }

  li {
    margin-bottom: 6px;
  }
}

.js-loaderclose {
  .l-header-group {
    transition: transform 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);

    &:before {
      opacity: 0;
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-image: url("../img/bg.png");
      transition: opacity 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
      background-repeat: repeat;
      background-position: left top;
      background-size: 300px 300px;
    }

    header img {
      transition: transform 0.4s cubic-bezier(0.55, 0.01, 0.45, 1), opacity 0.4s;
    }
  }

  .l-nav_wrapper {
    transition: height 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-nav_primary, .l-nav_secondary, .l-nav_primary a {
    transition: color 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-nav_secondary {
    a {
      transition: color 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
    }

    .cart-num a {
      transition: border-color 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
    }

    .cart-num-count {
      transition: color 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
    }

    .search svg path {
      transition: fill 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
    }
  }

  .l-nav-sp_nav-icon svg rect {
    transition: fill 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-nav-sp_nav-cart {
    .cart-num-count {
      transition: color 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
    }

    .cart-num a {
      transition: border-color 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
    }
  }
}

body:not(.js-nav-sp--active) .l-header-group--blue:before, .js-megadrop--active:before, .js-search--active:before {
  opacity: 1;
}

body:not(.js-nav-sp--active) .l-header-group--blue img.white, .js-megadrop--active img.white, .js-search--active img.white {
  opacity: 0;
  pointer-events: none;
}

body:not(.js-nav-sp--active) .l-header-group--blue img.blue, .js-megadrop--active img.blue, .js-search--active img.blue {
  opacity: 1;
  pointer-events: auto;
}

body:not(.js-nav-sp--active) .l-header-group--blue {
  .l-nav_primary, .l-nav_secondary, .l-nav_primary a, .l-nav_secondary a {
    color: #1F2C5C;
  }
}

.js-megadrop--active {
  .l-nav_primary, .l-nav_secondary, .l-nav_primary a, .l-nav_secondary a {
    color: #1F2C5C;
  }
}

.js-search--active {
  .l-nav_primary, .l-nav_secondary, .l-nav_primary a, .l-nav_secondary a {
    color: #1F2C5C;
  }
}

body:not(.js-nav-sp--active) .l-header-group--blue {
  .l-nav_primary li:after, .l-nav_secondary li:after {
    background-color: #1F2C5C;
  }
}

.js-megadrop--active {
  .l-nav_primary li:after, .l-nav_secondary li:after {
    background-color: #1F2C5C;
  }
}

.js-search--active {
  .l-nav_primary li:after, .l-nav_secondary li:after {
    background-color: #1F2C5C;
  }
}

body:not(.js-nav-sp--active) .l-header-group--blue .cart-num a, .js-megadrop--active .cart-num a, .js-search--active .cart-num a {
  border: 1px solid #1F2C5C;
}

body:not(.js-nav-sp--active) .l-header-group--blue .search svg path, .js-megadrop--active .search svg path, .js-search--active .search svg path, body:not(.js-nav-sp--active) .l-header-group--blue .l-nav-sp_nav-icon svg rect, .js-megadrop--active .l-nav-sp_nav-icon svg rect, .js-search--active .l-nav-sp_nav-icon svg rect {
  fill: #1F2C5C;
}

body:not(.js-nav-sp--active) .l-header-group--blue .l-nav-sp_nav-cart .cart-num-count, .js-megadrop--active .l-nav-sp_nav-cart .cart-num-count, .js-search--active .l-nav-sp_nav-cart .cart-num-count {
  color: #1F2C5C;
}

body:not(.js-nav-sp--active) .l-header-group--blue .l-nav-sp_nav-cart .cart-num a, .js-megadrop--active .l-nav-sp_nav-cart .cart-num a, .js-search--active .l-nav-sp_nav-cart .cart-num a {
  border-color: #1F2C5C;
}

.l-header-group--min .l-nav_wrapper, .js-megadrop--active .l-nav_wrapper, .js-search--active .l-nav_wrapper {
  height: 104px;
}

@media only screen and (max-width: 750px) {
  .l-header-group--min .l-nav_wrapper, .js-megadrop--active .l-nav_wrapper, .js-search--active .l-nav_wrapper {
    height: 66px;
  }
}

.l-header-group--min header img, .js-megadrop--active header img, .js-search--active header img {
  -webkit-transform: scale(0.6428571429);
  transform: scale(0.6428571429);
}

.l-nav-sp {
  color: #E3E0D9;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 8;
  -webkit-transform: translateX(-100%);
  transform: translateX(-100%);
  transition: transform 0.3s cubic-bezier(0.55, 0.01, 0.45, 1);
  display: block;
  @screen lg {
    display: none;
  }
}

.js-nav-sp--active .l-nav-sp {
  -webkit-transform: translateX(0);
  transform: translateX(0);
}

.l-nav-sp_wrapper {
  display: block;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  @screen lg {
    display: none;
  }
}

.l-nav-sp_cover {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #1F2C5C;
  width: 100%;
  height: 100%;
}

.l-nav-sp_small {
  background-image: url("../img/bg.png");
  background-repeat: repeat;
  background-position: left top;
  background-size: 300px 300px;
  border-bottom: 1px solid #DCD8D0;
  height: 66px;

  header {
    padding-top: 16px;

    img {
      width: 70px;
    }
  }
}

.l-nav-sp_blue {
  .default svg rect {
    fill: #1F2C5C;
  }

  .cart-num a {
    border: 1px solid #1F2C5C !important;
    color: #1F2C5C !important;
  }
}

.l-nav-sp_nav-icon {
  width: 40px;
  height: 40px;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 8.2666666667vw;
  z-index: 10;
  display: flex;
  flex-wrap: wrap;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  @screen lg {
    display: none;
  }

  .default svg {
    width: 30px;
    height: 13px;
  }

  .close {
    display: none;

    svg {
      width: 12px;
      height: 12px;
    }
  }
}

.js-nav-sp--active .l-nav-sp_nav-icon .close {
  display: inline;
  @screen lg {
    display: none;
  }
}

.js-nav-sp--active .l-nav-sp_nav-icon .default {
  display: none;
}

.l-nav-sp_nav-cart {
  width: 34px;
  position: absolute;
  top: 50%;
  right: 9.6vw;
  z-index: 10;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  display: block;
  @screen lg{
    display: none;
  }

  .cart-num {
    width: 34px;
    text-align: center;
    font-size: .6666666667rem;

    a {
      display: block;
      height: 21px;
      border: 1px solid #E3E0D9;
      border-radius: 50%;
      color: #E3E0D9;
    }
  }

  .cart-num-count {
    line-height: 19px;
  }
}

.l-nav-sp_open {
  position: relative;
  padding: 0 9.6vw 0 9.6vw;

  a {
    color: #E3E0D9;
  }
}

.l-nav-sp_open-inner {
  height: 100%;
  transition: 0.2s padding-top;
}

.l-nav-sp_open-inner2 {
  height: 100%;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.l-nav-sp_primary {
  font-size: 3.3333333333rem;
  line-height: .88;
  margin: 26px 0 60px 0;

  .account {
    margin-top: 30px;
    font-size: .7333333333rem;
    line-height: 1;
  }
}

.l-nav-sp_secondary {
  font-size: .7333333333rem;
  margin-bottom: 50px;

  a {
    color: #777D95;
  }
}

.l-nav-sp_sns {
  margin-bottom: 70px;

  li {
    display: inline-block;
    vertical-align: middle;
    margin-right: 26px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    vertical-align: middle;
  }
}

.l-nav-sp_search-field {
  position: relative;
  width: 100%;
  padding: 0 0 86px 0;

  .search_box {
    color: #E3E0D9;

    form {
      position: relative;
    }

    input {
      &[type=text] {
        background-color: #18234D;
        border-radius: 6px;
        padding: 18px 20px 18px 48px;
        line-height: 1;
        color: #E3E0D9;

        &:focus {
          outline: none;
        }
      }

      &::-webkit-input-placeholder {
        color: #18234D;
      }
    }

    textarea::-webkit-input-placeholder, input:-moz-placeholder, textarea:-moz-placeholder, input::-moz-placeholder, textarea::-moz-placeholder {
      color: #18234D;
    }

    input:-ms-input-placeholder, textarea:-ms-input-placeholder {
      color: #18234D !important;
    }

    button {
      position: absolute;
      top: 0;
      left: 0;
      width: 48px;
      height: 100%;
      z-index: 1;
      cursor: pointer;

      svg {
        width: 14px;
        height: 15px;
      }
    }
  }
}

.cart-num.in a {
  border: 1px solid #F08300 !important;
  color: #1F2C5C !important;
  background-color: #F08300;
}

.l-footer--min {
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 50px;
    position: absolute;
    top: -23px;
    left: 0;
    background-image: url("../img/cover_btm.png");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
  }
}

@media only screen and (max-width: 750px) {
  .l-footer--min:after {
    width: 1280px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}

.l-und_wrap {
  pointer-events: auto;
  position: relative;
  z-index: 2;
  padding-top: 150px;
}

.js-loaderclose .l-und_wrap {
  transition: padding-top .2s;
}

@media only screen and (max-width: 750px) {
  .l-und_wrap {
    padding-top: 90px;
  }
}

hr {
  margin: 0;
  padding: 0;
  border: none;
  width: 100%;
  height: 1px;
  background-color: #BFBCB4;
}

.l-content-description {
  margin: 167px 0 170px 0;
}

@media only screen and (max-width: 750px) {
  .l-content-description {
    margin: 92px 9.6vw 94px 9.6vw;
  }
}

.l-content-description_ttl {
  font-size: 6rem;
  line-height: .7555555556;
  margin-bottom: 82px;
  text-align: center;

  .min {
    display: block;
    font-size: .6666666667rem;
    margin-top: 24px;
    letter-spacing: .02em;
  }
}

@media only screen and (max-width: 1200px) {
  .l-content-description_ttl {
    font-size: calc((100vw - 376px) / 826 * 40 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_ttl {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .l-content-description_ttl {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .8;
  }
}

@media only screen and (max-width: 375px) {
  .l-content-description_ttl .min {
    margin-top: 14px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

.l-content-description_block {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  flex-direction: row-reverse;
  width: calc(100% - 9.375vw);
  margin: 0 0 140px 9.375vw;

  figure {
    background-color: #000;
    width: 45.3125vw;
    height: 46.875vw;
    margin-left: 9.375vw;
    position: relative;
  }

  &:nth-of-type(2n) {
    flex-direction: row;
    margin: 0 9.375vw 140px 0;

    figure {
      margin-left: 0;
      margin-right: 9.375vw;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_block {
    display: block;
    width: auto;
    margin: 0 0 66px 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_block figure {
    width: 80.8vw;
    height: 83.4666666667vw;
    margin: 0 0 31px 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_block:nth-of-type(2n) {
    margin: 0 0 66px 0;
  }
}

.l-content-description_block-rev {
  flex-direction: row;
  margin: 0 9.375vw 140px 0;

  figure {
    margin: 0 9.375vw 0 0;
  }

  &:nth-of-type(2n) {
    flex-direction: row-reverse;
    margin: 0 0 140px 9.375vw;

    figure {
      margin: 0 0 0 9.375vw;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_block-rev {
    margin: 0 0 66px 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_block-rev figure {
    margin: 0 0 31px 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_block-rev:nth-of-type(2n) {
    margin: 0 0 66px 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_block-rev:nth-of-type(2n) figure {
    margin: 0 0 31px 0;
  }
}

.l-content-description_detail {
  -webkit-flex: 1;
  flex: 1;

  p {
    font-size: 1rem;
    line-height: 1.8666666667;

    img {
      margin-bottom: 30px;
    }

    + p {
      margin-top: 30px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_detail p {
    font-size: .9333333333rem;
    line-height: 1.7142857143;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_detail p img {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_detail p + p {
    margin-top: 12px;
  }
}

.l-content-description_detail-ttl {
  font-size: 1.8666666667rem;
  line-height: 1.3571428571;
  margin-bottom: 30px;

  .num {
    font-size: .6666666667rem;
    display: block;
    margin-bottom: 16px;
    line-height: 1.28;
  }
}

@media only screen and (max-width: 1200px) {
  .l-content-description_detail-ttl {
    font-size: calc((100vw - 376px) / 826 * 6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_detail-ttl {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 375px) {
  .l-content-description_detail-ttl {
    font-size: calc(10px + 3.2vw);
    line-height: 1.5;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-description_detail-ttl .num {
    margin-bottom: 5px;
  }
}

.l-content-item-choice {
  margin: 0 9.375vw;
  padding-bottom: 167px;
  display: flex;
  flex-wrap: wrap;

  .c-ttl-large {
    width: 24vw;
    margin-right: 3.125vw;
    line-height: .74;

    .min {
      margin-top: 26px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice {
    display: block;
    margin: 0 0 0 9.6vw;
    padding-bottom: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .l-content-item-choice .c-ttl-large {
    font-size: calc((100vw - 376px) / 824 * 60 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice .c-ttl-large {
    width: auto;
    margin-right: 0;
    margin-bottom: 40px;
    font-size: calc((100vw - 376px) / 824 * 30 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice .c-ttl-large .min {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 375px) {
  .l-content-item-choice .c-ttl-large .min {
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
}

.l-content-item-choice_featured-slide {
  -webkit-flex: 1;
  flex: 1;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    width: 30.17%;
    margin-right: 4.745%;
    text-align: center;

    &:nth-of-type(3) {
      margin-right: 0;
    }

    .c-item-name {
      margin-top: 27px;
    }

    a {
      display: block;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice_featured-slide li {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice_featured-slide li .c-item-name {
    margin-top: 26px;
  }
}

.l-content-item-choice2 {
  margin: 0 9.375vw;
  padding-bottom: 167px;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2 {
    display: block;
    margin: 0 0 0 9.6vw;
    padding-bottom: 100px;
  }
}

.l-content-item-choice2_ttl {
  width: 23.75vw;
  margin-right: 3.125vw;
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2_ttl {
    width: auto;
    margin-right: 0;
    margin-bottom: 40px;
  }
}

.l-content-item-choice2_pc {
  margin-top: 4.6875vw;
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2_pc {
    display: none;
  }
}

.l-content-item-choice2_sp {
  display: none;
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2_sp {
    display: block;
    margin-top: 41px;

    .c-btn-small {
      margin: 0 auto;
    }
  }
}

.l-content-item-choice2 .c-ttl-large {
  line-height: .74;

  .min {
    margin-top: 26px;
  }
}

@media only screen and (max-width: 1200px) {
  .l-content-item-choice2 .c-ttl-large {
    font-size: calc((100vw - 376px) / 824 * 60 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2 .c-ttl-large {
    font-size: calc((100vw - 376px) / 824 * 30 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2 .c-ttl-large .min {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 375px) {
  .l-content-item-choice2 .c-ttl-large .min {
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
}

.l-content-item-choice2_featured-slide {
  -webkit-flex: 1;
  flex: 1;

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    width: 30.17%;
    margin-right: 4.745%;
    text-align: center;

    &:nth-of-type(3) {
      margin-right: 0;
    }

    .c-item-name {
      margin-top: 27px;
    }

    a {
      display: block;
    }

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2_featured-slide li {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2_featured-slide li .c-item-name {
    margin-top: 26px;
  }
}

.l-pagenation {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-top: 148px;
  line-height: 1;

  > {
    .next {
      width: 41px;
      margin: 0 20px 0 auto;
    }

    .prev {
      width: 41px;
      margin: 0 auto 0 20px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }
}

@media only screen and (max-width: 1200px) {
  .l-pagenation {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .l-pagenation {
    width: calc(100% - 4.72%);
    margin: 69px 2.36% 0 2.36%;
    min-height: 27px;
  }
}

@media only screen and (max-width: 375px) {
  .l-pagenation {
    font-size: calc(10px + .5333333333vw);
  }
}

.l-pagenation_num {
  display: inline-block;
  margin: 0 16px;
  vertical-align: middle;

  &.disp {
    border-bottom: 1px solid #1F2C5C;
  }

  &.while {
    margin-top: -.7em;
  }

  a {
    display: inline-block;
    overflow: hidden;
  }
}

@media only screen and (max-width: 750px) {
  .l-pagenation_num {
    margin: 0 10px;
  }
}

.l-main-type1 {
  position: absolute;
  left: calc(50% - 40.625vw);
  bottom: 12.5vh;
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  width: 81.25vw;
  color: #E3E0D9;
  z-index: 2;

  .primary {
    display: block;
    margin: 0 0 17px 0;
    -webkit-flex: 1;
    flex: 1;
    letter-spacing: -.04em;
    font-size: 2.4rem;
    line-height: .8333333333;

    .inr {
      display: block;
      margin: 0 0 22px .8em;
      text-align: left;
    }

    .icon {
      display: inline-block;
      -webkit-transform: translateY(5px);
      transform: translateY(5px);
      font-size: 3.6rem;
      line-height: .7407407407;
      margin: 0 5px 0 0;
    }

    .num {
      display: block;
      font-size: 9.3333333333rem;
      line-height: .6;
    }
  }

  .secondary {
    display: block;
    width: 54.921875vw;
    text-align: right;
    font-size: 2.5333333333rem;
    line-height: 1.4210526316;

    .icon {
      display: inline-block;
      width: 24px;
      height: 18px;
      margin-right: 23px;

      img {
        width: 100%;
        line-height: 1;
        vertical-align: .43em;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-main-type1 {
    bottom: 103px;
    left: 0;
    width: auto;
    display: block;
    margin: 0 9.6vw;
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .l-main-type1 {
    bottom: 60px;
  }
}

@media only screen and (max-width: 750px) {
  .l-main-type1 .primary {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .l-main-type1 .secondary {
    font-size: calc((100vw - 376px) / 826 * 14 + 24px);
  }
}

@media only screen and (max-width: 750px) {
  .l-main-type1 .secondary {
    width: auto;
    text-align: left;
  }
}

@media only screen and (max-width: 375px) {
  .l-main-type1 .secondary {
    font-size: calc(10px + 3.7333333333vw);
    line-height: 1.3333333333;
  }
}

@media only screen and (max-width: 750px) {
  .l-main-type1 .secondary .icon {
    display: block;
    margin-bottom: 15px;
    margin-right: 0;
    width: 4.8vw;
    height: 3.6vw;
    max-width: 18px;
    max-height: 14px;
  }
}

.l-article-other {
  padding-top: 117px;
  font-size: 1.0666666667rem;
  line-height: 2;

  p {
    width: 54.6875vw;
    margin: 0 auto 123px auto;
  }
}

@media only screen and (max-width: 1200px) {
  .l-article-other {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article-other {
    margin: 0 9.6vw 100px 9.6vw;
  }
}

@media only screen and (max-width: 375px) {
  .l-article-other {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.8571428571;
  }
}

@media only screen and (max-width: 750px) {
  .l-article-other p {
    width: auto;
    margin: 0 0 63px 0;
  }
}

.l-article {
  padding-top: 117px;
  font-size: 1.0666666667rem;
  line-height: 2;
  margin-bottom: 192px;
}

@media only screen and (max-width: 1200px) {
  .l-article {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article {
    padding-top: 92px;
    margin: 0 9.6vw 110px 9.6vw;
  }
}

@media only screen and (max-width: 375px) {
  .l-article {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.8571428571;
  }
}

.l-article_ttl {
  width: 54.6875vw;
  margin: 0 auto 48px auto;
  text-align: center;
  font-size: 1.8666666667rem;
  line-height: 1.4285714286;
  font-family: YakuHanJP, 'Inter', "游ゴシック", YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
  font-weight: bold;
}

.l-article {
  h2 {
    width: 54.6875vw;
    margin: 0 auto 48px auto;
    text-align: center;
    font-size: 1.8666666667rem;
    line-height: 1.4285714286;
    font-family: YakuHanJP, 'Inter', "游ゴシック", YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
    font-weight: bold;
  }

  p {
    width: 54.6875vw;
    margin: 0 auto 123px auto;
  }
}

@media only screen and (max-width: 1200px) {
  .l-article_ttl, .l-article h2 {
    font-size: calc((100vw - 376px) / 826 * 6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article_ttl, .l-article h2 {
    width: auto;
    margin: 0 0 31px 0;
    text-align: left;
  }
}

@media only screen and (max-width: 375px) {
  .l-article_ttl, .l-article h2 {
    font-size: calc(10px + 3.2vw);
    line-height: 1.4545454545;
  }
}

@media only screen and (max-width: 750px) {
  .l-article p {
    width: auto;
    margin: 0 0 63px 0;
  }
}

.l-article_intro, .l-article .l-article_txtset:first-child p {
  width: 51.5625vw !important;
}

@media only screen and (max-width: 750px) {
  .l-article_intro {
    width: auto !important;
  }
}

@media only screen and (max-width: 750px) {
  .l-article .l-article_txtset:first-child p {
    width: auto !important;
  }
}

.l-article_large-figure {
  margin-bottom: 140px;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_large-figure {
    margin: 0 -9.6vw 65px -9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_large-figure figure {
    height: 88vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_large-figure img {
    object-fit: cover;
    object-position: center center;
    height: 100%;
  }
}

.l-article_middle-figure {
  margin: 0 7.5vw 140px 7.5vw;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_middle-figure {
    margin: 0 -9.6vw 65px -9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_middle-figure figure {
    height: 88vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_middle-figure img {
    object-fit: cover;
    object-position: center center;
    height: 100%;
  }
}

.l-article_movie {
  margin-bottom: 120px;
}

@media only screen and (max-width: 750px) {
  .l-article_movie {
    margin: 0 0 50px 0;
  }
}

.l-article_img2col {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 131px;

  h2 {
    margin-bottom: 50px;
  }

  p {
    width: auto;
    margin: 0;
  }

  figure {
    width: 45.3125vw;
  }

  .dtl {
    -webkit-flex: 1;
    flex: 1;
  }

  .ttl {
    width: auto;
    text-align: left;
    font-size: 1.8666666667rem;
    line-height: 1.4285714286;
    font-family: YakuHanJP, 'Inter', "游ゴシック", YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
    font-weight: bold;
  }

  &.right {
    flex-direction: row-reverse;
    margin-left: 9.375vw;

    figure {
      margin: 0 0 0 9.375vw;

      img {
        width: 100%;
      }
    }
  }

  &.left {
    margin-right: 9.375vw;

    figure {
      margin: 0 9.375vw 0 0;

      img {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col {
    margin-bottom: 64px;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col p {
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col figure {
    width: auto;
  }
}

@media only screen and (max-width: 1200px) {
  .l-article_img2col .ttl {
    font-size: calc((100vw - 376px) / 826 * 6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col .ttl {
    margin-bottom: 29px;
  }
}

@media only screen and (max-width: 375px) {
  .l-article_img2col .ttl {
    font-size: calc(10px + 3.2vw);
    line-height: 1.4545454545;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col.right {
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col.right figure {
    margin: 0 0 45px 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col.left {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img2col.left figure {
    margin: 0 0 45px 0;
  }
}

.l-article_img3col {
  display: flex;
  flex-wrap: wrap;
  width: 81.25vw;
  margin: 0 auto 88px auto;

  figure {
    width: 30.76%;
    margin: 0 3.86% 70px 0;

    &:nth-of-type(3n) {
      margin-right: 0;
    }

    figcaption {
      margin-top: 11px;
      font-size: .9333333333rem;
      line-height: 2.2857142857;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img3col {
    width: auto;
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img3col figure {
    width: 47.52%;
    margin: 0 4.96% 38px 0;

    &:nth-of-type(3n) {
      margin-right: 4.96%;
    }

    &:nth-of-type(2n) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .l-article_img3col figure figcaption {
    font-size: calc((100vw - 376px) / 826 * 3 + 11px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article_img3col figure figcaption {
    margin-top: 16px;
  }
}

@media only screen and (max-width: 375px) {
  .l-article_img3col figure figcaption {
    font-size: calc(10px + .2666666667vw);
    line-height: 1.4545454545;
  }
}

.l-figure-3img-free {
  padding-bottom: 170px;
  background-image: url("../img/bg_stech2.svg");
  background-repeat: no-repeat;
  background-position: -2.578125vw 10.078125vw;
  background-size: 100% auto;

  figure {
    &:nth-of-type(1) {
      width: 45.3125vw;
      height: 54.6875vw;
      margin-left: auto;
    }

    &:nth-of-type(2) {
      width: 28.90625vw;
      height: 38.28125vw;
      margin-left: 13.30234375vw;
      margin-top: -21.171875vw;
    }

    &:nth-of-type(3) {
      width: 18.75vw;
      height: 25vw;
      margin-left: auto;
      margin-right: 19.51015625vw;
      margin-top: -3.046875vw;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-figure-3img-free {
    padding-bottom: 70px;
    background-position: -62.6vw 8.2vw;
    background-size: 280% auto;
  }
}

@media only screen and (max-width: 750px) {
  .l-figure-3img-free figure:nth-of-type(1) {
    width: 52.2666666667vw;
    height: 62.9333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-figure-3img-free figure:nth-of-type(2) {
    width: 33.3333333333vw;
    height: 44vw;
    margin-left: 0;
    margin-top: -24.5333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-figure-3img-free figure:nth-of-type(3) {
    width: 21.6vw;
    height: 28.8vw;
    margin-right: 22.6666666667vw;
    margin-top: -3.4666666667vw;
  }
}

.l-article-next {
  border-bottom: 1px solid #E3E0D9;

  a {
    position: relative;
    z-index: 1;
    color: #E3E0D9;
    height: 37.5vw;
    max-height: 480px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    text-align: right;
    font-size: 2.1333333333rem;
    line-height: 1.4375;
    padding-right: 15.625vw;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 30px;
      height: 23px;
      background-image: url("../img/ico_arrow_w_large.svg");
      background-repeat: no-repeat;
      background-position: center top;
      background-size: 100% auto;
      position: absolute;
      top: calc(50% - -15px);
      right: 9.375vw;
      -webkit-transition: transform 0.5s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.5s cubic-bezier(0.6, 0.25, 0, 1);
    }

    .l-article-next-inr {
      display: inline-block;
      -webkit-transition: transform 0.5s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.5s cubic-bezier(0.6, 0.25, 0, 1);
      margin-top: 6px;

      .icon {
        width: 19px;
        height: 14px;
        display: inline-block;
        margin-right: 25px;

        img {
          width: 100%;
          vertical-align: .4em;
          line-height: 1;
        }
      }
    }

    &:hover {
      opacity: 1;

      .l-article-next-inr, &:after {
        -webkit-transform: translateX(25px);
        transform: translateX(25px);
      }
    }
  }

  figure {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &:before {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      background-color: rgba(0, 0, 0, 0.4);
    }

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center center;
    }
  }

  .sub {
    display: block;
    margin-bottom: 24px;
    font-size: 2rem;
    line-height: .8;
  }
}

@media only screen and (max-width: 1200px) {
  .l-article-next a {
    font-size: calc((100vw - 376px) / 826 * 12 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article-next a {
    height: 80vw;
    min-height: 300px;
    padding-right: 6.4vw;
  }
}

@media only screen and (max-width: 375px) {
  .l-article-next a {
    font-size: calc(10px + 2.6666666667vw);
    line-height: 1.3;
  }
}

@media only screen and (max-width: 750px) {
  .l-article-next a:after {
    content: "";
    display: block;
    width: 22px;
    height: 16px;
    top: calc(50% - -8px);
    right: 9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-article-next a .l-article-next-inr {
    display: block;
    margin: 0 auto;
    width: 66.6666666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-article-next a .l-article-next-inr .icon {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .l-article-next a:hover .l-article-next-inr {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article-next a:hover:after {
    -webkit-transform: translateX(15px);
    transform: translateX(15px);
  }
}

@media only screen and (max-width: 1200px) {
  .l-article-next .sub {
    font-size: calc((100vw - 376px) / 826 * 14 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .l-article-next .sub {
    margin-bottom: 11px;
  }
}

@media only screen and (max-width: 375px) {
  .l-article-next .sub {
    font-size: calc(10px + 1.6vw);
    line-height: 1.5;
    letter-spacing: -.04em;
  }
}

.l-contents-care--repair {
  padding-bottom: 158px;

  .l-contents-care_inr {
    display: block;
    position: relative;

    > figure {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
    }
  }

  .l-contents-care_dtl {
    width: 52.34375vw;
    padding: 80px 8.59375vw 120px 8.59375vw;
    text-align: left;
    font-size: 1.4666666667rem;
    line-height: 1.0909090909;
    margin-left: auto;

    p {
      margin: 0;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care--repair {
    padding-bottom: 71px;
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care--repair .l-contents-care_inr > figure {
    position: relative;
    height: 85.8666666667vw;
  }
}

@media only screen and (max-width: 860px) {
  .l-contents-care--repair .l-contents-care_dtl {
    padding: 80px 3.125vw 120px 3.125vw;
  }
}

@media only screen and (max-width: 1200px) {
  .l-contents-care--repair .l-contents-care_dtl {
    font-size: calc((100vw - 376px) / 826 * -6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .l-contents-care--repair .l-contents-care_dtl {
    position: relative;
    width: auto;
    z-index: 1;
    margin: -53.8666666667vw 0 0 9.6vw;
    -webkit-transform: none;
    transform: none;
    padding: 42px 9.0666666667vw 55px 8.8vw;
  }
}

@media only screen and (max-width: 375px) {
  .l-contents-care--repair .l-contents-care_dtl {
    font-size: calc(10px + 1.6vw);
  }
}

.js-fadein .js-loader {
  animation: anime-loader-fadein 1s 0s 1 normal both;
}

.js-ready .js-loader {
  opacity: 1;
  height: 0;
}

.js-fadeout .js-loader {
  animation: anime-loader-fadeout 0.5s 0s 1 normal both;
}

.js-fadein .s-top_loader.js-loader {
  animation: anime-loader-fadein 1.2s 1s 1 normal both;
}

@keyframes anime-loader-fadein {
  0% {
    opacity: 1;
    height: 100%;
  }

  99% {
    opacity: 0;
    height: 100%;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

@keyframes anime-loader-fadeout {
  0% {
    opacity: 0;
    height: 100%;
  }

  100% {
    opacity: 1;
    height: 100%;
  }
}

.wpcf7-form-control-wrap .wpcf7-not-valid-tip, .wpcf7-response-output, .sent .form_disp {
  display: none;
}

.wpcf7-form.sent .wpcf7-response-output {
  display: block;
  border: 1px solid #BFBCB4;
  background-color: #DCD8D0;
  font-weight: bold;
  border-radius: 6px;
  font-size: .8rem;
  padding: 20px;
}

input {
  &[type=text], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=password], &[type=submit] {
    display: block;
    width: 100%;
    border-radius: 0;
    border: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none transparent;
    font-weight: 400;
    font-family: YakuHanJP, 'Inter', YuGothic, 'Yu Gothic Medium', 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ ゴシック', sans-serif;
    font-size: 1.0666666667rem;
    line-height: normal;
    color: #1F2C5C;
  }
}

button, textarea, select {
  display: block;
  width: 100%;
  border-radius: 0;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: none transparent;
  font-weight: 400;
  font-family: YakuHanJP, 'Inter', YuGothic, 'Yu Gothic Medium', 'Yu Gothic', 'Hiragino Kaku Gothic ProN', 'Hiragino Kaku Gothic Pro', 'メイリオ', Meiryo, 'ＭＳ ゴシック', sans-serif;
  font-size: 1.0666666667rem;
  line-height: normal;
  color: #1F2C5C;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #1F2C5C;
}

input:-moz-placeholder, textarea:-moz-placeholder, input::-moz-placeholder, textarea::-moz-placeholder {
  color: #1F2C5C;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #1F2C5C !important;
}

input:focus::-webkit-input-placeholder, textarea:focus::-webkit-input-placeholder, input:focus:-moz-placeholder, textarea:focus:-moz-placeholder, input:focus::-moz-placeholder, textarea:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder, textarea:focus:-ms-input-placeholder {
  color: transparent !important;
}

.c-form-select-wrapper {
  position: relative;
  user-select: none;
  font-family: YakuHanJP, 'Inter', "游ゴシック", YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
  font-weight: bold;

  select {
    display: none;
  }
}

.c-form-select select {
  position: relative;
  display: inline-block;
}

.c-form-select-trigger {
  position: relative;
  display: block;
  width: 100%;
  padding: 10px 32px 10px 20px;
  background: transparent;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid #BFBCB4;
  line-height: 1.2;

  &:after {
    position: absolute;
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    top: calc(50% - 5.5px);
    right: 20px;
    border-bottom: 1px solid #1F2C5C;
    border-right: 1px solid #1F2C5C;
    transform: rotate(45deg);
    transition: transform .4s ease-in-out;
    transform-origin: center center;
  }
}

@media only screen and (max-width: 750px) {
  .c-form-select-trigger {
    padding: 13px 32px 10px 20px;
  }
}

.c-form-select-ui.opened .c-form-select-trigger {
  border: 1px solid #1F2C5C;

  &:after {
    transform: rotate(-135deg);
  }
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  min-width: 100%;
  margin: 6px 0 0 0;
  padding: 6px 0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  background: #DCD8D0;
  transition: all .2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: translateY(-15px);
  z-index: 1;
  font-weight: 400;
}

.c-form-select-ui.opened .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: translateY(0);
}

.option-hover:before {
  background: #E3E0D9;
}

.custom-option {
  position: relative;
  display: block;
  padding: 6px 30px;
  color: #1F2C5C;
  cursor: pointer;
  transition: all .4s ease-in-out;

  &:hover, &.selection {
    background: #E3E0D9;
  }
}

.s-item-detail_scrollcart {
  .custom-options {
    top: -15px;
    transform: translateY(calc(-100% + 15px));
  }

  .c-form-select-ui.opened .custom-options {
    top: -15px;
    transform: translateY(-100%);
  }
}

.s-top_loader {
  position: fixed !important;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100000;
  opacity: 1;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  align-items: center;
  background-color: #E3E0D9;

  img {
    width: 50%;
    max-width: 200px;
    transition: opacity 1.2s;
  }
}

.js-loaderclose .s-top_loader img {
  opacity: 0;
}

@keyframes anime-toploader {
  0% {
    opacity: 1;
    height: 100%;
  }

  99% {
    opacity: 0;
    height: 100%;
  }

  100% {
    opacity: 0;
    height: 0;
  }
}

.s-top_main-visual {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  min-height: 600px;
  z-index: 0;

  figure {
    position: relative;
    height: 100%;
    z-index: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 0;
    }

    video, img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual {
    height: 81.28vh;
    height: 81.28 dvh;
    min-height: 560px;
  }
}

.s-top_main-visual-ttl {
  position: absolute;
  bottom: 63px;
  left: 9.84375vw;
  color: #E3E0D9;
  font-size: 12rem;
  line-height: .7;
  letter-spacing: -.04em;
}

@media only screen and (max-width: 1200px) {
  .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 110 + 70px);
    left: 5.3125vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-ttl {
    left: 12.5333333333vw;
    bottom: 25.9852216749vh;
    line-height: .73;
  }
}

@media only screen and (max-width: 375px) {
  .s-top_main-visual-ttl {
    font-size: calc(10px + 16vw);
  }
}

@media only screen and (max-width: 750px) and (max-height: 690px) {
  .s-top_main-visual-ttl {
    bottom: 186px;
  }
}

.s-top_main-visual-ttl--1 {
  margin-left: 16.796875vw;

  .txt-wrapper {
    &:nth-of-type(3) {
      margin-left: -11px;
    }

    &:nth-of-type(4) {
      margin-left: -12px;
    }

    &:nth-of-type(5) {
      margin-left: -11px;
    }

    &:nth-of-type(6) {
      margin-left: -13px;
    }

    &:nth-of-type(7) {
      margin-left: -10px;
    }

    &:nth-of-type(8), &:nth-of-type(9) {
      margin-left: -11px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-ttl--1 {
    margin-left: 9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-ttl--1 .txt-wrapper {
    &:nth-of-type(3) {
      margin-left: -9px;
    }

    &:nth-of-type(4) {
      margin-left: -10px;
    }

    &:nth-of-type(5) {
      margin-left: -9px;
    }

    &:nth-of-type(6) {
      margin-left: -11px;
    }

    &:nth-of-type(7), &:nth-of-type(8) {
      margin-left: -9px;
    }
  }
}

.s-top_main-visual-ttl--2 {
  margin-top: -0.1em;

  .txt-wrapper {
    &:nth-of-type(2) {
      margin-left: -12px;
    }

    &:nth-of-type(3) {
      margin-left: -11px;
    }

    &:nth-of-type(4) {
      margin-left: -17px;
    }

    &:nth-of-type(5) {
      margin-left: -22px;
    }

    &:nth-of-type(6) {
      margin-left: -21px;
    }

    &:nth-of-type(7) {
      margin-left: -10px;
    }

    &:nth-of-type(8), &:nth-of-type(9) {
      margin-left: -11px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-ttl--2 .txt-wrapper {
    &:nth-of-type(1) {
      margin-left: -11px;
    }

    &:nth-of-type(2) {
      margin-left: -10px;
    }

    &:nth-of-type(4) {
      margin-left: -11px;
    }

    &:nth-of-type(5), &:nth-of-type(6) {
      margin-left: -14px;
    }

    &:nth-of-type(9) {
      margin-left: -9px;
    }
  }
}

.s-top_main-visual-ttl--3 {
  margin-left: 26.015625vw;
  margin-top: -0.1em;

  .txt-wrapper {
    &:nth-of-type(4) {
      margin-left: -12px;
    }

    &:nth-of-type(5) {
      margin-left: -11px;
    }

    &:nth-of-type(6) {
      margin-left: -9px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-ttl--3 {
    margin-left: 34.9333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-ttl--3 {
    margin-left: 36vw;

    .txt-wrapper {
      &:nth-of-type(3), &:nth-of-type(4), &:nth-of-type(5) {
        margin-left: -10px;
      }
    }
  }
}

.s-top_main-visual-ttl-row {
  .txt-wrapper {
    display: inline-block;
    overflow: hidden;
    position: relative;
    padding-right: 9px;
    padding-bottom: 1px;
    margin-left: -9px;
    min-width: .3em;
  }

  .txt {
    display: inline-block;
    -webkit-transform: translateY(105%);
    transform: translateY(105%);
    transition: transform 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }
}

.js-loaderclose .s-top_main-visual-ttl-row .txt {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.s-top_main-visual-dtl {
  width: 294px;
  position: absolute;
  bottom: 243px;
  left: calc(9.21875vw + 746px);
  color: #E3E0D9;
  font-size: .6666666667rem;
  line-height: 1.5555555556;
  letter-spacing: 0;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  white-space: nowrap;

  .sp-br {
    display: none;
  }

  span {
    display: block;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: left center;
    transform-origin: left center;
  }
}

@media only screen and (max-width: 1200px) {
  .s-top_main-visual-dtl {
    left: 66.6666666667vw;
    bottom: 20vw;
  }
}

@media only screen and (max-width: 1080px) {
  .s-top_main-visual-dtl {
    bottom: 20.8333333333vw;
  }
}

@media only screen and (max-width: 970px) {
  .s-top_main-visual-dtl {
    bottom: 21.5463917526vw;
  }
}

@media only screen and (max-width: 860px) {
  .s-top_main-visual-dtl {
    width: 230px;
    left: 69.7674418605vw;
    bottom: 20.9302325581vw;
    white-space: normal;
  }
}

@media only screen and (max-width: 810px) {
  .s-top_main-visual-dtl {
    bottom: 21.975308642vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-dtl {
    width: 100%;
    bottom: 18.9655172414vh;
    left: auto;
    right: 0;
    text-align: center;
  }
}

@media only screen and (max-height: 600px) and (min-width: 981px) {
  .s-top_main-visual-dtl {
    top: 345px;
  }
}

@media only screen and (max-width: 750px) and (max-height: 690px) {
  .s-top_main-visual-dtl {
    top: auto;
    bottom: 135px;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-dtl .sp-br {
    display: inline;
  }
}

@media only screen and (max-width: 860px) {
  .s-top_main-visual-dtl .pc-br {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-dtl .pc-br {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-dtl span {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

.js-loaderclose .s-top_main-visual-dtl {
  opacity: 1;
}

.s-top_main-visual-circle {
  width: 80px;
  height: 80px;
  position: absolute;
  top: calc(100vh - 67px);
  left: 27.96875vw;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(81.28vh - 50px);
    top: calc(81.28dvh - 40px);
    left: 9.8666666667vw;
  }
}

@media only screen and (max-width: 750px) and (max-height: 690px) {
  .s-top_main-visual-circle {
    top: 510px;
  }
}

@media only screen and (max-height: 600px) and (min-width: 751px) {
  .s-top_main-visual-circle {
    top: 533px;
  }
}

.js-loaderclose .s-top_main-visual-circle {
  opacity: 1;
}

.s-top_main-visual-btn {
  position: absolute;
  bottom: 76px;
  right: 9.375vw;
  font-size: .8rem;
  line-height: 1;
  min-width: 166px;
  opacity: 0;
  transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);

  .c-btn-large_outline {
    min-width: 166px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-top_main-visual-btn {
    bottom: 30px;
  }
}

@media only screen and (max-width: 980px) {
  .s-top_main-visual-btn {
    right: 30px;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_main-visual-btn {
    bottom: 6.157635468vh;
    right: 50%;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
}

@media only screen and (max-width: 750px) and (max-height: 690px) {
  .s-top_main-visual-btn {
    bottom: 43px;
  }
}

.js-loaderclose .s-top_main-visual-btn {
  opacity: 1;
}

.s-top_main-contents {
  pointer-events: auto;
}

.s-top_main-contents-mainghost {
  position: relative;
  width: 100%;
  height: 100vh;
  pointer-events: none;
}

@media only screen and (max-width: 750px) {
  .s-top_main-contents-mainghost {
    height: 81.28vh;
    height: 81.28 dvh;
  }
}

@media only screen and (max-height: 600px) {
  .s-top_main-contents-mainghost {
    height: 600px;
  }
}

@media only screen and (max-width: 750px) and (max-height: 690px) {
  .s-top_main-contents-mainghost {
    height: 560px;
  }
}

.s-top_featured {
  margin: 0 0 170px 0;
  padding-left: 9.375vw;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .s-top_featured {
    display: block;
    width: auto;
    padding-left: 9.6vw;
    padding-bottom: 66px;
    margin-bottom: 97px;
  }
}

.s-top_featured-intro {
  -webkit-flex: 1;
  flex: 1;
}

.s-top_featured-slide {
  width: 68.1%;
  position: relative;
  margin-top: 10px;

  img {
    width: 100%;
  }

  .slick-nav_arrow {
    height: 60px;
    bottom: -26px;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_featured-slide {
    width: 100%;
    margin-top: 0;
  }
}

.s-top_featured-slide-progress {
  background-color: #BFBCB4;
  position: absolute;
  bottom: 3px;
  left: 0;
  width: 70%;
  height: 1px;
}

@media only screen and (max-width: 750px) {
  .s-top_featured-slide-progress {
    display: none;
  }
}

.s-top_featured-slide-progress-bar {
  background-color: #1F2C5C;
  width: 100%;
  height: 1px;
  transition: transform 0.5s cubic-bezier(0.55, 0.01, 0.45, 1);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.s-top_featured-ttl {
  font-size: 5.3333333333rem;
  line-height: 1;
  margin-bottom: 11.09375vw;
  white-space: nowrap;

  .min {
    display: block;
    font-size: .6666666667rem;
    margin-top: -2px;
    letter-spacing: .02em;
    line-height: 2;
  }
}

@media only screen and (max-width: 1200px) {
  .s-top_featured-ttl {
    font-size: calc((100vw - 376px) / 826 * 30 + 50px);
  }
}

@media only screen and (max-width: 1040px) {
  .s-top_featured-ttl {
    font-size: 3.7rem;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_featured-ttl {
    margin-bottom: 34px;
  }
}

@media only screen and (max-width: 375px) {
  .s-top_featured-ttl {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .8;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_featured-ttl .min {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    margin-top: 9px;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_featured-btn {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;

    .c-btn-small {
      margin: 0 auto;
    }
  }
}

.s-top_behind {
  width: 81.25vw;
  margin: 7px auto 0 auto;
  padding-bottom: 170px;
  position: relative;
}

@media only screen and (max-width: 750px) {
  .s-top_behind {
    width: auto;
    margin: 29px 0 0 9.6vw;
    padding-bottom: 96px;
  }
}

.s-top_behind-ttl {
  width: 34.375vw;
  font-size: 6rem;
  margin-bottom: 63px;
  line-height: .7333333333;
  white-space: nowrap;

  .min {
    display: block;
    font-size: .6666666667rem;
    margin-top: 19px;
    letter-spacing: .02em;
    line-height: 2;
  }
}

@media only screen and (max-width: 1200px) {
  .s-top_behind-ttl {
    font-size: calc((100vw - 376px) / 826 * 40 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-ttl {
    width: auto;
    margin-bottom: 35px;
  }
}

@media only screen and (max-width: 375px) {
  .s-top_behind-ttl {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .76;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-ttl .min {
    margin-top: 12px;
  }
}

.s-top_behind-list {
  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    width: 24.045%;
    margin: -104px 3.84% 0 0;
    position: relative;

    &:nth-of-type(1) {
      width: 38.46%;
      margin: 0 9.61% 0 0;
    }

    &:nth-of-type(3) {
      margin-right: 0;
    }

    a img {
      width: 100%;
    }

    .ttl {
      display: block;
      font-size: 2.4rem;
      line-height: 1;
      margin: 33px 0 19px 0;
      letter-spacing: -.02em;
    }

    .dtl {
      display: block;
      font-size: .8rem;
      line-height: 1.6666666667;
    }

    &.large {
      a {
        color: #E3E0D9;
      }

      figure {
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.2);
          z-index: 0;
        }
      }

      .large-wrap {
        display: block;
        width: 62.5%;
        position: absolute;
        bottom: 50px;
        z-index: 1;
        left: 3.90625vw;
      }

      .ttl {
        display: block;
        font-size: 4.6666666667rem;
        line-height: .7714285714;
        margin: 0 0 24px 0;
      }

      .dtl {
        display: block;
        font-size: .8rem;
        line-height: 1.6666666667;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list ul {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li {
    width: auto;
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li:nth-of-type(1) {
    width: auto;
    margin: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-top_behind-list li .ttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 32px);
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li .ttl {
    margin: 30px 0 18px 0;
    font-size: calc((100vw - 376px) / 374 * 8 + 32px);
  }
}

@media only screen and (max-width: 375px) {
  .s-top_behind-list li .ttl {
    font-size: calc(10px + 5.8666666667vw);
    line-height: .9375;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li .dtl {
    margin-right: 9.0666666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li.large a {
    color: #1F2C5C;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li.large figure:after {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li.large .large-wrap {
    position: static;
    width: 100%;
  }
}

@media only screen and (max-width: 1200px) {
  .s-top_behind-list li.large .ttl {
    font-size: calc((100vw - 376px) / 826 * 38 + 32px);
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-list li.large .ttl {
    margin: 30px 0 18px 0;
    font-size: calc((100vw - 376px) / 374 * 8 + 32px);

    br {
      display: none;
    }
  }
}

@media only screen and (max-width: 375px) {
  .s-top_behind-list li.large .ttl {
    font-size: calc(10px + 5.8666666667vw);
    line-height: .9375;
  }
}

.s-top_behind-btn {
  position: absolute;
  bottom: 170px;
  right: 0;
}

@media only screen and (max-width: 1200px) {
  .s-top_behind-btn {
    bottom: 140px;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_behind-btn {
    position: static;
    margin-top: 38px;
    margin-left: -9.6vw;

    .c-btn-small {
      margin: 0 auto;
    }
  }
}

.s-top_instagram {
  position: relative;
  text-align: center;
  padding-bottom: 170px;

  figure {
    position: absolute;
    overflow: hidden;

    img {
      object-fit: cover;
      object-position: center;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-top_instagram {
    padding: 42.6666666667vw 0 58.9333333333vw 0;
  }
}

.s-top_instagram_1 {
  width: 14.84375vw;
  height: 21.09375vw;
  top: 0;
  left: 14.84375vw;
}

@media only screen and (max-width: 750px) {
  .s-top_instagram_1 {
    width: 22.9333333333vw;
    height: 32.8vw;
    top: 0;
    left: 38.6666666667vw;
  }
}

.s-top_instagram_2 {
  width: 11.71875vw;
  height: 11.71875vw;
  top: 15.390625vw;
  left: 0;
}

@media only screen and (max-width: 750px) {
  .s-top_instagram_2 {
    width: 22.4vw;
    height: 22.4vw;
    top: 15.7333333333vw;
    left: 5.3333333333vw;
  }
}

.s-top_instagram_3 {
  width: 14.0625vw;
  height: 8.59375vw;
  top: 25.78125vw;
  left: 19.53125vw;
}

@media only screen and (max-width: 750px) {
  .s-top_instagram_3 {
    width: 26.6666666667vw;
    height: 16.2666666667vw;
    top: 18.9333333333vw;
    right: 5.3333333333vw;
    left: auto;
  }
}

.s-top_instagram_4 {
  width: 13.28125vw;
  height: 7.8125vw;
  top: 1.5625vw;
  right: 8.59375vw;
}

@media only screen and (max-width: 750px) {
  .s-top_instagram_4 {
    width: 25.6vw;
    height: 15.2vw;
    top: auto;
    bottom: 28vw;
    right: auto;
    left: 6.4vw;
  }
}

.s-top_instagram_5 {
  width: 14.84375vw;
  height: 20.3125vw;
  top: 12.5vw;
  right: 16.40625vw;
}

@media only screen and (max-width: 750px) {
  .s-top_instagram_5 {
    width: 21.8666666667vw;
    height: 29.6vw;
    top: auto;
    bottom: 18.6666666667vw;
    right: 37.0666666667vw;
  }
}

.s-top_instagram_6 {
  width: 10.15625vw;
  height: 8.59375vw;
  top: 18.4375vw;
  right: 0vw;
}

@media only screen and (max-width: 750px) {
  .s-top_instagram_6 {
    width: 18.6666666667vw;
    height: 16vw;
    top: auto;
    bottom: 30.4vw;
    right: 9.3333333333vw;
  }
}

.s-top_instagram-inr {
  width: 29.6875vw;
  margin: 0 auto;
  padding: 5.703125vw 0 6.71875vw 0;
}

@media only screen and (max-width: 750px) {
  .s-top_instagram-inr {
    padding: 0;
    width: auto;
    padding: 0;
  }
}

.s-top_instagram-ttl {
  font-size: 5.3333333333rem;
  line-height: .7375;
  margin-bottom: 36px;
  white-space: nowrap;

  .min {
    display: block;
    font-size: .6666666667rem;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    margin-bottom: 19px;
    letter-spacing: .02em;
    line-height: 2;
  }
}

@media only screen and (max-width: 1200px) {
  .s-top_instagram-ttl {
    font-size: calc((100vw - 376px) / 826 * 30 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .s-top_instagram-ttl {
    margin: 0 0 28px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-top_instagram-ttl {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .76;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_instagram-ttl .min {
    margin-bottom: 15px;
  }
}

.s-top_instagram-btn {
  width: 228px;
  margin: 0 auto;

  .c-btn-middle a {
    height: 82px;
  }

  .c-btn-middle_inr:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
}

@media only screen and (max-width: 750px) {
  .s-top_instagram-btn {
    width: 193px;
  }
}

@media only screen and (max-width: 750px) {
  .s-top_instagram-btn .c-btn-middle a {
    height: 63px;
  }
}
