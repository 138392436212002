[id^=itempage_banner] {

  .slick-dots {
    position: relative;
    bottom: 0;
    @apply mt-[.5rem];
    @apply sm:mt-[1rem];

    li {
      @apply h-[5.5vw];
      @apply w-[16.5vw];
      @apply md:h-[4vw];
      @apply md:w-[12vw];
      margin: 0;

      &:not(:last-child) {
        @apply mr-[0.575vw];
        @apply md:mr-[0.625vw];
      }

      button, button > img {
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}
