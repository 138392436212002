#vmd_silver {
  .l-article {
    padding-top: 1rem;
    margin-bottom: 0;
  }

  .slick-track {
    margin-left: 0;
  }
  .s-top_featured{
    padding-top: 0;
    @apply pb-4 sm:pb-16 md:pb-32;
  }

  .s-top_featured-slide {
    width: 76.1%;
    @media only screen and (max-width: 750px) {
      width: 100%;
    }
  }

  @media only screen and (max-width: 750px) {
    .l-article h2 {
      text-align: center;
    }
  }
  .c-item-price, .c-item-soldout{
    @apply text-xs;
    .off{
      @apply text-[.5rem];
    }
  }
}
