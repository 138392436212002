#vmd_tshirt {
  .l-article_text {
    & > * {
      overflow-wrap: break-word;
      word-break: keep-all;
    }
  }
  &.l-content-item-lineup {
    margin: 0 9.375vw;
    @apply pb-28 md:pb-44;
    display: flex;
    flex-wrap: wrap;
  }

  .l-content-item-choice2_featured-slide li {
    width: 23.875%;
    margin-right: 1.5%;

    &:nth-of-type(4n) {
      margin-right: 0;
    }

    .c-item-name {
      margin-top: 2rem;
    }

    .c-item-price, .c-item-soldout {
      margin-top: 1rem;
      margin-bottom: 2rem;
    }

    @media only screen and (max-width: 750px) {
      width: 47.5%;
      margin-right: 5%;

      &:nth-of-type(2n) {
        margin-right: 0;
      }
    }
  }
  .c-item-price, .c-item-soldout{
    @apply text-xs;
    .off{
      @apply text-[.5rem];
    }
  }
}
