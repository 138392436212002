#cart {
  table#cart_table {
    th, td {
      &.stock {
        @apply hidden;
      }

      select.quantity {
        @apply mr-4;
        @apply md:mr-6;
      }
    }
  }
}


.fth-cart-button {
  margin: 0;

  &:hover {
    @apply text-[#292f33];
  }
}

.s-usces .send.horizontal {
  @apply justify-center;
}

#cart_checkout_box {
  .amazon-next {
    flex-flow: column-reverse !important;

    .cart-buttons {
      @apply justify-center;
    }

    .text h4 {
      @apply hidden;
    }
  }

  .cart-next:not(.amazon-next) .text {
    display: none !important;
  }

  .cart-buttons {
    input {
      @apply mx-auto;
      flex: 1 1;
      padding: 0.75rem 1.5rem;
    }
  }
}

#wc_confirm {
  .login-btn-container {
    #backDelivery {
      @apply py-4;
    }
  }
}

.usces_content {
  .ui {
    background: none;
  }

  button.ui {
    @apply border border-solid border-fth-blue text-fth-blue fth-cart-button;
    &:hover {
      @apply bg-fth-bg;
      background-image: none;
    }
  }

  .update-payment-method-container {
    justify-content: start !important;
    @apply text-sm;
  }

  .back-or-confirm-container .buttons, .login-btn-container {
    flex-wrap: wrap !important;
    flex-direction: column-reverse !important;
    @screen md {
      flex-wrap: nowrap !important;
      flex-direction: row !important;
    }
    @apply gap-8 w-full md:w-3/4;
    > * {
      @apply flex-shrink grow basis-16 md:basis-full;
    }

    .ui.button, button.ui {
      @apply w-full fth-cart-button;
    }

    [name=backDelivery] {
      @apply bg-fth-bg border border-fth-blue text-fth-blue;
      &:hover {
        @apply bg-none;
      }
    }

    #purchase_button {
      @apply bg-fth-orange border-none py-6;
      &:hover {
        @apply bg-none;
      }
    }

    #AmazonPayButton {
      @apply my-2;
    }
  }
}
