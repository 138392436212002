#aginggallery {

    // wrap
    .section-box-wrap {
        @apply mb-24 sm:mb-32;
    }

    .subsection-box-wrap {
        @apply mb-16 sm:mb-20;
    }

    .box-wrap {
        @apply mb-8 sm:mb-12;
    }

    .box-inner-wrap {
        @apply mb-4 sm:mb-8;
    }

    .block-wrap {
        @apply mb-1 xl:mb-2;
    }

    .img-default {
        @apply w-screen lg:w-full;
        @apply h-auto;
    }

    .flex-half {
        @apply basis-full sm:basis-1/2;
    }

    .responsive-max-width {
        @apply sm:mx-auto;
        @apply max-w-full sm:max-w-2xl md:max-w-3xl lg:max-w-4xl xl:max-w-6xl;
    }

    // 商品詳細ボタン
    .aginggallery_item-button-wrap {
        @apply text-center;
        @apply 2xl:max-w-xl;
    }

    .aginggallery_item-button {
        @apply rounded-lg;
        @apply bg-fth-orange;
        @apply c-font-bold;
        @apply block;
        @apply border-none;
        @apply text-xl;
        @apply py-4 px-0 md:px-1 lg:px-6;
        @apply min-w-fit md:min-w-[16rem] lg:min-w-[21.5rem];
        &.end {
            @apply bg-inherit;
            @apply border border-solid border-gray-400;
        }
    }

    .aginggallery-information_y-scroll {
        @apply sticky;
        @apply top-40;
    }

    // 商品詳細の案内フッター
    .aginggallery-footer {
        background-color: #e3e0d9;
        border-top: 1px solid #dcd8d0;
        @apply fixed;
        @apply bottom-0 left-0;
        @apply overflow-hidden;
        @apply z-50;
        @apply mb-0;
        @apply py-2 px-0;
        @apply w-full;
        @apply origin-center origin-top;
        transform: translateY(100%);
        transition: transform .4s cubic-bezier(.6, .25, 0, 1);
    }

    .disp {
        transform: translateY(0);
        transition: transform .4s cubic-bezier(.6, .25, 0, 1);
    }

    // スクロールする際にはみ出るので初期化
    .l-footer_join:after {
        left: 0;
        width: 0;
    }

    @screen sm {
        // 縦スクロール時の商品画像と商品情報のコンテンツの位置を逆にする調整
        .s-item-detail_iteminfo-left {
            @apply left-[9.325vw];
        }

        .s-item-detail_header-margin-right {
            @apply mr-0;
        }

        .s-item-detail_fig-margin-left {
            @apply ml-auto;
            @apply mr-0;
        }

        .js-cart-cloned {
            @apply left-[9.325vw];
        }
    }
}

#aginggallery-archive {
    // wrap
    .section-box-wrap {
        @apply mb-24 sm:mb-32;
    }

    .subsection-box-wrap {
        @apply mb-8 sm:mb-12 lg:mb-20;
    }

    .box-wrap {
        @apply mb-8 sm:mb-12;
    }

    .box-inner-wrap {
        @apply mb-4 sm:mb-8;
    }

    .block-wrap {
        @apply mb-2;
    }

    .img-default {
        @apply w-screen lg:w-full;
        @apply h-auto;
    }

    .flex-half {
        @apply basis-full sm:basis-1/2;
    }

    .responsive-max-width {
        @apply sm:mx-auto;
        @apply max-w-full sm:max-w-xl md:max-w-2xl lg:max-w-4xl xl:max-w-6xl;
    }

    // イタリック文字が切れて表示されるので対応
    #aginggallery-archive-term-header .js-textrowmotion-wrapper {
        overflow-x: visible;
        padding-right: 1.1rem;
        margin-right: -1rem;
    }

    .item-wrap {
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(32, 44, 92, 0);
            transform: translateY(-50%) scale(.9);
            transition: background-color .3s linear, transform .4s cubic-bezier(.19, 1, .22, 1);
        }

        &:hover {
            opacity: 1;

            &:before {
                background-color: rgba(32, 44, 92, .2);
                transform: translateY(-50%) scale(1);
            }
        }
    }
}

#aginggallery-archive {
    .js-textrowmotion-wrapper .js-textrowmotion-txt {
        min-width: 0.5rem;
    }

    #aginggallery-archive-nav-fixed {
        display: none;
        transition: .4s;

        &.disp {
            @apply block sm:hidden;
            animation: show .4s linear 0s;
        }

        @keyframes show {
            from {
                height: 0;
                opacity: 0;
            }
            to {
                height: calc(30px + 2rem);
                opacity: 1;
            }
        }

        .s-journal_sort-wrap {
            padding-top: 2rem;
        }
    }
}
