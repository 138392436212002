#cart_recommend {
  .l-slider-4item {
    @apply pb-8;
    .slick-list {
      @apply max-md:pr-[40vw];
    }

    .slick-nav_next {
      right: 0;
    }

    .slick-nav_prev {
      right: 5rem;
    }
  }
}
