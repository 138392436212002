$color_1: #F08300;
$color_2: #fff;
$color_3: #E3E0D9;
$color_4: #f08300;
$color_5: #777;
$color_6: #e00;
$color_7: #222;
$color_8: #1f2c5c;
$color_9: #f00;
$color_10: #555;
$font-family_1: YakuHanJP, 'Inter', "游ゴシック", YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
$font-family_2: YakuHanJP, 'Inter', YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
$font-family_3: aktiv-grotesk-extended, sans-serif;
$font-family_4: moret, serif;
$font-family_5: "FontAwesome";
$font-family_6: YakuHanJP, "Inter", "游ゴシック", YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
$background-color_1: rgba(201, 201, 201, 0.3);
$background-color_2: #F08300;
$background-color_3: #e3e0d9;
$background-color_4: #1F2C5C;
$background-color_5: #dcd8d0;
$background-color_6: #f08300;
$background-color_7: #fff;
$background-color_8: #000;
$background-color_9: #1f2c5c;
$background-color_10: #fac4c4;
$border-color_1: #000;

@keyframes disp-block {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes circle-rotation {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes c-str1 {
  0% {
    stroke-dashoffset: 1102px;
  }
  50% {
    stroke-dashoffset: 1653px;
  }
  100% {
    stroke-dashoffset: 2204px;
  }
}

@keyframes c-str2 {
  0% {
    stroke-dashoffset: 1180px;
  }
  50% {
    stroke-dashoffset: 1770px;
  }
  100% {
    stroke-dashoffset: 2360px;
  }
}

@keyframes c-str3 {
  0% {
    stroke-dashoffset: 196px;
  }
  50% {
    stroke-dashoffset: 294px;
  }
  100% {
    stroke-dashoffset: 392px;
  }
}

@keyframes anime-noise {
  0% {
    transform: translate3d(0, 9%, 0);
  }
  20% {
    transform: translate3d(-8%, 2%, 0);
  }
  40% {
    transform: translate3d(-2%, 7%, 0);
  }
  60% {
    transform: translate3d(2%, 5%, 0);
  }
  80% {
    transform: translate3d(-9%, 1%, 0);
  }
  100% {
    transform: translate3d(-3%, 3%, 0);
  }
}

b {
  font-family: $font-family_1;
  font-weight: bold;
}

.c-font-bold {
  font-family: $font-family_1;
  font-weight: bold;
}

.c-font-normal {
  font-weight: 400;
  font-family: $font-family_2;
}

.c-font-gr {
  font-family: $font-family_3;
  font-weight: 700;
  font-style: normal;
}

.c-font-en {
  font-family: $font-family_4;
  font-weight: 300;
  font-style: normal;
  letter-spacing: -.03em;
}

.c-ib {
  display: inline-block;
}

.c-ws {
  white-space: nowrap;
}

::selection {
  background-color: $background-color_1;
}

.c-ico-circle {
  display: block;
  animation: 20s linear infinite circle-rotation;
  z-index: 1;
  -webkit-transform-origin: center center;
  transform-origin: center center;

  img {
    width: 100%;
    vertical-align: top;
  }
}

.c-item-name {
  display: block;
  font-size: .9333333333rem;
  line-height: 1.4285714286;
}

.c-item-price {
  display: block;
  font-size: .6666666667rem;
  line-height: 1;
  margin-top: 12px;
}

.c-item-soldout {
  display: block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.c-item-off {
  color: $color_1;

  .off {
    display: inline-block;
    background-color: $background-color_2;
    border-radius: 4px;
    padding: 3px 5px 2px 5px;
    line-height: 1.2;
    color: $color_2;
    margin-right: 6px;
  }
}

.c-ttl-divide {
  display: block;

  &:first-child {
    margin-top: 0;
  }
}

.c-btn-large {
  max-width: 240px;
  width: 18.75vw;

  a {
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 99px;
    margin: 4px 6px 4px 7px;
    background-color: $background-color_2;
    font-size: .8rem;
    line-height: 1;
    border-radius: 50%;
    position: relative;

    &:hover {
      opacity: 1;
    }
  }

  &:hover {
    svg {
      animation: 0.6s cubic-bezier(0.2, 0.8, 0.5, 0.9) c-str1;
    }
  }
}

.c-btn-large_inr {
  display: inline-block;
  position: relative;
  padding-right: 22px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    right: 0;
    background-image: url("../img/ico_arrow_b.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100% auto;
  }
}

.c-btn-large_outline {
  position: absolute;
  top: 0;
  left: -7px;
  max-width: 240px;
  width: 18.75vw;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-dasharray: 551px;
    stroke-dashoffset: 1653px;
    transition: stroke-dashoffset 0.4s cubic-bezier(0.55, 0.01, 0.45, 1) 0.5s;
  }
}

.js-loaderclose {
  .c-btn-large_outline {
    svg {
      stroke-dashoffset: 1102px;
      transition: none;
    }
  }
}

a {
  .c-hov {
    display: inline-block;
    padding: .25em 0;
    position: relative;
    -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    -webkit-transform: translateY(0);
    transform: translateY(0);

    &:after {
      content: attr(data-txt);
      display: inline-block;
      padding: .25em 0;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transform: translateY(104%);
      transform: translateY(104%);
    }
  }

  &:hover {
    .c-hov {
      -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      -webkit-transform: translateY(-104%);
      transform: translateY(-104%);
    }
  }
}

.s-item-detail_item-cart-btn {
  .c-hov {
    display: inline-block;
    padding: .25em 0;
    position: relative;
    -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    -webkit-transform: translateY(0);
    transform: translateY(0);

    &:after {
      content: attr(data-txt);
      display: inline-block;
      padding: .25em 0;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transform: translateY(104%);
      transform: translateY(104%);
    }
  }

  &:hover {
    .c-hov {
      -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      -webkit-transform: translateY(-104%);
      transform: translateY(-104%);
    }
  }
}

.s-item-detail_item-trying_inr {
  .c-hov {
    display: inline-block;
    padding: .25em 0;
    position: relative;
    -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    -webkit-transform: translateY(0);
    transform: translateY(0);

    &:after {
      content: attr(data-txt);
      display: inline-block;
      padding: .25em 0;
      position: absolute;
      bottom: 0;
      left: 0;
      -webkit-transform: translateY(104%);
      transform: translateY(104%);
    }
  }
}

.s-item-detail_item-trying {
  &:hover {
    .c-hov {
      -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      -webkit-transform: translateY(-104%);
      transform: translateY(-104%);
    }
  }
}

.s-item-detail_item-accept {
  &:hover {
    .c-hov {
      -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      -webkit-transform: translateY(-104%);
      transform: translateY(-104%);
    }
  }
}

.c-btn-middle {
  a {
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 102px;
    font-size: .7333333333rem;
    line-height: 1;
    border-radius: 50%;
    position: relative;

    &:hover {
      opacity: 1;

      .c-btn-middle_outline {
        svg {
          path {
            &:nth-of-type(1) {
              animation: 0.6s cubic-bezier(0.2, 0.8, 0.5, 0.9) c-str2;
            }
          }
        }
      }
    }
  }
}

.c-btn-middle_inr {
  display: inline-block;
  position: relative;
  padding-right: 22px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    right: 0;
    background-image: url("../img/ico_arrow_b.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100% auto;
  }
}

.c-btn-middle_outline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;

    path {
      &:nth-of-type(1) {
        width: 100%;
        height: 100%;
        fill: none;
        stroke-dasharray: 590px;
        stroke-dashoffset: 1180px;
      }
    }
  }
}

.c-btn-middle--w {
  a {
    color: $color_3;
  }

  .c-btn-middle_inr {
    &:after {
      background-image: url("../img/ico_arrow_w.svg");
    }
  }
}

.c-btn-small {
  display: table;

  a {
    position: relative;
    display: inline-block;
    font-size: .7333333333rem;
    line-height: 1;

    &:hover {
      opacity: 1;

      .c-btn-small_outline {
        svg {
          path {
            &:nth-of-type(1) {
              animation: 0.6s cubic-bezier(0.2, 0.8, 0.5, 0.9) c-str3;
            }
          }
        }
      }
    }
  }
}

.c-btn-small_inr {
  display: inline-block;
  position: relative;
  padding-left: 55px;
  overflow: hidden;
}

.c-btn-small_arrow {
  display: block;
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
  width: 41px;
  height: 25px;
  background-image: url("../img/ico_arrow_b.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 8px;
}

.c-btn-small_outline {
  display: block;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;

    path {
      &:nth-of-type(1) {
        width: 100%;
        height: 100%;
        fill: none;
        stroke-dasharray: 98px;
        stroke-dashoffset: 196px;
        transition: stroke-dashoffset 0.4s cubic-bezier(0.55, 0.01, 0.45, 1);
      }
    }
  }
}

.c-btn-small--w {
  .c-btn-small_arrow {
    background-image: url("../img/ico_arrow_w.svg");
  }
}

.c-btn-small--r {
  .c-btn-small_inr {
    padding-right: 61px;
    padding-left: 0;
  }

  .c-btn-small_arrow {
    left: auto;
    right: 0;
  }
}

.c-cover {
  position: absolute !important;
  -webkit-mask-position: center top;
  mask-position: center top;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  top: calc(100vh - 16px);
  left: 0;
  width: 100%;
  height: 33.5px;
  -webkit-mask-image: url("/asset/img/mv_btm.png");
  mask-image: url("/asset/img/mv_btm.png");
}

.c-cover-sp {
  display: none;
}

.c-bk {
  position: relative;
  @extend .default_background;
}

.c-para {
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: calc(50%);
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    left: 0;
  }
}

.c-text-line {
  a {
    display: inline-block;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: $background-color_4;
      position: absolute;
      bottom: -6px;
      left: 0;
      -webkit-transform-origin: right top;
      transform-origin: right top;
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
      -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
    }

    &:hover {
      &:after {
        -webkit-transform-origin: left top;
        transform-origin: left top;
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }
  }
}

.c-ttl-max {
  font-size: 6rem;
  line-height: .7555555556;
  text-align: center;

  .min {
    display: block;
    font-size: .6666666667rem;
    letter-spacing: .02em;
    margin-top: 28px;
  }
}

.c-ttl--sp {
  display: none;
}

.c-ttl-large {
  font-size: 5.3333333333rem;
  line-height: .775;

  .min {
    display: block;
    font-size: .6666666667rem;
    letter-spacing: .02em;
    margin-top: 28px;
  }
}

.c-card-hov_fig {
  display: block;
  transition: transform 0.75s cubic-bezier(0, 0.15, 0.3, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
  will-change: transform;
  overflow: hidden;
}

.c-card-hov {
  figure {
    transition: transform 0.75s cubic-bezier(0, 0.15, 0.3, 1);
    -webkit-transform: scale(1);
    transform: scale(1);
    will-change: transform;
    overflow: hidden;
  }

  img {
    transition: transform 0.75s cubic-bezier(0, 0.15, 0.3, 1);
    -webkit-transform: scale(1);
    transform: scale(1);
    will-change: transform;
  }

  a {
    &:hover {
      opacity: 1 !important;

      figure {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
      }

      .c-card-hov_fig {
        -webkit-transform: scale(0.95);
        transform: scale(0.95);
      }

      img {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
      }
    }
  }
}

.c-size8-center {
  display: inline-block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.c-size8-left {
  display: inline-block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.c-size8-right {
  display: inline-block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.s-usces {
  line-height: 1.5;
  padding-top: 48px !important;

  input[type="text"] {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  input[type="tel"] {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  input[type="email"] {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  input[type="submit"], input[type=button] {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: auto;
    font-size: 1.0666666667rem;
    font-size: 1.2rem;
    padding: 28px 0 23px 0;
    background-color: $background-color_6;
    color: $color_8;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  input[type="password"] {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
    }
  }

  button {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
    }
  }

  textarea {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;
    height: 130px;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  select {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;
    padding: 10px 40px 10px 20px;
    background-image: url("../img/ico_pulldown.svg");
    background-position: calc(100% - 16px);
    background-size: 16px 16px;
    background-repeat: no-repeat;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  input[type="radio"] {
    -moz-appearance: radio;
    -webkit-appearance: radio;
    appearance: radio;
    width: auto;
    display: inline-block;
    background: #1F2C5C;
    border: 1px solid #1F2C5C;
  }

  .step_block {
    width: 100%;
    max-width: 900px;
    margin: 0 auto 32px auto;
  }

  .error_message {
    color: $color_4;
    font-weight: bold;
  }

  .upbutton {
    margin: 0 0 24px 0;
    color: $color_5;
    text-align: right;
    font-size: 12px;

    &:before {
      margin-right: 4px;
      color: $color_6;
      content: "\f071";
      font-size: 120%;
      font-family: $font-family_5;
    }

    input {
      margin: 0;
      padding: 5px 12px !important;
      border-radius: 3px;
      font-size: 11px !important;
      margin: 12px 0 0 0;
      display: inline-block;
      border-radius: 4px;
      width: auto !important;
    }
  }

  #cart_table {
    width: 100%;

    .aright {
      text-align: right;
    }

    .aleft {
      text-align: left;
    }

    tr {
      border-bottom: 1px solid #bfbcb4;

      &:last-child {
        border: 0;
      }
    }

    th {
      text-align: center;
      font-weight: normal;
      padding: 18px 12px;
      letter-spacing: normal;
      font-size: .8666666667rem;
      border-top: 1px solid #bfbcb4;
      border-bottom: 1px solid #bfbcb4;
      font-weight: bold;
      font-size: .8666666667rem;
      line-height: 1.2;
    }

    td {
      text-align: center;
      font-weight: normal;
      padding: 18px 12px;
      letter-spacing: normal;
      font-size: .8666666667rem;
    }

    .num {
      width: 3%;
      text-align: right;
    }

    .thumbnail {
      width: 21%;
      min-width: 30px;

      a {
        display: block;
        border: 0;

        img {
          width: 50%;
        }
      }
    }

    .productname {
      width: 30%;
      text-align: left;
      font-size: 100%;
    }

    .quantity {
      width: 10%;

      input.quantity {
        font-size: 11px;
        padding: 5px 8px;
        margin: 0 auto;
        width: 4em !important;
        text-align: right;
      }
    }

    .unitprice {
      width: 8%;
      text-align: right;
    }

    .subtotal {
      width: 15%;
      text-align: center;

      em {
        font-size: 80%;
        display: inline-block;
      }
    }

    .stock {
      width: 15%;
      font-size: 85%;
    }

    .action {
      width: 6%;

      .delButton {
        margin: 0;
        padding: 5px 10px;
        border: 1px solid #999;
        border-radius: 3px;
        background-color: $background-color_7;
        font-size: 11px !important;

        &:hover {
          border-color: $border-color_1;
          background-color: $background-color_8;
          color: $color_2;
        }
      }
    }
  }

  .no_cart {
    padding: 60px 0;
    text-align: center;
  }

  .total_box {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    margin-top: 26px;
    padding: 15px 0;
    width: 100%;
    border-top: 4px solid #1f2c5c;
    border-bottom: 1px solid #bfbcb4;
    text-align: right;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: 30px;
  }

  .total_txt {
    padding: 5px 15px 5px 0;
    width: 70%;
    font-size: 16px;

    em {
      font-size: 80%;
    }
  }

  .total_price {
    padding: 5px 15px 5px 0;
    width: 30%;
    font-size: 16px;
  }

  .total_txt.total_price_all {
    margin-top: 12px;
    font-weight: bold;
    display: flex;
    flex-wrap: wrap;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-align-items: center;
    align-items: center;
  }

  .total_price.total_price_all {
    margin-top: 12px;
    font-weight: bold;
    font-size: 20px;
  }

  .postage_free_txt {
    margin-top: 6px;
    color: $color_5;
    text-align: right;
    font-size: 12px;

    span {
      color: $color_6;
    }

    strong {
      color: $color_7;
    }
  }

  #previouscart {
    display: inline-block;
    color: $color_8;
    cursor: pointer;
    border: 1px solid #1f2c5c;
    border-radius: 6px;
    padding: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    font-family: $font-family_6;
    font-weight: bold;
    font-size: 15px;
    padding: 12px 48px;

    a {
      padding: 12px 48px;
    }
  }

  .customer_form {
    width: 100%;

    tr {
      width: 100%;

      &.hidden {
        position: absolute;
        visibility: hidden;
      }
    }

    th {
      width: 30%;
      padding: 0 0 15px 0;
      font-weight: bold;
    }

    td {
      padding: 0 0 15px 0;
      font-size: 16px;
      font-size: .9333333333rem;
    }

    em {
      color: $color_9;
    }
  }

  .memberinfo_tbl {
    width: 100%;
    margin: 0 0 10px 0;

    tr {
      width: 100%;
    }

    th {
      width: 30%;
      padding: 0 0 15px 0;
      font-weight: bold;
    }

    td {
      padding: 0 0 15px 0;
      font-size: 16px;
      font-size: .9333333333rem;
    }

    em {
      color: $color_9;
    }
  }

  .form_customer {
    table {
      td {
        padding: 0 0 15px 0;
      }
    }

    .send {
      margin: 0 auto 24px;
      @apply lg:w-3/4;
    }

    input[type="submit"] {
      margin: 0 auto 24px;
    }
  }

  .form_not_customer {
    .send {
      margin: 48px 0 24px 0;
    }
  }

  h5 {
    margin-bottom: 12px;
    font-size: 20px;
    font-weight: bold;
  }

  h5.tit_not_customer {
    padding-bottom: 10px;
    border-bottom: 1px solid #bfbcb4;

    span {
      font-weight: normal;
      font-size: 12px;
    }
  }

  form.form_customer {
    margin-bottom: 80px;
    padding: 40px 60px;
    border: 11px solid #f1f1f1;
  }

  form.form_not_customer {
    padding: 25px 0 0 0;
  }

  .name_td {
    font-weight: bold;
    width: 35% !important;
    padding: 10px;

    &:first-of-type {
      padding: 0 5px 10px 0;
    }

    &:last-of-type {
      padding: 0 0 10px 5px;
    }
  }

  #furikana_row {
    > td {
      font-weight: bold;
      width: 35% !important;
      padding: 10px;

      &:first-of-type {
        padding: 0 5px 10px 0;
      }

      &:last-of-type {
        padding: 0 0 10px 5px;
      }
    }
  }

  #search_zipcode {
    margin: 0 10px 0 0;
    background-color: $background-color_9;
    color: $color_2;
    border: 0;
    border-radius: 3px;
    padding: 4px 12px;
  }

  .login_sub_navi {
    margin: 20px 0 0 0;

    li {
      padding: 0;

      &:before {
        display: none;
      }
    }
  }

  .send {
    text-align: center;
    margin: 48px 0 24px 0;

    &.horizontal {
      @apply md:flex md:w-3/4 mx-auto flex-wrap justify-between;
      input {
        @apply w-full md:w-5/12 md:mb-12 mb-4 mx-auto;
        white-space: pre-wrap;
      }

      &.row {
        @apply flex max-md:flex-row;
      }
    }

    [name=customerlogin] {
      @apply w-1/2;
    }

    input[type="submit"].back_cart_button {
      &._order-1 {
        @apply md:order-1;
      }

      @apply order-5 md:order-4;
      display: inline-block;
      color: $color_8;
      cursor: pointer;
      border: 1px solid #1f2c5c;
      border-radius: 6px;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 15px;
      padding: 16px 48px;

      a {
        padding: 12px 48px;
      }
    }

    input[type="submit"].back_to_customer_button {
      @apply max-md:order-2;
      display: block;
      color: $color_8;
      cursor: pointer;
      border: 1px solid #1f2c5c;
      border-radius: 6px;
      padding: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 15px;
      padding: 12px 48px;

      a {
        padding: 12px 48px;
      }
    }

    input[type="submit"].to_customerinfo_button {
      @apply mt-0;
    }

    input[type="submit"].to_deliveryinfo_button {
      @apply order-3;
    }

    input[type="submit"].to_reganddeliveryinfo_button {
      @apply order-2;
    }

    input[type="submit"].back_to_delivery_button {
      @apply max-md:order-2;
      display: block;
      color: $color_8;
      cursor: pointer;
      border: 1px solid #1f2c5c;
      border-radius: 6px;
      padding: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 15px;
      padding: 12px 48px;

      a {
        padding: 12px 48px;
      }
    }
      #paidy-checkout-button{
          @apply w-full md:w-1/3 py-4 px-[48px];
      }
  }

  .pass_att {
    font-size: 12px;
    line-height: 1.7;

    span {
      color: $color_5;
    }
  }
    dd[class^=payment_] {
    margin-bottom: 4px;
  }

  dd[class^=payment_] {
    margin-bottom: 16px;
    padding-left: 21px;
    color: $color_5;
    font-size: 80%;

    em {
      color: $color_6;
    }
  }

  .tenpo_kibo {
    margin: 16px 0;
    padding: 8px 8px 8px 12px;
    border-radius: 3px;
    background-color: $background-color_7;
    font-size: 13px;
  }

  .customkey_tenpouketori {
    select {
      width: auto;
      background-color: $background-color_10;
    }
  }

  .ex_comment {
    margin-bottom: 10px;

    h6 {
      margin-bottom: 4px;
      font-size: 14px;
    }

    p {
      color: $color_5;
      font-size: 12px;
      line-height: 1.5em;
      margin-bottom: 20px;
    }
  }

  #point_table {
    width: calc(100% - 15px);
    margin-top: 30px;

    tr {
      width: 100%;
    }

    td {
      font-weight: bold;
      padding: 0 0 15px 0;
      font-size: 16px;

      @screen sm {
        &:first-child {
          width: 20rem;
        }
      }
    }

    .used_point {
      font-weight: bold;
      display: inline-block;
      width: calc(100% - 2em) !important;
      max-width: 200px;
      margin: 0 10px 0 0;
    }

    .use_point_button {
      @apply w-full md:w-1/2 py-4;
    }
  }

  #cart #coupon_table {
    width: calc(100% - 15px);
    float: left;
    margin-left: 15px;
    margin-right: 15px;
    @apply mt-4;
    td {
      width: 50%;
      padding: 0 0 15px 0;
      font-size: 13px;
      @apply font-bold;
      @screen sm {
        font-size: 16px;
        &:first-child {
          width: 20rem;
        }

        &:last-child {
          width: auto;
        }
      }
    }


    td[colspan="2"] {
      @screen sm {
        padding-left: 20rem;
      }
    }

    [name='offer[usedcoupon]'] {
      @screen sm {
        max-width: 16rem;
      }
    }

    [type=submit] {
      width: 50%;
      @apply py-4 ml-auto;
      @screen sm {
        @apply ml-0;
      }
    }
  }

  #confirm_table {
    margin-top: 40px;
    width: 100%;

    tr.ttl {
      td {
        border: 0;
      }
    }

    th {
      padding: 5px 10px;
      border: 1px solid #bfbcb4;
      font-size: 16px;
      width: 30%;
    }

    td {
      padding: 5px 10px;
      border: 1px solid #bfbcb4;
      font-size: 16px;
      width: 70%;
    }

    h3 {
      margin: 20px -10px 10px -10px;
      font-weight: bold;
    }

    .num {
      width: 2.5%;
    }

    .thumbnail {
      width: 10%;
    }

    .productname {
      width: 56.5%;
      font-size: 110%;
    }

    .price {
      width: 8%;
    }

    .unitprice {
      width: 8%;
    }

    .quantity {
      width: 8%;
    }

    .subtotal {
      width: 15%;
    }

    .action {
      padding: 0 !important;
      width: 0 !important;
      font-size: 0 !important;
    }
  }

  .confiem_notice {
    text-align: right;
    margin-bottom: 16px;
    font-size: 12px;
  }

  .thanks_comment {
    .back_to_top_button {
      display: inline-block;
      color: $color_8;
      width: auto !important;
      cursor: pointer;
      border: 1px solid #1f2c5c;
      border-radius: 6px;
      padding: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      margin: 0;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 15px;
      padding: 12px 48px;

      a {
        padding: 12px 48px;
      }
    }

    .page_title {
      margin-bottom: 40px;
      font-size: 30px;
      font-weight: bold;
      text-align: center;
    }

    .thanks_msg {
      em {
        display: block;
        font-weight: bold;
        margin-bottom: 40px;
        padding: 8px 20px;
        background-color: $background-color_9;
        color: $color_2;
        text-align: center;
      }

      p {
        font-size: 14px;
      }
    }

    .send {
      margin: 10px 0 45px;
    }

    .info_fth_sns {
      margin-bottom: 10px;
    }
  }

  .error_comment {
    margin: 0 auto;
    width: 860px;

    .page_title {
      margin-bottom: 50px;
    }

    h2 {
      margin-bottom: 10px;
    }

    .error_msg {
      margin-bottom: 40px;
      text-align: left;
      line-height: 1.6em;

      em {
        display: block;
        margin-bottom: 10px;
        padding: 8px 20px;
        border: 4px solid #e5e5e5;
      }
    }

    .send {
      margin: 10px 0 45px;
    }

    .info_fth_sns {
      margin-bottom: 10px;
    }
  }

  .page_title {
    font-size: 30px;
    margin: 0 0 30px 0;
    font-weight: bold;
    text-align: center;
  }

  .member_submenu {
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;

    li {
      text-align: center;

      &:before {
        display: none;
      }

      a {
        border: 0;
        display: block;
      }
    }
  }

  .edit_member {
    display: inline-block;
    color: $color_8;
    width: auto !important;
    cursor: pointer;
    border: 1px solid #1f2c5c;
    border-radius: 6px;
    padding: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    margin: 0;
    font-family: $font-family_6;
    font-weight: bold;
    font-size: 15px;
    display: block;
    width: calc(50% - 5px) !important;
    margin: 0 5px 30px 0;

    a {
      padding: 12px 48px;
    }
  }

  .logout_member {
    display: inline-block;
    color: $color_8;
    width: auto !important;
    cursor: pointer;
    border: 1px solid #1f2c5c;
    border-radius: 6px;
    padding: 0;
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    margin: 0;
    font-family: $font-family_6;
    font-weight: bold;
    font-size: 15px;
    display: block;
    width: calc(50% - 5px) !important;
    margin: 0 0 30px 5px;

    a {
      padding: 12px 48px;
    }
  }

  .history-area {
    margin: 0 0 40px 0;
  }

  #history_head {
    width: 100%;
    border: 1px solid #bfbcb4 !important;
    margin: 30px 0 0 0;

    th {
      font-size: .7333333333rem;
    }
  }

  .order_head_label {
    font-size: .7333333333rem;
  }

  .historyrow {
    width: 10% !important;
  }

  .order_number {
    width: 15% !important;
    word-break: break-all;
  }

  .purchase_date {
    width: 15% !important;
  }

  .retail {
    width: 100%;
    border: 1px solid #bfbcb4 !important;
    border-top: none !important;

    th {
      border-top: none !important;
    }

    .productname {
      font-size: 13px !important;
    }
  }

  #edit-member {
    .form_customer {
      margin: 30px 0 0 0;
    }

    .customer_form {
      margin: 0 0 40px 0;
    }

    .send {
      display: flex;
      flex-wrap: wrap;

      input[name="top"] {
        display: inline-block;
        color: $color_8;
        width: auto !important;
        cursor: pointer;
        border: 1px solid #1f2c5c;
        border-radius: 6px;
        padding: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: none;
        margin: 0;
        font-family: $font-family_6;
        font-weight: bold;
        font-size: 15px;
        padding: 12px 48px;
        display: block;
        width: calc(50% - 5px) !important;
        margin: 0 5px 70px 0;

        a {
          padding: 12px 48px;
        }
      }

      input[name="editmember"] {
        display: inline-block;
        color: $color_8;
        width: auto !important;
        cursor: pointer;
        border: 1px solid #1f2c5c;
        border-radius: 6px;
        padding: 0;
        appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        background: none;
        margin: 0;
        font-family: $font-family_6;
        font-weight: bold;
        font-size: 15px;
        padding: 12px 48px;
        display: block;
        width: calc(50% - 5px) !important;
        margin: 0 5px 70px 0;

        a {
          padding: 12px 48px;
        }
      }

      input[name="deletemember"] {
        display: inline-block;
        background: transparent;
        font-size: 12px;
        padding: 4px 12px;
        margin: 0;
        width: auto !important;
        border: 1px solid #1f2c5c;
      }
    }
  }

  .memberpages_message {
    p {
      display: block;
      font-weight: bold;
      padding: 8px 20px;
      background-color: $background-color_9;
      color: $color_2;
      text-align: center;
      margin: 0 0 40px 0;
    }
  }

  .btn_wrap {
    text-align: center;

    .back_to_top_button {
      display: inline-block;
      color: $color_8;
      width: auto !important;
      cursor: pointer;
      border: 1px solid #1f2c5c;
      border-radius: 6px;
      padding: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      margin: 0;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 15px;
      min-width: 240px;
      margin: 0 0 10px 0;

      a {
        padding: 12px 48px;
      }
    }
  }

  .header_explanation {
    font-size: 1rem;

    li {
      margin-bottom: 10px;
    }
  }

  #wc_lostmemberpassword {
    input[name="lostpassword"] {
      margin: 30px 0;
    }

    .login_sub_navi {
      text-align: center;
    }

    .loginbox_msg {
      text-align: center;
      font-size: 13px;
      margin: 0 0 40px 0;
    }

    .md-btn {
      display: inline-block;
      color: $color_8;
      width: auto !important;
      cursor: pointer;
      border: 1px solid #1f2c5c;
      border-radius: 6px;
      padding: 0;
      appearance: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      background: none;
      margin: 0;
      font-family: $font-family_6;
      font-weight: bold;
      font-size: 15px;

      a {
        padding: 12px 48px;
        border: 0;
        display: block;
      }
    }
  }
}

.ui-dialog {
  top: 50% !important;
  position: fixed !important;
}

#escott-dialog {
  position: fixed;
  @extend .default_background;
  input[type="tel"], input[type="text"] {
    background-color: $background-color_5;
    border: 1px solid $background-color_5;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1rem;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  input[type="submit"] {
    background-color: $background-color_5;
    border: 1px solid $background-color_5;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: auto;
    font-size: 1rem;
    font-size: 1.2rem;
    padding: 28px 0 23px 0;
    background-color: $background-color_6;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    -webkit-transition: opacity 0.3s;
    transition: opacity 0.3s;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
    }

    &:hover {
      opacity: 0.7;
    }
  }

  textarea {
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1.0666666667rem;
    height: 130px;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  select {
    display: inline-block;
    background-color: $background-color_5;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    font-size: 1rem;
    padding: 10px 40px 10px 20px;
    background-image: url("../img/ico_pulldown.svg");
    background-position: calc(100% - 16px);
    background-size: 16px 16px;
    background-repeat: no-repeat;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
      border: 1px solid #1f2c5c;
    }
  }

  .ui-dialog-titlebar {
    padding: 8px 36px 8px 16px;
    height: auto;
    @extend .default_background;
  }

  .ui-button.ui-dialog-titlebar-close {
    top: 8px;
    right: 8px;
  }

  .settlement_form_send {
    display: flex;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    justify-content: center;
  }

  .to_confirm_button {
    background-color: #f08300;
    border: 1px solid #dcd8d0;
    width: auto;
  }

  .escott_agreement_message {
    height: 50vh;
  }
}

#escott-agree-dialog {
  button {
    background-color: $background-color_5;
    border: 1px solid $background-color_5;
    border-radius: 6px;
    padding: 10px 20px;
    margin: 10px 0;
    width: 100% !important;
    font-size: 1rem;

    &:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
    }
  }

  #escott_agree_next {
    border: 1px solid #1f2c5c;
    border-radius: 6px;
    color: #1f2c5c;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    height: 50px;
    margin: 0;
    margin-top: 10px;
    padding: 12px 24px;

    background-color: #f08300;
    border: 1px solid #dcd8d0;
    border-radius: 6px;
    cursor: pointer;
    width: auto;
  }

  #escott_agree_cancel {
    appearance: none;
    background: none;
    border: 1px solid #1f2c5c;
    border-radius: 6px;
    color: #1f2c5c;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: 700;
    height: 50px;
    margin: 0;
    margin-top: 10px;
    padding: 12px 24px;

    border-radius: 6px;
    cursor: pointer;
    width: auto;
  }
}


#escott-token-dialog {
  padding: 24px;
    max-height: calc(100dvh - 2rem) !important;
}

#escott-token-form {
  margin: 0 0 20px 0;

  .customer_form {
    display: block !important;
    line-height: 1.4;

    th {
      padding: 8px;
      width: 30%;
      font-weight: bold;
      text-align: left;
      font-size: 1rem;
    }

    td {
      padding: 8px;
      width: 70%;
      font-size: .8666666667rem;

      .attention {
        margin: 8px 0 8px 0;
      }

      .escott_quick_member {
        label {
          font-size: 1rem;
        }
      }
    }

    input[type="checkbox"] {
      display: inline-block;
      width: 1em;
      height: 1em;
      moz-appearance: checkbox;
      -webkit-appearance: checkbox;
      appearance: checkbox;
      margin: 0 6px 0 0;
      vertical-align: middle;
      background: #1F2C5C;
      border: 1px solid #1F2C5C;
    }
  }
}

#escott_token_cancel {
  display: inline-block;
  color: $color_8;
  width: auto !important;
  cursor: pointer;
  border: 1px solid #1f2c5c;
  border-radius: 6px;
  padding: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  margin: 0;
  font-family: $font-family_6;
  font-weight: bold;
  font-size: 15px;
  height: 50px;
  padding: 12px 24px;

  a {
    padding: 12px 48px;
  }
}

#escott_token_next {
  display: inline-block;
  color: $color_8;
  width: auto !important;
  cursor: pointer;
  border: 1px solid #1f2c5c;
  border-radius: 6px;
  padding: 0;
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  background: none;
  margin: 0;
  font-family: $font-family_6;
  font-weight: bold;
  font-size: 15px;
  height: 50px;
  padding: 12px 24px;

  a {
    padding: 12px 48px;
  }
}

@media only screen and (max-width: 1200px) {
  .c-item-name {
    font-size: calc((100vw - 376px) / 826 * 1 + 13px);
  }
  .c-ttl-max {
    font-size: calc((100vw - 376px) / 824 * 40 + 50px);
  }
  .c-ttl-large {
    font-size: calc((100vw - 376px) / 824 * 30 + 50px);
  }
  .s-usces {
    width: auto !important;
    margin: 0 4vw !important;

    input[type="text"] {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }

    input[type="tel"] {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }

    input[type="email"] {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }

    input[type="submit"] {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
      font-size: calc((100vw - 376px) / 826 * 4 + 14px);
    }

    input[type="password"] {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }

    button {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }

    textarea {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }

    select {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }
  }
}

@media only screen and (max-width: 750px) {
  .c-item-name {
    font-size: .8666666667rem;
  }
  .c-item-off {
    .off {
      padding: 2px 3px 1px 2px;
    }
  }
  .c-btn-large {
    max-width: none;
    width: 206px;

    a {
      height: 83px;
      margin: 4px 6px 4px 7px;
    }
  }
  .c-btn-large_outline {
    max-width: none;
    width: 206px;
    top: 0;
  }
  .c-btn-middle {
    a {
      height: 63px;
    }
  }
  .c-cover {
    top: calc(81.28vh - 18px);
    height: 27px;
    -webkit-mask-image: url("/asset/img/mv_btm_sp.png");
    mask-image: url("/asset/img/mv_btm_sp.png");
  }
  .c-cover-pc {
    display: none;
  }
  .c-cover-sp {
    display: inline;
  }
  .c-ttl--pc {
    display: none;
  }
  .c-ttl--sp {
    display: block;
  }
  .s-usces {
    margin: 4vw !important;
    padding-top: 8px !important;

    input[type="text"] {
      padding: 10px 16px;
      margin: 0 0 5px 0;
    }

    input[type="tel"] {
      padding: 10px 16px;
      margin: 0 0 5px 0;
    }

    input[type="email"] {
      padding: 10px 16px;
      margin: 0 0 5px 0;
    }

    input[type="submit"] {
      padding: 10px 16px;
      margin: 0 0 5px 0;
      padding: 18px 0 17px 0;
    }

    input[type="password"] {
      padding: 10px 16px;
      margin: 0 0 5px 0;
    }

    button {
      padding: 10px 16px;
      margin: 0 0 5px 0;
    }

    textarea {
      padding: 10px 16px;
      margin: 0 0 5px 0;
      height: 130px;
    }

    select {
      padding: 10px 16px;
      margin: 0 0 5px 0;
      padding: 10px 40px 10px 16px;
      margin: 0 0 10px 0;
    }

    .upbutton {
      font-size: calc(10px + .2666666667vw);
      text-align: center;
    }

    #cart_table {
      font-size: 90%;

      th {
        padding: 8px calc((100vw - 376px) / 374 * 5 + 1px);
        font-size: 11px !important;
      }

      td {
        padding: 8px calc((100vw - 376px) / 374 * 5 + 1px);
        font-size: 11px !important;
      }

      .cartrownum {
        width: 7%;
      }

      .thumbnail {
        width: 16%;

        a {
          img {
            width: 90%;
          }
        }
      }

      .quantity {
        input.quantity {
          padding: 5px calc((100vw - 376px) / 374 * 6 + 2px);
        }
      }

      .action {
        .delButton {
          padding: 5px calc((100vw - 376px) / 374 * 6 + 2px);
          font-size: 10px !important;
        }
      }
    }

    .total_txt {
      width: 50%;
      font-size: 13px;
    }

    .total_txt.total_price_all {
      margin-top: 0;
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
    }

    .total_price {
      width: 50%;
      text-align: right;
      font-size: 13px;
    }

    .total_price.total_price_all {
      margin-top: 0;
      font-size: 16px;
    }

    #previouscart {
      font-size: 12px;
    }

    .customer_form {
      th {
        display: block;
        width: 100%;
        padding: 0 0 5px 0;
      }

      td {
        display: block;
        padding: 0 0 20px 0;
        width: 100%;
        font-size: calc(10px + .5333333333vw);
      }
    }

    .memberinfo_tbl {
      th {
        display: block;
        width: 100%;
        padding: 0 0 5px 0;
      }

      td {
        display: block;
        padding: 0 0 20px 0;
        width: 100%;
        font-size: calc(10px + .5333333333vw);
      }
    }

    form.form_customer {
      margin-bottom: calc((100vw - 320px) / 430 * 40 + 40px);
      padding: calc((100vw - 320px) / 430 * 20 + 20px) calc((100vw - 320px) / 430 * 40 + 20px);
    }

    #name_row {
      display: flex;
      flex-wrap: wrap;
    }

    #furikana_row {
      display: flex;
      flex-wrap: wrap;

      > td {
        display: block !important;
        width: 50% !important;
      }
    }

    .name_td {
      display: block !important;
      width: 50% !important;
    }

    .login_sub_navi {
      li {
        font-size: 11px;
      }
    }

    .send {
      input[type="submit"].back_cart_button {
        font-size: 12px;
      }

      input[type="submit"].back_to_customer_button {
        font-size: 12px;
      }

      input[type="submit"].back_to_delivery_button {
        font-size: 12px;
      }
    }

    .pass_att {
      font-size: 11px;
    }

    #point_table {
      margin-top: 20px;

      td {
        width: 50%;
        font-size: 13px;
      }
    }

    #confirm_table {
      margin-top: 15px;

      th {
        font-size: 13px;
      }

      td {
        font-size: 13px;
      }
    }

    .thanks_comment {
      .back_to_top_button {
        font-size: 12px;
      }
    }

    .memberinfo_box {
      h5 {
        font-size: 18px;
      }
    }

    .page_title {
      font-size: 24px;
      margin: 0 0 20px 0;
    }

    .edit_member {
      font-size: 12px;
      width: 100% !important;
      margin: 0 0 10px 0;
    }

    .logout_member {
      font-size: 12px;
      width: 100% !important;
      margin: 0 0 20px 0;
    }

    #edit-member {
      .customer_form {
        .name_td {
          display: inline-block;
        }
      }

      .send {
        input[name="top"] {
          font-size: 12px;
          width: 100% !important;
          margin: 0 0 10px 0;
          padding: 12px;
        }

        input[name="editmember"] {
          font-size: 12px;
          width: 100% !important;
          margin: 0 0 10px 0;
          padding: 12px;
        }

        input[name="deletemember"] {
          margin: 50px 0 0 0;
        }
      }
    }

    .btn_wrap {
      .back_to_top_button {
        font-size: 12px;
      }
    }

    .header_explanation {
      font-size: calc(10px + .8vw);
    }

    #wc_lostmemberpassword {
      .md-btn {
        font-size: 12px;
      }
    }
  }
  #escott-token-dialog {
    padding: 8px 8px 24px 8px;
  }
  #escott-token-form {
    margin: 0 0 10px 0;

    .customer_form {
      th {
        font-size: calc(10px + .8vw);
      }

      td {
        font-size: calc(10px + .2666666667vw);
      }

      select {
        padding: 4px 40px 4px 8px;
      }
    }
  }
  #escott_token_cancel {
    font-size: 12px;
  }
  #escott_token_next {
    font-size: 12px;
  }
}

@media only screen and (max-width: 375px) {
  .c-item-name {
    line-height: 1.3846153846;
  }
  .c-btn-large {
    a {
      font-size: calc(10px + .2666666667vw);
    }
  }
  .c-ttl-max {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .8;

    .min {
      margin-top: 24px;
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      -webkit-transform-origin: center center;
      transform-origin: center center;
    }
  }
  .c-ttl-large {
    font-size: calc(10px + 10.6666666667vw);

    .min {
      margin-top: 24px;
      -webkit-transform: scale(0.9);
      transform: scale(0.9);
      -webkit-transform-origin: center center;
      transform-origin: center center;
    }
  }
  .s-usces {
    input[type="text"] {
      font-size: calc(10px + 1.0666666667vw);
    }

    input[type="tel"] {
      font-size: calc(10px + 1.0666666667vw);
    }

    input[type="email"] {
      font-size: calc(10px + 1.0666666667vw);
    }

    input[type="submit"] {
      font-size: calc(10px + 1.0666666667vw);
      font-size: calc(10px + 1.0666666667vw);
    }

    input[type="password"] {
      font-size: calc(10px + 1.0666666667vw);
    }

    button {
      font-size: calc(10px + 1.0666666667vw);
    }

    textarea {
      font-size: calc(10px + 1.0666666667vw);
    }

    select {
      font-size: calc(10px + 1.0666666667vw);
    }

    .upbutton {
      font-size: calc(10px + 0vw);
    }

    #cart_table {
      th {
        padding: 8px 2px;
        font-size: 10px !important;
      }

      td {
        padding: 8px 2px;
        font-size: 10px !important;
      }

      .quantity {
        input.quantity {
          padding: 5px 2px;
          width: 2em !important;
        }
      }

      .action {
        .delButton {
          padding: 5px 2px;
        }
      }
    }

    .postage_free_txt {
      font-size: 10px;
    }
  }
}

@media only screen and (max-height: 600px) {
  .c-cover {
    top: calc(600px - 18px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 690px) {
  .c-cover {
    top: calc(560px - 18px);
  }
}
