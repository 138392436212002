#jeansfeature {
    .jenas-top-header-subtitle {
        @apply leading-6;
    }

    .jeansfeature-lineup {
        p {
            @apply text-xs sm:text-sm;
        }
    }

    #jeansfeature-description_box-bottom {
        p {
            @apply text-sm;
            @apply leading-6;
        }
    }

    .jenas-content-subtitle {
        @apply text-2xl;
    }

    // wrap
    .section-box-wrap {
        @apply mb-24 sm:mb-32;
    }

    .subsection-box-wrap {
        @apply mb-16 sm:mb-20;
    }

    .box-wrap {
        @apply mb-8 sm:mb-12;
    }

    .box-inner-wrap {
        @apply mb-4 sm:mb-8;
    }

    .block-wrap {
        @apply mb-2;
    }

    .img-default {
        @apply w-screen lg:w-full;
        @apply h-auto;
    }

    .flex-half {
        @apply basis-full sm:basis-1/2;
    }

    .responsive-max-width {
        @apply sm:mx-auto;
        @apply max-w-full sm:max-w-xl md:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl;
    }

    .textrowmotion-title {
        @apply mb-4;
    }

    .textrowmotion-subtitle {
        @apply mt-0;
    }

    .jeansfeature-lineup_label {
        @apply c-font-bold;
        @apply basis-1/6;
        @apply text-sm sm:text-base;
    }

    .jeansfeature-lineup-header {
        background-color: #e3e0d9;
        border-top: 1px solid #dcd8d0;
        @apply fixed;
        @apply top-[88px] left-0;
        //　現在ヘッダーのサイズが固定値
        @apply overflow-hidden;
        z-index: -10;
        @apply mb-0;
        @apply py-2 px-0;
        @apply w-full;
        @apply origin-center origin-top;
        transform: translateY(-250%);
        transition: transform .4s cubic-bezier(.6, .25, 0, 1);
    }

    .disp {
        transform: translateY(2.5rem);
        transition: transform .4s cubic-bezier(.6, .25, 0, 1);
    }

    .jeansfeature-descriptions_box-headline {
        @apply c-font-bold;
        @apply text-center;
        @apply text-lg;
    }

    .jeansfeature-descriptions_box-description {
        @apply box-inner-wrap;
        @apply row-span-1;
        @apply pr-0 sm:pr-8;
    }

    .item-button-wrap {
        @apply mx-auto;
        @apply text-sm lg:text-base;
        @apply w-3/4;
        height: 80px !important;
    }

    .s-journal-detail_related .ttl {
        font-size: 1.5rem;
        line-height: 1.25;
    }

}
