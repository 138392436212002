#keito_special {
    @media only screen and (max-width: 768px) {
        .l-article {
            @apply mb-12;
        }
        .l-article_ttl, .l-article h2 {
            @apply text-center;
        }
        .p-journal-detail .l-article:has(>.l-article_img2col) {
            margin-bottom: 3rem;
        }
        .l-slider-5item {
            @apply pb-8;
        }
        .s-top_featured {
            @apply pb-0;
        }
    }

    .s-top_featured {
        padding-left: 0;
        @apply mb-6;
    }

    .l-article_img2col {
        display: block;
        @apply mb-24;
        > figure {
            width: 35.9375vw;
            margin: 0 9.375vw;
        }

        > h2 {
            width: calc(100% - 54.6875vw);
        }

        > p {
            width: calc(100% - 54.6875vw);
        }

        > div {
            clear: both;
        }

        &.left {
            > figure {
                float: left;
            }

            > h2 {
                float: right;
            }

            > p {
                float: right;
            }
        }

        &.right {
            > figure {
                float: right;
            }

            > h2 {
                float: left;
            }

            > p {
                float: left;
            }
        }

        @media only screen and (max-width: 768px) {
            display: flex;
            @apply mb-12;
            > figure, > h2, > p {
                float: none;
                width: 100vw;
                margin: 1.5rem 0;
            }
            > figure {
                order: 2;
            }
            > h2 {
                order: 1;
            }
            > p {
                order: 3;
            }
            > div {
                display: none;
            }
        }
    }

    .l-content-item-choice2_sp {
        width: 100vw;

        > .c-btn-small {
            @apply mx-auto;
            a {
                @apply ml-0;
            }
        }
    }
}

#keito_special_2024 {
    @media only screen and (max-width: 750px) {
        .l-article {
            @apply mb-12;
        }
        .l-article_ttl, .l-article h2 {
            @apply text-center;
        }
        .p-journal-detail .l-article:has(>.l-article_img2col) {
            margin-bottom: 3rem;
        }
        .l-slider-5item {
            @apply pb-8;
        }
        .s-top_featured {
            @apply pb-0;
        }
    }

    .s-top_featured {
        padding-left: 0;
        @apply mb-6;
    }

    .l-article_img2col {
        display: flex;
        @apply mb-24;
        > figure {
            width: 35.9375vw;
            margin: 0 9.375vw;

            &.item {
                width: 45.9375vw;
                margin-right: 4.375vw;
            }
        }

        > h2 {
            width: calc(100% - 54.6875vw);
        }

        > p {
            width: calc(100% - 54.6875vw);
        }

        > div {
            width: calc(100% - 54.6875vw);

            &.item {
                width: calc(100% - 59.6875vw);
            }
        }

        &.left {
            > figure {
                float: left;
            }

            > div {
                float: right;
            }

            > h2 {
                float: right;
            }

            > p {
                float: right;
            }
        }

        &.right {
            > figure {
                float: right;
            }


            > div {
                float: left;
            }

            > h2 {
                float: left;
            }

            > p {
                float: left;
            }
        }

        @media only screen and (max-width: 750px) {
            @apply mb-12 flex flex-wrap justify-center;
            &.right, &.left {
                > figure, > div > h2, > div > p {
                    float: none;
                    width: 100vw;
                    margin: 1.5rem 0 0;
                }

                > figure {
                    order: 2;
                }

                > div {
                    @apply contents;
                    > h2 {
                        order: 1;
                    }

                    > p.font-bold {
                        order: 1;
                        @apply text-left;
                    }

                    > p.item-info {
                        order: 4;
                        @apply text-right;
                    }

                    > p {
                        order: 3;
                    }
                }
            }
        }
    }

    .l-content-item-choice2_sp {
        width: 100vw;

        > .c-btn-small {
            @apply mx-auto;
            a {
                @apply ml-0;
            }
        }
    }
}
