@import "fth_usces";
@import "helper";
@import "startup-element";
@tailwind base;
@tailwind components;
@tailwind utilities;

//既に使っているcssとかぶるので上書き。
@layer components {
  body .outline {
    outline-style: none;
  }
}
body {
  overflow: visible;
}

b, .c-font-bold {
  font-family: YakuHanJP, 'Inter', "游ゴシック", YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
  font-weight: bold;
}

.c-font-normal {
  font-weight: 400;
  font-family: YakuHanJP, 'Inter', YuGothic, "Yu Gothic Medium", "Yu Gothic", "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
}

.c-font-gr {
  font-family: aktiv-grotesk-extended, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.c-font-en {
  font-family: moret, serif;
  font-weight: 300;
  font-style: normal;
  letter-spacing: -.03em;
}


.c-ib {
  display: inline-block;
}

.c-ws {
  white-space: nowrap;
}

::selection {
  background-color: rgba(201, 201, 201, 0.3);
}

@keyframes disp-block {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes circle-rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.c-ico-circle {
  display: block;
  animation: 20s linear infinite circle-rotation;
  z-index: 1;
  -webkit-transform-origin: center center;
  transform-origin: center center;

  img {
    width: 100%;
    vertical-align: top;
  }
}

.c-item-name {
  display: block;
  font-size: .9333333333rem;
  line-height: 1.4285714286;
}

@media only screen and (max-width: 1200px) {
  .c-item-name {
    font-size: calc((100vw - 376px) / 826 * 1 + 13px);
  }
}

@media only screen and (max-width: 750px) {
  .c-item-name {
    font-size: .8666666667rem;
  }
}

@media only screen and (max-width: 375px) {
  .c-item-name {
    line-height: 1.3846153846;
  }
}

.c-item-price {
  display: block;
  font-size: .6666666667rem;
  line-height: 1;
  margin-top: 12px;
}

.c-item-soldout {
  display: block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.c-item-off {
  color: #F08300;

  .off {
    display: inline-block;
    background-color: #F08300;
    border-radius: 4px;
    padding: 3px 5px 2px 5px;
    line-height: 1.2;
    color: #fff;
    margin-right: 6px;
  }
}

@media only screen and (max-width: 750px) {
  .c-item-off .off {
    padding: 2px 3px 1px 2px;
  }
}

.c-ttl-divide {
  display: block;

  &:first-child {
    margin-top: 0;
  }
}

@keyframes c-str1 {
  0% {
    stroke-dashoffset: 1102px;
    opacity: 1;
  }

  40% {
    stroke-dashoffset: 1102px;
    opacity: 0;
  }

  50% {
    stroke-dashoffset: 1653px;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 2204px;
  }
}

@keyframes c-str2 {
  0% {
    stroke-dashoffset: 1180px;
    opacity: 1;
  }

  40% {
    stroke-dashoffset: 1180px;
    opacity: 0;
  }

  50% {
    stroke-dashoffset: 1770px;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 2360px;
  }
}

@keyframes c-str3 {
  0% {
    stroke-dashoffset: 196px;
    opacity: 1;
  }

  40% {
    stroke-dashoffset: 196px;
    opacity: 0;
  }

  50% {
    stroke-dashoffset: 294px;
    opacity: 1;
  }

  100% {
    stroke-dashoffset: 392px;
  }
}

.c-btn-large {
  max-width: 240px;
  width: 18.75vw;

  a {
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
    height: 99px;
    margin: 4px 6px 4px 7px;
    background-color: #F08300;
    font-size: .8rem;
    line-height: 1;
    border-radius: 50%;
    position: relative;

    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 750px) {
  .c-btn-large {
    max-width: none;
    width: 206px;
  }
}

@media only screen and (max-width: 750px) {
  .c-btn-large a {
    height: 83px;
    margin: 4px 6px 4px 7px;
  }
}

@media only screen and (max-width: 375px) {
  .c-btn-large a {
    font-size: calc(10px + .2666666667vw);
  }
}

.c-btn-large_inr {
  display: inline-block;
  position: relative;
  padding-right: 22px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    top: calc(50% - 4px);
    right: 0;
    background-image: url("../img/ico_arrow_b.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100% auto;
  }
}

.c-btn-large_outline {
  position: absolute;
  top: 0;
  left: -7px;
  max-width: 240px;
  width: 18.75vw;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-dasharray: 551px;
    stroke-dashoffset: 1653px;
    transition: stroke-dashoffset 0.4s cubic-bezier(0.55, 0.01, 0.45, 1) 0.5s;
  }
}

@media only screen and (max-width: 750px) {
  .c-btn-large_outline {
    max-width: none;
    width: 206px;
    top: 0;
  }
}

.js-loaderclose .c-btn-large_outline svg {
  stroke-dashoffset: 1102px;
  transition: none;
}

.c-btn-large:hover svg {
  animation: 0.6s cubic-bezier(0.2, 0.8, 0.5, 0.9) c-str1;
}

a .c-hov, .s-item-detail_item-cart-btn .c-hov, .s-item-detail_item-trying_inr .c-hov {
  display: inline-block;
  padding: .25em 0;
  position: relative;
  -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

a .c-hov:after, .s-item-detail_item-cart-btn .c-hov:after, .s-item-detail_item-trying_inr .c-hov:after {
  content: attr(data-txt);
  display: inline-block;
  padding: .25em 0;
  position: absolute;
  bottom: 0;
  left: 0;
  -webkit-transform: translateY(104%);
  transform: translateY(104%);
}

a:hover .c-hov, .s-item-detail_item-cart-btn:hover .c-hov, .s-item-detail_item-trying:hover .c-hov, .s-item-detail_item-accept:hover .c-hov {
  -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
  transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
  -webkit-transform: translateY(-104%);
  transform: translateY(-104%);
}

.c-btn-middle a {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  height: 102px;
  font-size: .9333333333rem;
  line-height: 1;
  border-radius: 50%;
  position: relative;

  &:hover {
    opacity: 1;

    .c-btn-middle_outline svg path:nth-of-type(1) {
      animation: 0.6s cubic-bezier(0.2, 0.8, 0.5, 0.9) c-str2;
    }
  }
}

@media only screen and (max-width: 750px) {
  .c-btn-middle a {
    height: 63px;
  }
}

.c-btn-middle_inr {
  display: inline-block;
  position: relative;
  padding-right: 22px;
  overflow: hidden;

  &:after {
    content: "";
    display: block;
    width: 12px;
    height: 8px;
    position: absolute;
    bottom: calc(50% - .32em);
    right: 0;
    background-image: url("../img/ico_arrow_b.svg");
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 100% auto;
  }
}

.c-btn-middle_outline {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;

    path:nth-of-type(1) {
      width: 100%;
      height: 100%;
      fill: none;
      stroke-dasharray: 590px;
      stroke-dashoffset: 1180px;
    }
  }
}

.c-btn-middle--w {
  a {
    color: #E3E0D9;
  }

  .c-btn-middle_inr:after {
    background-image: url("../img/ico_arrow_w.svg");
  }
}

.c-btn-small {
  display: table;

  a {
    position: relative;
    display: inline-block;
    font-size: .9333333333rem;
    line-height: 1;

    &:hover {
      opacity: 1;

      .c-btn-small_outline svg path:nth-of-type(1) {
        animation: 0.6s cubic-bezier(0.2, 0.8, 0.5, 0.9) c-str3;
      }
    }
  }
}

.c-btn-small_inr {
  display: inline-block;
  position: relative;
  padding-left: 55px;
  overflow: hidden;
}

.c-btn-small_arrow {
  display: block;
  position: absolute;
  top: calc(50% - 12px);
  left: 0;
  width: 41px;
  height: 25px;
  background-image: url("../img/ico_arrow_b.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 12px 8px;
}

.c-btn-small_outline {
  display: block;
  width: 100%;
  height: 100%;

  svg {
    width: 100%;
    height: 100%;

    path:nth-of-type(1) {
      width: 100%;
      height: 100%;
      fill: none;
      stroke-dasharray: 98px;
      stroke-dashoffset: 196px;
      transition: stroke-dashoffset 0.4s cubic-bezier(0.55, 0.01, 0.45, 1);
    }
  }
}

.c-btn-small--w .c-btn-small_arrow {
  background-image: url("../img/ico_arrow_w.svg");
}

.c-btn-small--r {
  .c-btn-small_inr {
    padding-right: 61px;
    padding-left: 0;
  }

  .c-btn-small_arrow {
    left: auto;
    right: 0;
  }
}

.c-cover {
  position: absolute !important;
  -webkit-mask-position: center top;
  mask-position: center top;
  -webkit-mask-size: 100% 100%;
  mask-size: 100% 100%;
  top: calc(100vh - 16px);
  left: 0;
  width: 100%;
  height: 33.5px;
  -webkit-mask-image: url("/asset/img/mv_btm.png");
  mask-image: url("/asset/img/mv_btm.png");
}

@media only screen and (max-width: 750px) {
  .c-cover {
    top: calc(81.28vh - 18px);
    height: 27px;
    -webkit-mask-image: url("/asset/img/mv_btm_sp.png");
    mask-image: url("/asset/img/mv_btm_sp.png");
  }
}

@media only screen and (max-height: 600px) {
  .c-cover {
    top: calc(600px - 18px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 690px) {
  .c-cover {
    top: calc(560px - 18px);
  }
}

@media only screen and (max-width: 750px) {
  .c-cover-pc {
    display: none;
  }
}

.c-cover-sp {
  display: none;
}

@media only screen and (max-width: 750px) {
  .c-cover-sp {
    display: inline;
  }
}

.c-bk {
  position: relative;
  background-image: url("../img/bg.png");
  background-repeat: repeat;
  background-position: left top;
  background-size: 300px 300px;
  background-color: #e3e0d9;
}

@keyframes anime-noise {
  0% {
    transform: translate3d(0, 9%, 0);
  }

  20% {
    transform: translate3d(-8%, 2%, 0);
  }

  40% {
    transform: translate3d(-2%, 7%, 0);
  }

  60% {
    transform: translate3d(2%, 5%, 0);
  }

  80% {
    transform: translate3d(-9%, 1%, 0);
  }

  100% {
    transform: translate3d(-3%, 3%, 0);
  }
}

.c-para {
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    position: absolute;
    top: calc(50%);
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
    left: 0;
  }
}

.c-text-line a {
  display: inline-block;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #1F2C5C;
    position: absolute;
    bottom: -6px;
    left: 0;
    -webkit-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
  }

  &:hover:after {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
  }
}

.c-ttl-max {
  font-size: 6rem;
  line-height: .7555555556;
  text-align: center;

  .min {
    display: block;
    font-size: .6666666667rem;
    letter-spacing: .02em;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 1200px) {
  .c-ttl-max {
    font-size: calc((100vw - 376px) / 824 * 40 + 50px);
  }
}

@media only screen and (max-width: 375px) {
  .c-ttl-max {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .8;
  }
}

@media only screen and (max-width: 375px) {
  .c-ttl-max .min {
    margin-top: 24px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@media only screen and (max-width: 750px) {
  .c-ttl--pc {
    display: none;
  }
}

.c-ttl--sp {
  display: none;
}

@media only screen and (max-width: 750px) {
  .c-ttl--sp {
    display: block;
  }
}

.c-ttl-large {
  font-size: 5.3333333333rem;
  line-height: .775;

  .min {
    display: block;
    font-size: .6666666667rem;
    letter-spacing: .02em;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 1200px) {
  .c-ttl-large {
    font-size: calc((100vw - 376px) / 824 * 30 + 50px);
  }
}

@media only screen and (max-width: 375px) {
  .c-ttl-large {
    font-size: calc(10px + 10.6666666667vw);
  }
}

@media only screen and (max-width: 375px) {
  .c-ttl-large .min {
    margin-top: 24px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

.c-card-hov_fig {
  display: block;
}

.c-card-hov figure, .c-card-hov_fig {
  transition: transform 0.75s cubic-bezier(0, 0.15, 0.3, 1);
  -webkit-transform: scale(1);
  transform: scale(1);
  will-change: transform;
  overflow: hidden;
}

.c-card-hov {
  img {
    width: 100%;
    transition: transform 0.75s cubic-bezier(0, 0.15, 0.3, 1);
    -webkit-transform: scale(1);
    transform: scale(1);
    will-change: transform;
  }

  a:hover {
    opacity: 1 !important;

    figure, .c-card-hov_fig {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }

    img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

.c-size8-center {
  display: inline-block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: center center;
  transform-origin: center center;
}

.c-size8-left {
  display: inline-block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.c-size8-right {
  display: inline-block;
  font-size: .6666666667rem;
  -webkit-transform: scale(0.8);
  transform: scale(0.8);
  -webkit-transform-origin: right center;
  transform-origin: right center;
}

.l-header {
  position: fixed;
  padding: 0 9.4%;
  width: 100%;
  height: 120px;
  top: 0;
  left: 0;
  line-height: 1;
  z-index: 100;
  background-color: rgba(255, 255, 255, 0);
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
}

.js-loaded .l-header {
  -webkit-transition: top 1s cubic-bezier(0.55, 0.085, 0, 0.99), height 1s cubic-bezier(0.55, 0.085, 0, 0.99), background-color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  transition: top 1s cubic-bezier(0.55, 0.085, 0, 0.99), height 1s cubic-bezier(0.55, 0.085, 0, 0.99), background-color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
}

@media only screen and (max-width: 1110px) {
  .l-header {
    width: 100%;
    padding: 0 4.85%;
  }
}

@media only screen and (max-width: 750px) {
  .l-header {
    width: 100%;
    padding: 0;
    height: 72px;
  }
}

.l-header {
  &.js-header--min {
    background-color: #fff;
    height: 72px;
    box-shadow: 10px -10px 40px -10px rgba(0, 0, 26, 0.1);
  }

  &.js-header--hidden {
    background-color: #fff;
    height: 72px;
    top: -72px;
  }
}

.l-header_logo {
  width: 113px;

  a {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .l-header_logo {
    width: 88px;
    position: absolute;
    top: 30px;
    left: 30px;
  }
}

.l-nav {
  width: calc(100% - 113px);
  font-size: .9333333333rem;
  letter-spacing: .1190285714em;
  color: #00001A;
  z-index: 100;
}

@media only screen and (max-width: 860px) {
  .l-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 0;
    margin: 0;
    background-color: #00001A;
    overflow: hidden;
    opacity: 0;
    -webkit-transition: opacity 0.8s cubic-bezier(0.55, 0.085, 0, 0.99), height 0s 0.8s;
    transition: opacity 0.8s cubic-bezier(0.55, 0.085, 0, 0.99), height 0s 0.8s;
  }

  .js-nav-sp--active .l-nav {
    -webkit-transition: opacity 1s cubic-bezier(0.55, 0.085, 0, 0.99), height 0s;
    transition: opacity 1s cubic-bezier(0.55, 0.085, 0, 0.99), height 0s;
    opacity: 1;
    height: 100%;
  }
}

@media only screen and (max-width: 860px) {
  .l-nav_pc {
    display: none;
  }
}

.l-nav_pc ul {
  display: flex;
  flex-wrap: wrap;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  width: 100%;
  padding-right: 15.62vw;

  a {
    display: inline-block;
    position: relative;
  }
}

.l-header--white {
  .l-nav_pc ul a {
    color: #ffffff;
    -webkit-transition: color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
    transition: color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  }

  &.js-header--min .l-nav_pc ul a {
    color: inherit;
  }
}

.l-nav_pc {
  ul a {
    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      position: absolute;
      bottom: -10px;
      left: 0;
      background-color: currentColor;
      -webkit-transform-origin: right top;
      transform-origin: right top;
      -webkit-transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
      -webkit-transform: scaleX(0);
      transform: scaleX(0);
    }

    &:hover {
      opacity: 1;

      &:after {
        -webkit-transform-origin: left top;
        transform-origin: left top;
        -webkit-transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transform: scaleX(1);
        transform: scaleX(1);
      }
    }

    &.js-current:after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  li {
    margin-right: 2.34vw;
  }
}

.l-nav_pc-login {
  margin: 0 3.9vw !important;
}

.l-nav_pc-download {
  position: absolute;
  right: 9.4%;
  top: 50%;
  width: 15.62vw;
  min-width: 152px;
  margin: 0 0 0 auto;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  overflow: hidden;

  a {
    color: #00001A;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
  }
}

@media only screen and (max-width: 1110px) {
  .l-nav_pc-download {
    right: 4.85%;
  }
}

.l-header--white {
  .l-nav_pc-download a {
    color: #ffffff;
    -webkit-transition: color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
    transition: color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  }

  &.js-header--min .l-nav_pc-download a {
    color: inherit;
  }
}

.l-nav_pc-download a:after {
  content: "";
  display: block;
  width: 18px;
  height: 14px;
  position: absolute;
  right: 12px;
  top: calc(50% - 7px);
  //background-image: url("../img/c_icon_download.svg");
  background-repeat: no-repeat;
  background-position: left top;
  background-size: 100% auto;
}

@media only screen and (max-width: 1110px) {
  .l-nav_pc-download a:after {
    right: 0;
  }
}

.l-nav_pc-download-inner {
  padding: 20px 0;
  height: 56px;
  position: static;
}

.js-loaded .l-nav_pc-download-inner {
  -webkit-transition: padding 1s cubic-bezier(0.55, 0.085, 0, 0.99), height 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  transition: padding 1s cubic-bezier(0.55, 0.085, 0, 0.99), height 1s cubic-bezier(0.55, 0.085, 0, 0.99);
}

.l-nav_pc-download-inner {
  &:before, &:after {
    bottom: 0;
  }
}

.js-header--min .l-nav_pc-download-inner {
  padding: 30px 0 0 0;
  height: 72px;

  &:before, &:after {
    bottom: 0px;
  }
}

.js-header--hidden .l-nav_pc-download-inner {
  padding: 30px 0 0 0;
  height: 72px;
}

.l-nav_pc-download-inner_pc-type-white a {
  color: #fff;
}

.l-nav_sp {
  display: none;
}

@media only screen and (max-width: 860px) {
  .l-nav_sp {
    display: block;
    margin: 0 auto;
    padding: 128px 0 24px 0;
    font-size: 16px;
    letter-spacing: .1101em;

    ul a {
      display: inline-block;
      position: relative;
      color: #fff;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        bottom: -10px;
        left: 0;
        background-color: currentColor;
        -webkit-transform-origin: right top;
        transform-origin: right top;
        -webkit-transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
        -webkit-transform: scaleX(0);
        transform: scaleX(0);
      }

      &:hover {
        opacity: 1;

        &:after {
          -webkit-transform-origin: left top;
          transform-origin: left top;
          -webkit-transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1);
          -webkit-transform: scaleX(1);
          transform: scaleX(1);
        }
      }
    }

    li {
      margin-bottom: 28px;
    }
  }
}

.l-nav_sp-header {
  width: 88px;
  position: absolute;
  top: 30px;
  left: 30px;

  a {
    display: block;
  }

  img {
    width: 88px;
    height: 16.75px;
  }
}

.l-nav_sp-inner {
  width: 100%;
  padding: 0 14.5%;
  height: 100%;
}

.l-nav_sp-login {
  margin: 50px 0 !important;
  font-size: 15px;
  letter-spacing: .1142666667em;
}

.l-nav_sp-download {
  position: relative;
  margin-top: 71px;
  margin-bottom: 33px;

  a {
    color: #fff;
    padding: 0;

    &:after {
      content: "";
      display: block;
      width: 18px;
      height: 14px;
      position: absolute;
      right: 10px;
      top: 2px;
      //background-image: url("../img/c_icon_download.svg");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
    }
  }
}

.l-nav_sp-download-inner {
  padding-bottom: 22px;

  &:before, &:after {
    height: 2px;
  }
}

.l-nav_sp-demo {
  margin-bottom: 65px;

  a {
    display: inline-block;
    position: relative;
    padding: 6px 0;
    color: #fff;

    &:after {
      content: "";
      display: inline-block;
      width: 25px;
      height: 14px;
      margin-left: 17px;
      //background-image: url("../img/c_icon_demo.svg");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
    }
  }
}

.l-nav_sp-sub1 {
  margin-bottom: 54px;
  font-size: 13px;
  letter-spacing: .1245230769em;
  line-height: 1;

  ul li {
    display: inline-block;
    margin-right: 25px;
    margin-bottom: 0;

    a {
      color: #fff;

      &:after {
        display: none;
      }

      &:hover {
        opacity: .7;
      }
    }
  }
}

.l-nav_sp-sub2 {
  margin-bottom: 45px;
  font-size: 11px;
  letter-spacing: .1476em;
  line-height: 1;

  ul {
    margin-bottom: 18px;

    li {
      display: inline-block;
      margin-right: 18px;
      margin-bottom: 0;

      a {
        color: #fff;

        &:after {
          display: none;
        }

        &:hover {
          opacity: .7;
        }
      }
    }
  }
}

.l-nav_sp-sub3 {
  margin-bottom: 81px;
  font-size: 11px;
  letter-spacing: .1476em;
  line-height: 1;

  ul li {
    margin-bottom: 17px;

    a {
      color: #fff;

      &:after {
        display: none;
      }

      &:hover {
        opacity: .7;
      }
    }
  }
}

.l-nav-sp-btn {
  display: none;
}

@media only screen and (max-width: 860px) {
  .l-nav-sp-btn {
    display: block;
    position: absolute;
    top: 19px;
    right: 30px;
    width: 40px;
    height: 40px;
    z-index: 101;

    a {
      display: block;
      height: 40px;
      position: relative;

      &:before {
        content: "";
        display: block;
        width: 100%;
        height: 1px;
        position: absolute;
        left: 0;
        background-color: #00001A;
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-transform: translateY(17px) rotate(0deg);
        transform: translateY(17px) rotate(0deg);
        -webkit-transition: width 1s cubic-bezier(0.55, 0.085, 0, 0.99), left 1s cubic-bezier(0.55, 0.085, 0, 0.99), transform 1s cubic-bezier(0.55, 0.085, 0, 0.99), background-color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
        transition: width 1s cubic-bezier(0.55, 0.085, 0, 0.99), left 1s cubic-bezier(0.55, 0.085, 0, 0.99), transform 1s cubic-bezier(0.55, 0.085, 0, 0.99), background-color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
      }
    }
  }

  .l-header--white {
    .l-nav-sp-btn a:before {
      background-color: #ffffff;
    }

    &.js-header--min .l-nav-sp-btn a:before {
      background-color: #00001A;
    }
  }

  .l-nav-sp-btn a:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    position: absolute;
    left: 0;
    background-color: #00001A;
    -webkit-transform-origin: center center;
    transform-origin: center center;
    -webkit-transform: translateY(23px) rotate(0deg);
    transform: translateY(23px) rotate(0deg);
    -webkit-transition: width 1s cubic-bezier(0.55, 0.085, 0, 0.99), left 1s cubic-bezier(0.55, 0.085, 0, 0.99), transform 1s cubic-bezier(0.55, 0.085, 0, 0.99), background-color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
    transition: width 1s cubic-bezier(0.55, 0.085, 0, 0.99), left 1s cubic-bezier(0.55, 0.085, 0, 0.99), transform 1s cubic-bezier(0.55, 0.085, 0, 0.99), background-color 1s cubic-bezier(0.55, 0.085, 0, 0.99);
  }

  .l-header--white {
    .l-nav-sp-btn a:after {
      background-color: #ffffff;
    }

    &.js-header--min .l-nav-sp-btn a:after {
      background-color: #00001A;
    }
  }

  .js-nav-sp--active .l-nav-sp-btn a {
    &:before {
      width: 23px;
      left: 8px;
      background-color: #fff !important;
      -webkit-transform: translateY(20px) rotate(45deg);
      transform: translateY(20px) rotate(45deg);
    }

    &:after {
      width: 23px;
      left: 8px;
      background-color: #fff !important;
      -webkit-transform: translateY(20px) rotate(-45deg);
      transform: translateY(20px) rotate(-45deg);
    }
  }
}

.l-slider, .l-slider-5item, .l-slider-4item {
  width: 100%;
  text-align: center;
  padding-bottom: 77px;

  * {
    outline: none !important;
  }

  a {
    display: block;
  }

  figure {
    margin-bottom: 25px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-slider {
    padding-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider figure {
    margin-bottom: 23px;
  }
}

.l-slider_block-ttl {
  margin-bottom: 12px;
}

@media only screen and (max-width: 750px) {
  .l-slider_block-ttl {
    margin-bottom: 14px;
  }
}

.l-slider-3item {
  a {
    display: block;
  }

  figure {
    overflow: hidden;

    img {
      width: 100%;
    }
  }
}

.l-slider-item-slide-large {
  width: 100%;
}

.s-item-archives {
  position: relative;
  width: 85.9375vw;
  padding: 80px 0;
  margin-left: 4.6875vw;
}

@media only screen and (max-width: 1280px) {
  .s-item-archives {
    padding: calc((100vw - 750px) / 530 * 60 + 20px) 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives {
    width: auto;
    display: block;
    padding: 40px 0;
    margin: 0;
  }
}

.s-item-archives_nav {
  position: fixed;
  width: 15.625vw;
  left: 4.6875vw;
  bottom: 20px;
  z-index: 5;

  &.s-item-archives_nav--pc {
    display: block;
    -webkit-transition: top 0.6s cubic-bezier(0.55, 0.01, 0.45, 1), opacity 0.2s;
    transition: top 0.6s cubic-bezier(0.55, 0.01, 0.45, 1), opacity 0.2s;

    &.js-hide {
      opacity: 0;
      pointer-events: none;
    }
  }

  &.s-item-archives_nav--sp {
    display: none;
  }

  &-media {
    li.item-txt {
      position: relative;
      margin-left: 1rem;

      &:before {
        margin-left: -1rem;
        content: "";
        display: block;
        width: 12px;
        height: 8px;
        position: absolute;
        top: calc(50% - 4px);
        background-image: url("../img/ico_arrow_b.svg");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 100% auto;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav {
    width: auto;
    height: auto !important;
    top: auto;
    bottom: auto;
    left: auto;
    position: relative;
    margin: 21px 9.0666666667vw 30px 9.0666666667vw;
    border-top: none;
    padding: 0 1px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav.s-item-archives_nav--pc {
    display: none !important;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav.s-item-archives_nav--sp {
    display: block !important;
  }
}

.s-item-archives_nav-inr {
  position: relative;
  height: 100%;
  border-top: 1px solid #BFBCB4;
  padding-top: 16px;
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-inr {
    height: auto;
    position: static !important;
    border-top: none;

    .simplebar-wrapper {
      overflow: visible !important;
    }

    .simplebar-mask {
      position: static !important;
      overflow: visible !important;
    }

    .simplebar-content-wrapper {
      overflow: visible !important;
      padding-right: 0 !important;
    }

    .simplebar-offset {
      position: static !important;
    }

    .simplebar-content {
      display: block !important;
    }

    .simplebar-placeholder, .simplebar-track {
      display: none !important;
    }
  }
}

.s-item-archives_nav-ttl {
  font-size: 1.8666666667rem;
  margin-bottom: 37px;
  line-height: 1;
  position: relative;

  .icon {
    margin-right: 22px;

    img {
      width: 16px;
      vertical-align: 0;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-archives_nav-ttl {
    font-size: calc((100vw - 376px) / 826 * 2 + 26px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-ttl {
    height: 40px;
    margin-bottom: 0;
    cursor: pointer;
    border-bottom: 1px solid #BFBCB4;
    -webkit-transition: border-bottom 0s;
    transition: border-bottom 0s;
    -webkit-transition-delay: .3s;
    transition-delay: .3s;

    &:after {
      content: "";
      display: block;
      width: 5px;
      height: 5px;
      border: 1px solid #1F2C5C;
      border-top: none;
      border-left: none;
      position: absolute;
      top: 9px;
      right: 10px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transition: top 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
      transition: top 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
    }
  }
}

@media only screen and (max-width: 750px) and (max-width: 750px) {
  .s-item-archives_nav-ttl:after {
    top: 9px;
    right: 13px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-archives_nav-ttl {
    font-size: calc(10px + 4.2666666667vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-ttl .icon {
    margin-right: 19px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-ttl.filter-open {
    border-bottom: none;
    -webkit-transition: border-bottom 0s;
    transition: border-bottom 0s;

    &:after {
      -webkit-transform: rotate(-135deg);
      transform: rotate(-135deg);
      top: 12px;
    }
  }
}

.s-item-archives_nav-ttl-sub {
  display: none;
  font-size: .8rem;
  position: absolute;
  top: 9px;
  right: 33px;
  letter-spacing: .02em;
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-ttl-sub {
    display: inline-block;
  }
}

.s-item-archives_nav-subttl {
  font-size: 1.3333333333rem;
  margin-bottom: 15px;
  line-height: 1;
}

@media only screen and (max-width: 1200px) {
  .s-item-archives_nav-subttl {
    font-size: calc((100vw - 376px) / 826 * 2 + 18px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-subttl {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-archives_nav-subttl {
    font-size: calc(10px + 2.1333333333vw);
  }
}

.s-item-archives_nav-subttl--2 {
  margin-bottom: 19px;
}

.s-item-archives_nav-filter:before {
  display: none;
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-filter:before {
    display: block;
    background-image: url("../img/bg.png");
    background-repeat: repeat;
    background-position: left top;
    background-size: 300px auto;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-filter {
    border-bottom: 1px solid #BFBCB4;
    padding: 8vw 9.0666666667vw 39px 9.0666666667vw;
    position: absolute;
    width: calc(100% + 18.12vw);
    top: 40px;
    left: 0;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
    -webkit-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.19, 0.82, 0.27, 1);
    transition: opacity 0.5s, transform 0.5s cubic-bezier(0.19, 0.82, 0.27, 1);
    z-index: 1;
    margin: 0 -9.0666666667vw;

    > * {
      opacity: 0;
      -webkit-transition: opacity 0.1s cubic-bezier(0.6, 0.25, 0, 1);
      transition: opacity 0.1s cubic-bezier(0.6, 0.25, 0, 1);
      -webkit-transition-delay: 0s;
      transition-delay: 0s;
    }

    &.open {
      -webkit-transition: opacity 0.6s, transform 0.6s cubic-bezier(0.19, 0.82, 0.27, 1);
      transition: opacity 0.6s, transform 0.6s cubic-bezier(0.19, 0.82, 0.27, 1);
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;

      > * {
        opacity: 1;
        -webkit-transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1);
        transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1);
        -webkit-transition-delay: .46s;
        transition-delay: .46s;
      }
    }
  }
  #backnumbers {
    .s-media-archives_back_number {
      -webkit-transform-origin: center top;
      transform-origin: center top;
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      opacity: 1;
      -webkit-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.19, 0.82, 0.27, 1);
      transition: opacity 0.5s, transform 0.5s cubic-bezier(0.19, 0.82, 0.27, 1);
      z-index: 1;

      &.open {
        -webkit-transition: opacity 0.6s, transform 0.6s cubic-bezier(0.19, 0.82, 0.27, 1);
        transition: opacity 0.6s, transform 0.6s cubic-bezier(0.19, 0.82, 0.27, 1);
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
        opacity: 1;

        > * {
          opacity: 1;
          -webkit-transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1);
          transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1);
          -webkit-transition-delay: .46s;
          transition-delay: .46s;
        }
      }
    }

    h4 {
      span.txt {
        position: relative;

        &:after {
          content: "";
          display: block;
          width: 5px;
          height: 5px;
          border: 1px solid #1F2C5C;
          border-top: none;
          border-left: none;
          position: absolute;
          top: calc(50% - 5px);
          right: -1rem;
          transform: rotate(45deg);
          transition: top 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
        }
      }

      &.open span.txt:after {
        transform: rotate(225deg);
        top: 50%;
      }
    }
  }
}

.s-item-archives_nav-new {
  font-size: .8666666667rem;
  margin: 0 0 12px 9px;

  a {
    color: #F08300;
    display: inline-block;
    overflow: hidden;
  }

  .current a {
    border-bottom: 1px solid #1F2C5C;

    .c-hov {
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
      transform: none;

      &:after {
        -webkit-transform: none;
        transform: none;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-archives_nav-new {
    font-size: calc((100vw - 376px) / 826 * 1 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-new {
    display: none;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-archives_nav-new {
    font-size: calc(10px + .5333333333vw);
  }
}

.s-item-archives_nav-filter01 {
  font-size: .8666666667rem;
  margin: 0 0 34px 9px;

  li {
    margin-bottom: 8px;
    line-height: 1.4;

    &.new a {
      color: #F08300;
    }
  }

  a {
    overflow: hidden;
    display: inline-block;
  }

  .current a {
    border-bottom: 1px solid #1F2C5C;

    .c-hov {
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
      transform: none;

      &:after {
        -webkit-transform: none;
        transform: none;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-archives_nav-filter01 {
    font-size: calc((100vw - 376px) / 826 * 1 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-filter01 {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 25px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-archives_nav-filter01 {
    font-size: calc(10px + .5333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-filter01 li {
    width: 50%;
    padding-left: 14px;
    margin-bottom: 8px;
  }
}

.s-item-archives_nav-filter02 {
  font-size: .6666666667rem;
  margin: 0 0 0 9px;

  li {
    margin-bottom: 9px;
    line-height: 1.4;

    img {
      width: 100%;
    }
  }

  a {
    overflow: hidden;
    display: inline-block;
    width: 52.35%;
  }

  .txt {
    display: table;
    margin-top: 6px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: left top;
    transform-origin: left top;
    letter-spacing: normal;
  }

  .current .txt {
    border-bottom: 1px solid #1F2C5C;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-filter02 {
    display: flex;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-filter02 li {
    width: 27.2%;
    margin: 0 6.8% 10px 0;

    &:nth-of-type(3n) {
      margin-right: 0;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_nav-filter02 .txt {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    margin-top: 10px;
    width: 100%;
  }
}

.s-item-archives_list {
  margin-left: 23.4375vw;
  margin-bottom: 200px;
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list {
    margin: 0 7.6vw;
    margin-bottom: 117px;
  }
}

.s-item-archives_list-group {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 5%;
  grid-row-gap: 3.046875vw;
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-group {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    grid-column-gap: unset;
    grid-row-gap: unset;
  }
}

.s-item-archives_list-itemname {
  font-size: .8rem;
  margin-bottom: 5px;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

@media only screen and (max-width: 1200px) {
  .s-item-archives_list-itemname {
    font-size: calc((100vw - 376px) / 826 * 1 + 11px);
  }
}

@media only screen and (max-width: 375px) {
  .s-item-archives_list-itemname {
    font-size: calc(10px + .2666666667vw);
  }
}

.s-item-archives_list-item-colorchip {
  margin-bottom: 13px;

  ul {
    text-align: center;
    font-size: 0;
  }

  li {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 0 1px;
    position: relative;
    cursor: pointer;
    border: 6px solid transparent;
    background-clip: padding-box;

    &:hover {
      background-color: #000;
    }

    &.col-current:before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      position: absolute;
      top: -4px;
      left: -4px;
      border: 1px solid #BFBCB4;
      border-radius: 50%;
      z-index: 1;
    }

    &.outline:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      z-index: 10;
      border-radius: 50%;
      left: 0;
      top: 0;
      border-radius: 50%;
      position: absolute;
      box-sizing: border-box;
      z-index: 1;
    }
  }
}

.s-item-archives_list-block {
  text-align: center;

  a {
    display: block;
  }

  .c-item-price {
    -webkit-transform: none;
    transform: none;
    font-size: .6666666667rem;
    line-height: 1.6;
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block {
    width: 45.28%;
    margin: 0 2.36% 7.4666666667vw 2.36%;
  }
}

.s-item-archives_list-block-fig-a {
  padding-top: 128.2352941176%;
  margin-bottom: 17px;
  position: relative;

  figure {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
    }

    figcaption {
      display: inline-block;
      position: absolute;
      bottom: 5px;
      left: 3px;
      background-color: #F08300;
      padding: 8px 14px;
      border-radius: 50%;
      font-size: .6666666667rem;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      line-height: 1;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block-fig-a {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block-fig-a figure figcaption {
    bottom: 6px;
    left: 4px;
    padding: 8px 13px;
  }
}

.s-item-archives_list-block {
  &:nth-of-type(1) {
    grid-area: 1 / 1 / 3 / 3;
  }

  &:nth-of-type(2) {
    grid-area: 1 / 3 / 2 / 4;
  }

  &:nth-of-type(3) {
    grid-area: 1 / 4 / 2 / 5;
  }

  &:nth-of-type(4) {
    grid-area: 2 / 3 / 3 / 4;
  }

  &:nth-of-type(5) {
    grid-area: 2 / 4 / 3 / 5;
  }

  &:nth-of-type(6) {
    grid-area: 3 / 1 / 4 / 2;
  }

  &:nth-of-type(7) {
    grid-area: 3 / 2 / 4 / 3;
  }

  &:nth-of-type(8) {
    grid-area: 3 / 3 / 4 / 4;
  }

  &:nth-of-type(9) {
    grid-area: 3 / 4 / 4 / 5;
  }

  &:nth-of-type(10) {
    grid-area: 4 / 3 / 6 / 5;
  }

  &:nth-of-type(11) {
    grid-area: 4 / 1 / 5 / 2;
  }

  &:nth-of-type(12) {
    grid-area: 4 / 2 / 5 / 3;
  }

  &:nth-of-type(13) {
    grid-area: 5 / 1 / 6 / 2;
  }

  &:nth-of-type(14) {
    grid-area: 5 / 2 / 6 / 3;
  }

  &:nth-of-type(15) {
    grid-area: 6 / 1 / 7 / 2;
  }

  &:nth-of-type(16) {
    grid-area: 6 / 2 / 7 / 3;
  }

  &:nth-of-type(17) {
    grid-area: 6 / 3 / 7 / 4;
  }

  &:nth-of-type(18) {
    grid-area: 6 / 4 / 7 / 5;
  }

  &:nth-of-type(19) {
    grid-area: 7 / 1 / 9 / 3;
  }

  &:nth-of-type(20) {
    grid-area: 7 / 3 / 8 / 4;
  }

  &:nth-of-type(21) {
    grid-area: 7 / 4 / 8 / 5;
  }

  &:nth-of-type(22) {
    grid-area: 8 / 3 / 9 / 4;
  }

  &:nth-of-type(23) {
    grid-area: 8 / 4 / 9 / 5;
  }

  &:nth-of-type(24) {
    grid-area: 9 / 1 / 10 / 2;
  }

  &:nth-of-type(25) {
    grid-area: 9 / 2 / 10 / 3;
  }

  &:nth-of-type(26) {
    grid-area: 9 / 3 / 10 / 4;
  }

  &:nth-of-type(27) {
    grid-area: 9 / 4 / 10 / 5;
  }

  &:nth-of-type(28) {
    grid-area: 10 / 3 / 12 / 5;
  }

  &:nth-of-type(29) {
    grid-area: 10 / 1 / 11 / 2;
  }

  &:nth-of-type(30) {
    grid-area: 10 / 2 / 11 / 3;
  }

  &:nth-of-type(31) {
    grid-area: 11 / 1 / 12 / 2;
  }

  &:nth-of-type(32) {
    grid-area: 11 / 2 / 12 / 3;
  }

  &:nth-of-type(1) .c-size8-right, &:nth-of-type(10) .c-size8-right, &:nth-of-type(19) .c-size8-right, &:nth-of-type(28) .c-size8-right {
    -webkit-transform: none;
    transform: none;
  }

  &:nth-of-type(1) .s-item-archives_list-itemname, &:nth-of-type(10) .s-item-archives_list-itemname, &:nth-of-type(19) .s-item-archives_list-itemname, &:nth-of-type(28) .s-item-archives_list-itemname {
    font-size: 1.3333333333rem;
    margin-bottom: 9px;
  }

  &:nth-of-type(1) .c-item-price, &:nth-of-type(10) .c-item-price, &:nth-of-type(19) .c-item-price, &:nth-of-type(28) .c-item-price {
    font-size: .8rem;
    margin: 0;
  }

  &:nth-of-type(1) .s-item-archives_list-block-fig-a figure, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure {
    margin-bottom: 29px;
  }

  &:nth-of-type(1) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure figcaption {
    bottom: 12px;
    left: 12px;
    -webkit-transform: none;
    transform: none;
    padding: 10px 14px;
  }

  &:nth-of-type(1) .s-item-archives_list-item-colorchip, &:nth-of-type(10) .s-item-archives_list-item-colorchip, &:nth-of-type(19) .s-item-archives_list-item-colorchip, &:nth-of-type(28) .s-item-archives_list-item-colorchip {
    margin-bottom: 19px;
  }

  &:nth-of-type(1) .s-item-archives_list-item-colorchip li, &:nth-of-type(10) .s-item-archives_list-item-colorchip li, &:nth-of-type(19) .s-item-archives_list-item-colorchip li, &:nth-of-type(28) .s-item-archives_list-item-colorchip li {
    width: 22px;
    height: 22px;
  }

  &:nth-of-type(1) .s-item-archives_list-item-colorchip li.col-current:before, &:nth-of-type(10) .s-item-archives_list-item-colorchip li.col-current:before, &:nth-of-type(19) .s-item-archives_list-item-colorchip li.col-current:before, &:nth-of-type(28) .s-item-archives_list-item-colorchip li.col-current:before {
    content: "";
    display: block;
    width: 16px;
    height: 16px;
    position: absolute;
    top: -4px;
    left: -4px;
    z-index: 1;
  }

  &:nth-of-type(1) .s-item-archives_list-item-colorchip li.outline:after, &:nth-of-type(10) .s-item-archives_list-item-colorchip li.outline:after, &:nth-of-type(19) .s-item-archives_list-item-colorchip li.outline:after, &:nth-of-type(28) .s-item-archives_list-item-colorchip li.outline:after {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    z-index: 1;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block {
    &:nth-of-type(1), &:nth-of-type(10), &:nth-of-type(19), &:nth-of-type(28) {
      width: 95.28%;
      margin: 0 2.36% 9.3333333333vw 2.36%;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-itemname, &:nth-of-type(10) .s-item-archives_list-itemname, &:nth-of-type(19) .s-item-archives_list-itemname, &:nth-of-type(28) .s-item-archives_list-itemname {
      font-size: calc((100vw - 376px) / 826 * 5 + 15px);
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-itemname, &:nth-of-type(10) .s-item-archives_list-itemname, &:nth-of-type(19) .s-item-archives_list-itemname, &:nth-of-type(28) .s-item-archives_list-itemname {
      margin-bottom: 6px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-itemname, &:nth-of-type(10) .s-item-archives_list-itemname, &:nth-of-type(19) .s-item-archives_list-itemname, &:nth-of-type(28) .s-item-archives_list-itemname {
      font-size: calc(10px + 1.3333333333vw);
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-block-fig-a figure, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure {
      margin-bottom: 23px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure figcaption {
      bottom: 14px;
      left: 14px;
      padding: 10px 13px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-item-colorchip, &:nth-of-type(10) .s-item-archives_list-item-colorchip, &:nth-of-type(19) .s-item-archives_list-item-colorchip, &:nth-of-type(28) .s-item-archives_list-item-colorchip {
      margin-bottom: 16px;
    }
  }
}

.s-item-archives--shotrmode {
  .s-item-archives {
    display: flex;
  }

  .s-item-archives_list {
    margin-left: 7.8125vw;
    flex: 1;
  }

  .s-item-archives_nav--sp {
    display: block;
    position: relative;
    left: 0;
    top: 0;
    bottom: auto;
  }

  .s-item-archives_nav--pc {
    display: none;
  }
}

.js-cart-cloned {
  position: fixed;
  width: 32.8125vw;
  right: 9.375vw;
  z-index: 50;
  pointer-events: none;
}

@media only screen and (max-width: 750px) {
  .js-cart-cloned {
    display: none;
  }
}

.js-cart-abs .js-itemdetail-cart {
  position: absolute;
  bottom: 0;
  right: 0;
  top: auto !important;
}

@media only screen and (max-width: 860px) {
  .js-cart-abs .js-itemdetail-cart {
    right: 4.6875vw;
  }
}

.p-item-detail {
  .grecaptcha-badge {
    visibility: hidden;
  }

  .l-contents-large-group {
    padding-bottom: 168px;
  }

  .l-slider-3item li {
    width: 28.84%;
    margin-right: 6.74%;

    .c-item-price {
      margin-top: 7px;
    }

    .c-item-name {
      font-size: 1.0666666667rem;
    }

    &:nth-of-type(2) {
      width: 28.84%;

      .c-item-name {
        font-size: 1.0666666667rem;
        margin: 26px 0 0 0;
      }
    }

    &:nth-of-type(3) {
      width: 28.84%;
      margin-right: 0;
    }
  }

  .l-contents-care {
    padding-bottom: 250px;
  }
}

@media only screen and (max-width: 750px) {
  .p-item-detail .l-contents-large-group {
    padding-bottom: 103px;
  }
}

@media only screen and (max-width: 750px) {
  .p-item-detail .l-slider-3item li {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .p-item-detail .l-slider-3item li .c-item-name {
    font-size: calc((100vw - 376px) / 826 * 3 + 13px);
  }
}

@media only screen and (max-width: 750px) {
  .p-item-detail .l-slider-3item li .c-item-name {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-item-detail .l-slider-3item li:nth-of-type(2) .c-item-name {
    font-size: calc((100vw - 376px) / 826 * 3 + 13px);
  }
}

@media only screen and (max-width: 750px) {
  .p-item-detail .l-slider-3item li:nth-of-type(2) .c-item-name {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .p-item-detail .l-slider-3item li:nth-of-type(3) {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .p-item-detail .l-contents-care {
    padding-bottom: 121px;
  }
}

.s-item-detail {
  padding: 10px 0 0 0;
}

@media only screen and (max-width: 750px) {
  .s-item-detail {
    padding: 0;
  }
}

.s-item-detail_header {
  display: flex;
  flex-wrap: wrap;
  margin-right: 9.375vw;
  position: relative;
}

@media only screen and (max-width: 860px) {
  .s-item-detail_header {
    margin-right: 4.6875vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_header {
    display: block;
    margin-right: 0;
  }
}

.s-item-detail_fig {
  width: 50vw;
  margin-right: 7.8125vw;

  figure {
    position: relative;

    img {
      width: 100%;
    }

    figcaption {
      font-size: .8rem;
      position: absolute;
      bottom: 14px;
      right: 14px;
      mix-blend-mode: difference;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 860px) {
  .s-item-detail_fig {
    width: 45.3125vw;
    margin-right: 3.90625vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_fig {
    width: 100%;
    margin-right: 0;
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_fig figure figcaption {
    font-size: calc(10px + .2666666667vw);
  }
}

.s-item-detail_iteminfo {
  position: fixed;
  width: 32.8125vw;
  right: 9.375vw;
  transition: top 0.6s cubic-bezier(0.55, 0.01, 0.45, 1);
  z-index: 50;
  overflow: hidden;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_iteminfo {
    margin: 0 9.6vw;
  }

  .s-item-detail_header .s-item-detail_iteminfo {
    position: relative;
    width: auto;
    top: auto !important;
    bottom: auto !important;
    right: 0;
  }
}

.s-item-detail_item-name {
  font-size: 1.8666666667rem;
  line-height: 1.1428571429;
  margin-bottom: 16px;
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_item-name {
    font-size: calc((100vw - 376px) / 826 * 4 + 24px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-name {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_item-name {
    font-size: calc(10px + 3.7333333333vw);
    line-height: 1.25;
  }
}

.s-item-detail_item-subname {
  font-size: .6666666667rem;
  line-height: 1.28;
  margin-bottom: 22px;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-subname {
    margin-bottom: 13px;
  }
}

.s-item-detail_item-price {
  font-size: 1.6rem;
  line-height: 1;
  margin-bottom: 36px;

  &.off {
    color: #F08300;
    margin-top: -26px;
  }

  .off-label {
    margin-left: 14px;
    font-size: .7333333333rem;
    color: #fff;
    display: inline-block;
    background-color: #F08300;
    padding: 2px 6px;
    line-height: 1;
    border-radius: 4px;
  }

  .crcode {
    font-size: 1.0666666667rem;
  }

  .tax {
    font-size: .6666666667rem;
    margin-left: 6px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_item-price {
    font-size: calc((100vw - 376px) / 826 * 0 + 24px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-price {
    margin-bottom: 23px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_item-price {
    font-size: calc(10px + 3.7333333333vw);
    line-height: 1.28;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-price.off {
    margin-top: -22px;
  }
}

.s-item-detail_select {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: flex-end;
  align-items: flex-end;
  margin-bottom: 28px;
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_select {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_select {
    display: block;
    margin-bottom: 24px;
  }
}

.s-item-detail_select-field {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  width: 250px;
  margin-right: 1.5625vw;

  .item-sku {
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;

    dt {
      width: 70px;
      font-size: .6666666667rem;
      margin-bottom: 8px;
      font-family: aktiv-grotesk-extended, sans-serif;
      font-weight: 700;
      font-style: normal;
    }

    dd {
      width: calc(100% - 70px);
      margin-bottom: 8px;
      position: relative;

      &:after {
        pointer-events: none;
        position: absolute;
        display: block;
        content: '';
        width: 6px;
        height: 6px;
        top: calc(50% - 5.5px);
        right: 20px;
        border-bottom: 1px solid #1F2C5C;
        border-right: 1px solid #1F2C5C;
        transform: rotate(45deg);
        transition: transform .4s ease-in-out;
        transform-origin: center center;
      }
    }
  }

  select {
    position: relative;
    border: 1px solid #BFBCB4;
    outline: 0;
    padding: 10px 32px 10px 20px;
    border-radius: 6px;
    line-height: 1.2;
    cursor: pointer;
    font-weight: 700;
    background-image: url("../img/bg.png");
    background-repeat: repeat;
    background-position: left top;
    background-size: 300px 300px;
    background-color: #e3e0d9;

    &:focus {
      border: 1px solid #1F2C5C;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_select-field {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_select-field {
    width: auto;
    margin-right: 0;
    margin-bottom: -7px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_select-field select {
    font-size: calc((100vw - 376px) / 826 * 3 + 10px);
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_select-field select {
    font-size: calc(10px + .8vw);
  }
}

.s-item-detail_scrollcart .s-item-detail_select-field select {
  background-image: none;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart .s-item-detail_select-field select {
    border-width: 0 0 1px 0;
    border-radius: 0;
    padding: 10px 32px 10px 6px;
  }
}

.s-item-detail_select-field select option {
  font-weight: 400;
}

.s-item-detail_selector-sizeguide {
  width: 100%;
  font-size: .7333333333rem;
  margin-bottom: 18px;

  .icon_guide img {
    width: 14px;
    vertical-align: -.2em;
    margin-right: 4px;
  }

  .txt_guide {
    border-bottom: 1px solid #1F2C5C;
    cursor: pointer;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;

    &:hover {
      opacity: .7;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_selector-sizeguide {
    font-size: calc((100vw - 376px) / 826 * 1 + 10px);
    margin-top: 5px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_selector-sizeguide {
    text-align: right;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_selector-sizeguide {
    font-size: calc(10px + 0vw);
  }
}

.s-item-detail_item-buy-wrap {
  margin-bottom: 44px;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-buy-wrap {
    margin-bottom: 40px;
  }
}

.s-item-detail_item-buy-desc {
  font-size: .7333333333rem;
  margin-bottom: 10px;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-buy-desc {
    margin-bottom: 6px;
  }
}

.s-item-detail_item-cart-btn-wrap {
  margin-bottom: 8px;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-cart-btn-wrap {
    height: 66px;
    position: relative;
    margin-bottom: 2px;
  }
}

.s-item-detail_item-cart-btn {
  position: relative;

  input[type=submit] {
    cursor: pointer;
    width: 100%;
    height: 66px;
    text-align: center;
    display: block;
    background-color: #F08300;
    border-radius: 6px;
  }

  &.nostock {
    width: 100%;
    height: 66px;
    text-align: center;
    display: block;
    background-color: #BFBCB4;
    border-radius: 6px;

    .s-item-detail_item-cart-btn-txt {
      padding-left: 0;

      &:after {
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-cart-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-cart-btn input[type=submit] {
    height: 60px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-cart-btn.nostock {
    height: 60px;
  }
}

.s-item-detail_item-cart-btn-txt {
  display: inline-block;
  white-space: nowrap;
  min-height: 19px;
  font-size: 1rem;
  line-height: 19px;
  overflow: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  padding-left: 36px;
  pointer-events: none;

  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 19px;
    position: absolute;
    top: calc(50% - 9px);
    left: calc(50% + 4px - 4.8em);
    background-image: url("../img/ico_cart.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% auto;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_item-cart-btn-txt {
    font-size: calc((100vw - 376px) / 826 * 1 + 14px);
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_item-cart-btn-txt {
    font-size: calc(10px + 1.0666666667vw);
  }
}

.s-item-detail_item-trying {
  display: block;
  border: 1px solid #1F2C5C;
  border-radius: 6px;
  padding: 9px 0 6px 0;
  text-align: center;
  margin-bottom: 8px;
  font-size: .8rem;
  line-height: 1.2;
  cursor: pointer;
}

.s-item-detail_item-trying_inr {
  display: inline-block;
  overflow: hidden;
  padding-right: 19px;
  background-image: url("../img/ico_arrow_b.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 9px 8px;
}

.s-item-detail_item-accept {
  display: block;
  border: 1px solid #1F2C5C;
  border-radius: 6px;
  padding: 11px 0 4px 0;
  text-align: center;
  margin-bottom: 8px;
  font-size: .8rem;
  line-height: 1.2;
  cursor: pointer;
}

.s-item-detail_item-accept_inr {
  display: inline-block;
  overflow: hidden;
  padding-right: 19px;
  background-image: url("../img/ico_arrow_b.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 9px 8px;
}

.s-item-detail_item-detail-nav {
  margin-bottom: 25px;

  ul {
    display: table;
    table-layout: auto;
    width: 100%;
    position: relative;
    line-height: 1.2;
    text-align: center;

    &:after {
      position: absolute;
      bottom: -5px;
      left: 0;
      content: "";
      display: block;
      width: 100%;
      height: 1px;
      background-color: #BFBCB4;
      z-index: 0;
    }

    br {
      display: none;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-nav {
    margin-bottom: 19px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-nav ul br {
    display: inline;
  }
}

.s-item-detail_item-detail-nav--2col li {
  width: 50%;
}

.s-item-detail_item-detail-nav li {
  display: table-cell;
  position: relative;
  vertical-align: middle;
  color: #8E8C86;
  cursor: pointer;
  -webkit-transition: color 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  transition: color 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  font-size: .8666666667rem;
  padding: 0 .46875vw;

  &:after {
    position: absolute;
    bottom: -5px;
    left: 0;
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #1F2C5C;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transform-origin: right center;
    transform-origin: right center;
    -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    z-index: 1;
  }

  .inr {
    display: inline-block;
    padding: 0 0 10px 0;
    position: relative;
  }

  &.current {
    color: #1F2C5C;

    &:after {
      -webkit-transform: scaleX(1);
      transform: scaleX(1);
    }
  }

  &:hover:after {
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
    -webkit-transform-origin: left center;
    transform-origin: left center;
    -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_item-detail-nav li {
    font-size: calc((100vw - 376px) / 826 * 1 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-nav li {
    margin-right: 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_item-detail-nav li {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.3333333333;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-nav li .inr {
    display: block;
    padding: 0 0 9px 0;
  }
}

.s-item-detail_item-detail-block {
  display: none;

  p {
    margin-bottom: 2.1em;
  }

  &.disp {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-block p {
    line-height: 1.7142857143;
    margin-bottom: 1.5em;
  }
}

.s-item-detail_item-detail-outfit {
  margin-bottom: 150px;

  .c-ttl-large {
    text-align: center;
    margin-bottom: 83px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit {
    margin-bottom: 73px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit .c-ttl-large {
    margin-bottom: 42px;
  }
}

.s-item-detail_item-detail-outfit-inr {
  display: flex;
  flex-wrap: wrap;
  margin-right: 7.8125vw;

  figure {
    width: 50vw;
    margin-right: 7.8125vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit-inr {
    display: block;
    margin: 0 9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit-inr figure {
    width: auto;
    margin-right: 0;
    margin-bottom: 30px;
  }
}

.s-item-detail_item-detail-outfit-list {
  -webkit-flex: 1;
  flex: 1;

  li {
    margin: 0 0 20px 0;

    a {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }

    .fig {
      width: 7.8125vw;
      margin-right: 3.125vw;
    }

    .detail {
      -webkit-flex: 1;
      flex: 1;
      font-size: 1.0666666667rem;
      line-height: 1.25;
      margin-top: 16px;
    }

    .c-item-price {
      margin-top: 9px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit-list li {
    margin: 0 0 9px 0;
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit-list li .fig {
    width: 21.3333333333vw;
    margin-right: 6.4vw;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_item-detail-outfit-list li .detail {
    font-size: calc((100vw - 376px) / 826 * 4 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit-list li .detail {
    margin-top: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_item-detail-outfit-list li .detail {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.3333333333;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_item-detail-outfit-list li .c-item-price {
    margin-top: 6px;
  }
}

.s-item-detail_scrollcart {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #E3E0D9;
  padding: 20px 0;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  -webkit-transform-origin: center top;
  transform-origin: center top;
  -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  border-top: 1px solid #DCD8D0;
  overflow: hidden;

  &.disp {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
  }

  &.js-followcart-hide {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart {
    margin: 0;
    padding: 6px 9.6vw;
  }
}

.s-item-detail_scrollcart-inr {
  width: 90.625vw;
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  margin: 0 auto;

  .s-item-detail_item-buy-wrap {
    width: 251px;
    margin-left: 2.34375vw;
    margin-bottom: 0;
  }

  .s-item-detail_item-cart-btn {
    font-size: 1rem;
    margin-bottom: 0;
  }

  .s-item-detail_item-cart-btn-txt {
    padding-left: 33px;
  }

  .s-item-detail_item-cart-btn input[type="submit"], .nostock {
    height: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-inr {
    width: auto;
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-inr .s-item-detail_item-buy-wrap {
    width: auto;
    margin-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-inr .s-item-detail_item-cart-btn {
    position: relative;
    font-size: .8666666667rem;
    width: auto;
  }
}

.s-item-detail_scrollcart-detail {
  -webkit-flex: 1;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;

  .s-item-detail_item-name {
    margin: 6px 1.5625vw 6px 0;
    font-size: .9333333333rem;
    letter-spacing: normal;
  }

  .s-item-detail_item-price {
    margin: 6px 10px 6px 0;
    font-size: 1.0666666667rem;
    letter-spacing: normal;
    white-space: nowrap;

    .crcode {
      font-size: .6666666667rem;
      display: inline-block;
    }
  }

  .s-item-detail_select {
    width: 368px;
    margin-bottom: 0;

    .c-form-select-wrapper {
      font-size: .9333333333rem;
    }

    .c-form-select-trigger {
      padding: 16px 32px 16px 20px;
    }
  }

  .s-item-detail_select-field-append {
    width: 100%;
  }

  .s-item-detail_select-field {
    width: 100%;

    .item-sku {
      dt {
        display: none;
      }

      dd {
        width: 48.915%;
        margin-right: 2.17%;
        margin-bottom: 2px;

        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-detail {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-detail .s-item-detail_item-name {
    width: auto;
    margin-right: 0;
    font-size: .8666666667rem;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-detail .s-item-detail_item-price {
    font-size: .6666666667rem;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-detail .s-item-detail_item-price .crcode {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-detail .s-item-detail_select {
    width: 100%;
  }
}

.s-item-detail_scrollcart-detail-inr1 {
  -webkit-flex: 1;
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_scrollcart-detail-inr1 {
    display: none;
  }
}

.s-item-detail_cover {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 19000;
  pointer-events: none;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;

  &.disp {
    opacity: 1;
    pointer-events: auto;
  }
}

.s-item-detail_form-section {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 70.32%;
  margin-left: 29.68%;
  padding: 86px 3.515625vw 0 7.03125vw;
  z-index: 20000;
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
  transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);

  &.disp {
    -webkit-transform: translateX(0);
    transform: translateX(0);

    .s-item-detail_form-section-wrap {
      opacity: 1;
      height: 100%;
      height: 100%;
    }

    .s-item-detail_form-section-inr {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }
  }

  .close {
    width: 60px;
    height: 60px;
    cursor: pointer;
    position: absolute;
    top: 20px;
    right: 22px;

    &:before {
      content: "";
      display: block;
      width: 30px;
      height: 1px;
      background-color: #1F2C5C;
      position: absolute;
      top: 50%;
      left: 15px;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }

    &:after {
      content: "";
      display: block;
      width: 1px;
      height: 30px;
      background-color: #1F2C5C;
      position: absolute;
      top: 15px;
      left: 50%;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}

@media only screen and (max-width: 980px) {
  .s-item-detail_form-section {
    width: 94.67%;
    margin-left: 5.33%;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section {
    padding: 46px 0 0 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section .close {
    width: 30px;
    height: 30px;
    top: 14px;
    right: 10px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section .close:before {
    width: 100%;
    left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section .close:after {
    height: 100%;
    top: 0;
  }
}

.s-item-detail_form-section-wrap {
  opacity: 0;
  -webkit-transition: opacity 0.6s;
  transition: opacity 0.6s;
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

.s-item-detail_form-section-inr {
  height: 100%;
  padding: 0 3.515625vw 100px 0;
  -webkit-transform: translateY(30px);
  transform: translateY(30px);
  -webkit-transition: transform 0.6s;
  transition: transform 0.6s;
  -webkit-transition-delay: .6s;
  transition-delay: .6s;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-inr {
    height: calc(100vh - 60px);
    padding: 0 5.3333333333vw 60px 0;
  }
}

.s-item-detail_form-section-ttl {
  font-size: 1.4666666667rem;
  line-height: 1.5454545455;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-ttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 18px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-ttl {
    margin: 0 0 16px 5.3333333333vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-ttl {
    font-size: calc(10px + 2.1333333333vw);
    line-height: 1.8888888889;
  }
}

.s-item-detail_form-section-dtl {
  font-size: .8666666667rem;
  line-height: 1.6923076923;
  margin-bottom: 47px;

  p {
    margin-bottom: 1.8em;
  }

  ul {
    font-size: .8rem;
    line-height: 1.6666666667;
  }

  li {
    text-indent: -1em;
    margin-left: 1em;

    &:before {
      content: "・";
      margin-right: .5em;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-dtl {
    font-size: calc((100vw - 376px) / 826 * 1 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-dtl {
    margin: 0 0 37px 5.3333333333vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-dtl {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.6666666667;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-dtl p {
    margin-bottom: 1.4em;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-dtl ul {
    font-size: calc((100vw - 376px) / 826 * 1 + 11px);
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-dtl ul {
    font-size: calc(10px + .2666666667vw);
    line-height: 1.6363636364;
  }
}

.s-item-detail_form-section-selectitem {
  margin-bottom: 48px;
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  padding: 20px 3.125vw 17px 3.125vw;
  border: 1px solid #BFBCB4;
  background-color: #DCD8D0;
  font-size: .8rem;
  border-radius: 6px;

  figure {
    width: 6.25vw;
    margin-right: 2.65625vw;
  }

  .dtl {
    -webkit-flex: 1;
    flex: 1;
    margin-top: 2px;
  }

  .itemname {
    font-size: 1.0666666667rem;
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-selectitem {
    font-size: calc((100vw - 376px) / 826 * 1 + 11px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-selectitem {
    margin: 0 -1.3333333333vw 39px 4vw;
    padding: 5.3333333333vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-selectitem {
    font-size: calc(10px + .2666666667vw);
    line-height: 1.5;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-selectitem figure {
    width: 18.6666666667vw;
    margin-right: 5.3333333333vw;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-selectitem .itemname {
    font-size: calc((100vw - 376px) / 826 * 1 + 15px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-selectitem .itemname {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-selectitem .itemname {
    font-size: calc(10px + 1.3333333333vw);
    line-height: 1.2;
    letter-spacing: normal;
  }
}

.s-item-detail_form-section-tel {
  border-bottom: 1px solid #BFBCB4;
  padding-bottom: 22px;
  margin-bottom: 45px;
  font-size: .9333333333rem;
  line-height: 1.2857142857;

  a {
    border-bottom: 1px solid #1F2C5C;
  }

  .s-item-detail_form-section-sbttl {
    margin-bottom: 36px;
  }

  .telnum {
    display: inline-block;
    margin: 9px 0 0 1em;
    font-size: .8666666667rem;
    line-height: 1;
    letter-spacing: .02em;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
  }

  li {
    letter-spacing: normal;
    text-indent: -1em;
    margin-left: calc(1em + 2.81vw);
    margin-bottom: 27px;
    width: calc(50% - 1em - 2.81vw);
    padding-right: 10px;

    &:nth-of-type(2n) {
      margin-left: 1em;
      width: calc(50% - 1em);
    }

    &:before {
      content: "・";
      margin-right: .5em;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-tel {
    font-size: calc((100vw - 376px) / 826 * 1 + 13px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-tel {
    padding: 0 1.3333333333vw 10px 1.3333333333vw;
    margin: 0 -1.3333333333vw 30px 4vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-tel {
    font-size: calc(10px + .8vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-tel .s-item-detail_form-section-sbttl {
    margin-bottom: 21px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-tel li {
    width: calc(50% - 1em - 1vw);
    margin-left: calc(1em + 1vw);
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-tel li:nth-of-type(2n) {
    width: 50%;
    margin-left: 0;
    padding: 0 0 0 2em;
  }
}

.s-item-detail_form-section-sbttl {
  font-size: 1.0666666667rem;
  margin-bottom: 38px;

  img {
    width: 20px;
    margin-right: 20px;
    vertical-align: -3px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-sbttl {
    font-size: calc((100vw - 376px) / 826 * 1 + 15px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-sbttl {
    margin-bottom: 19px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-sbttl {
    font-size: calc(10px + 1.3333333333vw);
    line-height: 2.1333333333;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-sbttl img {
    width: 14px;
    margin-right: 14px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-att {
    display: none;
  }
}

.s-item-detail_form-section-form {
  display: flex;
  flex-wrap: wrap;

  .s-item-detail_form-section-sbttl img {
    width: 18px;
    vertical-align: -2px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-form {
    display: block;
    margin: 0 -1.3333333333vw 0 4vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-form .s-item-detail_form-section-sbttl {
    margin-left: 1.3333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-form .s-item-detail_form-section-sbttl img {
    width: 16px;
    vertical-align: 0;
  }
}

.s-item-detail_form-section-form-dtl {
  width: 21.875vw;
  margin-right: 6.25vw;

  p {
    font-size: .8666666667rem;
    line-height: 1.6923076923;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-form-dtl {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-form-dtl p {
    font-size: calc((100vw - 376px) / 826 * 1 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-form-dtl p {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.6666666667;
  }
}

.s-item-detail_form-section-form-field {
  -webkit-flex: 1;
  flex: 1;
  margin-top: 4px;
}

.s-item-detail_form-section-form-field-inr {
  input {
    &[type=text], &[type=password], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
      background-color: #DCD8D0;
      border-radius: 6px;
      padding: 14px 26px;
      font-size: 1rem;
      outline: 0;
      border: 1px solid #DCD8D0;
    }
  }

  button, textarea, select {
    background-color: #DCD8D0;
    border-radius: 6px;
    padding: 14px 26px;
    font-size: 1rem;
    outline: 0;
    border: 1px solid #DCD8D0;
  }

  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #8E8C86;
  }

  input:-moz-placeholder, textarea:-moz-placeholder, input::-moz-placeholder, textarea::-moz-placeholder {
    color: #8E8C86;
    opacity: 1;
  }

  input:-ms-input-placeholder {
    color: #8E8C86 !important;
  }

  textarea {
    &:-ms-input-placeholder {
      color: #8E8C86 !important;
    }

    height: 92px;
  }

  input {
    &[type=text]:focus, &[type=password]:focus, &[type=email]:focus {
      border: 1px solid #1F2C5C;
    }
  }

  textarea:focus {
    border: 1px solid #1F2C5C;
  }

  .field {
    margin-bottom: 5px;

    .field {
      input {
        &[type=text], &[type=password], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
          border-color: #DCD8D0;
        }
      }

      button, textarea, select {
        border-color: #DCD8D0;
      }
    }

    .form_err {
      input, textarea {
        border-color: #F08300;
      }

      .c-form-select-trigger {
        border: 1px solid #F08300;
      }
    }

    .wpcf7-not-valid-tip {
      color: #F08300;
      font-size: .8rem;
      line-height: 1.2;
      font-weight: bold;
      margin: 12px 0 6px 0;
      text-align: right;
    }

    .c-form-select-wrapper {
      font-weight: 400;
    }

    .c-form-select-trigger {
      background-color: #DCD8D0;
      border: none;
      padding: 15px 45px 15px 26px;

      &:after {
        width: 8px;
        height: 8px;
        top: calc(50% - 4px);
        right: 26px;
      }
    }
  }

  .bottom-submit {
    margin-top: 19px;

    input[type=submit] {
      padding: 18px 0;
      background-color: #F08300;
      border-radius: 6px;
      font-weight: bold;
      cursor: pointer;
    }
  }

  .bottom-att {
    font-size: .6666666667rem;
    text-align: center;
    margin-top: 7px;

    a {
      text-decoration: underline;
    }
  }

  .bottom-att2 {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-form-field-inr {
    input {
      &[type=text], &[type=password], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
        padding: 14px 20px;
        font-size: .9333333333rem;
      }
    }

    button, textarea, select {
      padding: 14px 20px;
      font-size: .9333333333rem;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-form-field-inr textarea {
    height: 88px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_form-section-form-field-inr .field .wpcf7-not-valid-tip {
    font-size: calc((100vw - 376px) / 826 * 2 + 10px);
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-form-field-inr .field .wpcf7-not-valid-tip {
    font-size: calc(10px + 0vw);
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_form-section-form-field-inr .bottom-att {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_form-section-form-field-inr .bottom-att2 {
    display: block;
    font-size: .8rem;
    margin-top: 32px;
  }
}

.s-item-detail_sizeguide {
  background-color: #000;
  padding-left: 5.3333333333vw;

  figure img {
    width: 100%;
  }
}

.s-item-detail_sizeguide-ttl {
  font-size: 1.0666666667rem;
  margin-bottom: 12px;
  margin-top: 0px;
}

@media only screen and (max-width: 1200px) {
  .s-item-detail_sizeguide-ttl {
    font-size: calc((100vw - 376px) / 826 * 1 + 15px);
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_sizeguide-ttl {
    margin-bottom: 5px;
    margin-top: 18px;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_sizeguide-ttl {
    font-size: calc(10px + 1.3333333333vw);
  }
}

.s-item-detail_sizeguide-table table {
  width: 100%;
  font-size: .7333333333rem;
  border-collapse: collapse;
  table-layout: fixed;
  text-align: center;

  thead th {
    background-color: #BFBCB4;
    padding: 7px 6px 7px 6px;
  }

  tbody th {
    background-color: #DCD8D0;
  }

  tr {
    border-collapse: collapse;
    border: 1px solid #BFBCB4;
    border-left: none;
    border-right: none;
  }

  th {
    padding: 6px 6px 7px 6px;
    font-weight: bold;
  }

  td {
    padding: 8px 6px 8px 6px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_sizeguide-table table {
    font-size: .6666666667rem;
    //width: calc(100% - ((20 / $sp-design-width) * 100vw));
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_sizeguide-table table thead th {
    padding: 1px 4px 1px 4px;

    .inr {
      display: inline-block;
      font-size: .6666666667rem;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      line-height: 1.25;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_sizeguide-table table th {
    padding: 7px;
  }
}

@media only screen and (max-width: 750px) {
  .s-item-detail_sizeguide-table table td {
    padding: 7px;
  }
}

.s-item-detail_sizeguide-unit {
  font-size: .6666666667rem;
  text-align: right;
  margin: 0 0 3px 0;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_sizeguide-unit {
    margin: 0 0 2px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-item-detail_sizeguide-unit {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}

.s-item-detail_sizeguide-att {
  font-size: .8rem;
  margin: 17px 0 27px 0;
}

@media only screen and (max-width: 750px) {
  .s-item-detail_sizeguide-att {
    margin: 22px 0 40px 0;
    font-size: .7333333333rem;
    line-height: 1.2727272727;
  }
}

.item_size_tanni {
  font-size: .6666666667rem;
  text-align: right;
  margin: 17px 0 27px 0;
}

@media only screen and (max-width: 750px) {
  .item_size_tanni {
    margin: 0 0 2px 0;
  }
}

@media only screen and (max-width: 375px) {
  .item_size_tanni {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
  }
}

.item_size_txt {
  font-size: .8rem;
  margin: 17px 0 27px 0;
}

@media only screen and (max-width: 750px) {
  .item_size_txt {
    margin: 22px 0 40px 0;
    font-size: .7333333333rem;
    line-height: 1.2727272727;
  }
}

.js-history-sticky {
  display: none;
  position: fixed;
  top: 120px;
  left: 10.15625vw;
  z-index: 1000;
  transition: opacity 0.13s;
  font-size: 6rem;
  line-height: .7555555556;
}

@media only screen and (max-width: 1200px) {
  .js-history-sticky {
    font-size: calc((100vw - 376px) / 826 * 40 + 50px);
  }
}

@media only screen and (max-width: 980px) {
  .js-history-sticky {
    display: none !important;
  }
}

.js-history-sticky--fix .js-history-sticky {
  display: block;
}

.js-history-sticky--hidden .js-history-sticky {
  opacity: 0;
}

.js-history-sticky--abs {
  display: block;
}

@media only screen and (max-width: 980px) {
  .js-history-sticky--abs {
    display: block !important;
  }
}

.js-history-sticky--fix .js-history-sticky--abs {
  display: none;
}

.p-history-archive {
  .c-cover {
    top: calc(100vh - 56px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 40px);
  }

  .s-top_main-visual {
    height: calc(100vh - 40px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 9.3333333333rem;
    margin-bottom: 55px;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: .9333333333rem;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 90px);
    left: auto;
    right: 9.53125vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-contents-intro {
    margin-bottom: 190px;
  }

  .l-contents-intro_ttl {
    margin-bottom: 73px;
  }

  .l-contents-intro_dtl {
    font-size: 1.3333333333rem;
    line-height: 2.4;
    margin-bottom: 54px;
    position: relative;
    z-index: 1;

    &:before {
      content: "";
      display: block;
      width: 67px;
      height: 52px;
      position: absolute;
      top: -.8em;
      left: 0;
      background-image: url("../img/ico_bq.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: left top;
      z-index: -1;
    }

    &:after {
      content: "";
      display: block;
      width: 67px;
      height: 52px;
      position: absolute;
      bottom: -.8em;
      right: 2.2em;
      background-image: url("../img/ico_bq.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: left top;
      z-index: -1;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
    }
  }

  .l-contents-intro_btm-dtl {
    font-size: 1.2rem;
    line-height: 1.3333333333;
    text-align: center;

    small {
      font-size: .7333333333rem;
      line-height: 1.4545454545;
      display: block;
      margin-bottom: 15px;
    }
  }

  .l-contents-care {
    padding-bottom: 250px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .c-cover {
    top: calc(81.28vh - 11px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .p-history-archive .c-cover {
    top: 420px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .s-top_main-contents-mainghost {
    height: 81.28vh;
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .p-history-archive .s-top_main-contents-mainghost {
    height: 430px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .s-top_main-visual {
    height: 81.28vh;
    min-height: 430px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-archive .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 64 + 76px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .s-top_main-visual-ttl {
    margin-bottom: 26px;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-archive .s-top_main-visual-ttl {
    font-size: calc(10px + 17.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-archive .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .p-history-archive .s-top_main-visual-dtl {
    font-size: calc(10px + .5333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(81.28vh - 50px);
    right: 9.8666666667vw;
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .p-history-archive .s-top_main-visual-circle {
    top: 390px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro {
    margin-bottom: 146px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro_ttl {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro_fig-block2 {
    position: relative;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro_block {
    text-align: center;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-archive .l-contents-intro_dtl {
    font-size: calc((100vw - 376px) / 826 * 4 + 16px);
    font-size: calc((100vw - 376px) / 824 * 6 + 14px);
  }
}

@media only screen and (max-width: 860px) {
  .p-history-archive .l-contents-intro_dtl {
    margin-left: -10px;
    margin-right: -10px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro_dtl {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 44px;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-archive .l-contents-intro_dtl {
    font-size: calc(10px + 1.6vw);
    line-height: 2.5;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro_dtl:before {
    content: "";
    display: block;
    width: 60px;
    height: 46px;
    top: -1.1em;
    left: 1.5em;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro_dtl:after {
    content: "";
    display: block;
    width: 60px;
    height: 46px;
    right: 3.2em;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-archive .l-contents-intro_btm-dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 16px);
  }
}

@media only screen and (max-width: 375px) {
  .p-history-archive .l-contents-intro_btm-dtl {
    font-size: calc(10px + 1.6vw);
    line-height: 1.5;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-archive .l-contents-intro_btm-dtl small {
    font-size: calc((100vw - 376px) / 826 * 1 + 10px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-intro_btm-dtl small {
    margin-bottom: 9px;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-archive .l-contents-intro_btm-dtl small {
    font-size: calc(10px + 0vw);
    line-height: 1.4;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-archive .l-contents-care {
    padding-bottom: 121px;
  }
}

.s-history_main-ttl {
  position: absolute;
  top: calc(50% + 6.1vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .s-history_main-ttl {
    top: calc(50% + 3.5vh);
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-history_main-ttl {
    top: 50%;
  }
}

.s-history_archive {
  margin: 0 0 212px 0;
}

@media only screen and (max-width: 750px) {
  .s-history_archive {
    width: auto;
    margin: 0 0 -41px 0;
  }
}

.s-history_archive-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 13.28125vw 0 11.91953125vw;
  margin-bottom: 164px;

  &:nth-of-type(2n) {
    flex-direction: row-reverse;
    padding: 0 11.91953125vw 0 14.375vw;

    .fig {
      margin-right: 23px;

      figure {
        margin-left: auto;

        &:nth-of-type(1) {
          -webkit-transform: rotate(2deg);
          transform: rotate(2deg);
        }

        &:nth-of-type(2) {
          -webkit-transform: translate(-13.51%, 0) rotate(-1deg);
          transform: translate(-13.51%, 0) rotate(-1deg);
          margin-top: -18.046875vw;
          position: relative;
        }
      }
    }

    .circle {
      right: 0;
      left: auto;
      -webkit-transform: translate(50%, 50%);
      transform: translate(50%, 50%);
    }

    .s-history_archive-ttl {
      text-align: left;

      .type {
        margin-left: 2.3em;
      }

      .num {
        padding-left: .27em;

        &:before {
          left: 0;
        }
      }
    }
  }

  .fig {
    -webkit-flex: 1;
    flex: 1;
    margin: 16px 0 0 0;

    figure {
      width: 28.90625vw;
      height: 38.28125vw;
      -webkit-transform-origin: center center;
      transform-origin: center center;

      &:nth-of-type(1) {
        -webkit-transform: translateY(0.6%) rotate(-2deg);
        transform: translateY(0.6%) rotate(-2deg);
      }

      &:nth-of-type(2) {
        -webkit-transform: translate(23.62%, 0) rotate(1deg);
        transform: translate(23.62%, 0) rotate(1deg);
        margin-top: -18.046875vw;
        position: relative;
      }
    }
  }

  .dtl {
    width: 29.6875vw;
  }

  p {
    margin-bottom: 32px;
    font-size: 1.0666666667rem;
    line-height: 2;

    a {
      overflow: hidden;
      display: inline-block;
      line-height: 1;

      .c-hov {
        padding: 0;
        line-height: 1.6;

        &:after {
          padding: 0;
        }
      }
    }
  }

  .circle {
    display: block;
    width: 70px;
    height: 70px;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transform: translate(-50%, 50%);
    transform: translate(-50%, 50%);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block {
    display: block;
    width: auto;
    margin-bottom: 104px;
    padding: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block:nth-of-type(2n) {
    padding: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block:nth-of-type(2n) .fig {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block:nth-of-type(2n) .fig figure:nth-of-type(1) {
    margin-left: auto;
    margin-right: 11.224vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block:nth-of-type(2n) .fig figure:nth-of-type(2) {
    margin-top: -39.2%;
    margin-left: 10.330666666700001vw;
    -webkit-transform: translate(0, 0) rotate(-1deg);
    transform: translate(0, 0) rotate(-1deg);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block:nth-of-type(2n) .s-history_archive-ttl .type {
    margin-left: 7.4666666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block .fig {
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block .fig figure {
    width: 63.592vw;
    height: 84.216vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block .fig figure:nth-of-type(1) {
    margin-left: 11.0506666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block .fig figure:nth-of-type(2) {
    margin-top: -39.2%;
    -webkit-transform: translate(0, 0) rotate(1deg);
    transform: translate(0, 0) rotate(1deg);
    margin-left: auto;
    margin-right: 10.330666666700001vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block .dtl {
    width: auto;
    margin: 0 9.6vw;
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_archive-block p {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 375px) {
  .s-history_archive-block p {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.8571428571;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-block .circle {
    width: 50px;
    height: 50px;
  }
}

.s-history_archive-btn {
  max-width: 228px;

  a {
    height: 82px;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-btn {
    max-width: 205px;
  }
}

.s-history_archive-ttl {
  margin-bottom: 27px;
  text-align: right;

  .type {
    display: block;
    font-size: 1.2rem;
  }

  .num {
    display: inline-block;
    position: relative;
    font-size: 8.6666666667rem;
    padding-left: .3em;
    line-height: .7692307692;
    margin-bottom: 26px;

    &:before {
      content: "# ";
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 6px;
      font-size: 4.6666666667rem;
    }
  }

  .txt {
    text-align: left;
    display: block;
    font-size: 1.7333333333rem;
    line-height: 1.3846153846;
    position: relative;
    z-index: 1;
    text-indent: 78px;

    &:before {
      content: "";
      display: block;
      width: 53px;
      height: 41px;
      position: absolute;
      top: -.7em;
      left: 4px;
      background-image: url("../img/ico_bq.svg");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      background-position: left top;
      z-index: -1;
    }
  }

  .sub-txt {
    display: block;
    font-size: 1.3333333333rem;
    line-height: 1.5;
    margin-bottom: 85px;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-ttl {
    text-align: left;
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_archive-ttl .type {
    font-size: calc((100vw - 376px) / 826 * 2 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-ttl .type {
    margin-left: 7.4666666667vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_archive-ttl .type {
    font-size: calc(10px + 1.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_archive-ttl .num {
    font-size: calc((100vw - 376px) / 826 * 30 + 100px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-ttl .num {
    margin-bottom: 20px;
    padding-left: .26em;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_archive-ttl .num {
    font-size: calc(10px + 24vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_archive-ttl .num:before {
    font-size: calc((100vw - 376px) / 826 * 20 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-ttl .num:before {
    left: 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_archive-ttl .num:before {
    font-size: calc(10px + 10.6666666667vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_archive-ttl .txt {
    font-size: calc((100vw - 376px) / 826 * 6 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-ttl .txt {
    text-indent: 60px;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_archive-ttl .txt {
    font-size: calc(10px + 2.6666666667vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-ttl .txt:before {
    content: "";
    display: block;
    width: 33px;
    height: 25px;
    top: -.2em;
    left: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_archive-ttl .sub-txt {
    font-size: calc((100vw - 376px) / 826 * 4 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_archive-ttl .sub-txt {
    margin-bottom: 49px;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_archive-ttl .sub-txt {
    font-size: calc(10px + 1.6vw);
  }
}

.s-history_histories {
  padding: 18.90625vw 0 170px 10.15625vw;
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 27.109375vw;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../img/bg_stech.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: 100% auto;
    z-index: 1;
  }
}

@media only screen and (max-width: 980px) {
  .s-history_histories {
    padding-left: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_histories {
    padding: 37.0666666667vw 0 100px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_histories:before {
    content: "";
    display: block;
    width: 100%;
    height: 51.7333333333vw;
    background-position: 38% 31%;
    background-size: 270% auto;
  }
}

.s-history_histories-inr {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
}

@media only screen and (max-width: 980px) {
  .s-history_histories-inr {
    width: auto;
    display: block;
  }
}

.s-history_histories-ttl {
  width: 36.71875vw;
  padding-right: 20px;
  margin-top: -5px;

  .c-ttl-large {
    font-size: 6rem;
    line-height: .7555555556;
  }
}

@media only screen and (max-width: 980px) {
  .s-history_histories-ttl {
    width: auto;
    padding-right: 0;
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_histories-ttl .c-ttl-large {
    font-size: calc((100vw - 376px) / 826 * 40 + 50px);
  }
}

@media only screen and (max-width: 980px) {
  .s-history_histories-ttl .c-ttl-large {
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_histories-ttl .c-ttl-large {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .8;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_histories-ttl .c-ttl-large .min {
    margin-top: 14px;
  }
}

.s-history_histories-dtl {
  -webkit-flex: 1;
  flex: 1;

  .l-contents-large-group_ttl {
    font-size: 6rem;
  }

  .l-contents-large-group {
    padding-bottom: 0;
    margin-bottom: 1px;
  }

  .l-contents-large-group_btn {
    position: static;
    margin: 35px 0 0 0;

    .c-btn-small {
      margin: 0 auto;
    }
  }

  .l-contents-large-group_inr {
    padding: 130px 0 87px 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_histories-dtl .l-contents-large-group_ttl {
    font-size: calc((100vw - 376px) / 826 * 30 + 60px);
  }
}

@media only screen and (max-width: 375px) {
  .s-history_histories-dtl .l-contents-large-group_ttl {
    font-size: calc(10px + 13.3333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_histories-dtl .l-contents-large-group_inr {
    padding: 85px 0 78px 0;
  }
}

.s-history_collaboration {
  margin-bottom: 170px;

  .c-ttl-large {
    font-size: 6rem;
    line-height: .7555555556;
    margin-bottom: 75px;
    text-align: center;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration {
    margin-bottom: 100px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_collaboration .c-ttl-large {
    font-size: calc((100vw - 376px) / 826 * 40 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration .c-ttl-large {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_collaboration .c-ttl-large {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .8;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration .c-ttl-large .min {
    margin-top: 19px;
  }
}

.s-history_collaboration-inr {
  display: flex;
  flex-wrap: wrap;
  margin-right: 11.71875vw;
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-inr {
    display: block;
    margin: 0;
  }
}

.s-history_collaboration-fig {
  width: 46.875vw;
  margin-right: 12.5vw;

  img {
    width: 100%;
  }

  figure {
    &:nth-of-type(1) {
      position: relative;

      &:after {
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: rgba(0, 0, 0, 0.4);
      }
    }

    &:nth-of-type(2) {
      width: 33.59375vw;
      margin-top: -20.703125vw;
      margin-left: auto;
      -webkit-transform: translateX(3.125vw);
      transform: translateX(3.125vw);
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-fig {
    width: auto;
    margin: 0 0 29px 0;
    padding: 0 0 0 9.8666666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-fig figure:nth-of-type(1) {
    margin-left: 5.3333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-fig figure:nth-of-type(2) {
    width: 60.8vw;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    margin-left: 0;
    margin-top: -37.6vw;
  }
}

.s-history_collaboration-dtl {
  -webkit-flex: 1;
  flex: 1;
  margin-top: 7.421875vw;

  p {
    font-size: 1.0666666667rem;
    line-height: 2;
    margin-bottom: 42px;
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-dtl {
    margin: 0 9.6vw;
  }
}

@media only screen and (max-width: 1200px) {
  .s-history_collaboration-dtl p {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-dtl p {
    margin-bottom: 34px;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_collaboration-dtl p {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.8571428571;
  }
}

.s-history_collaboration-dtl-ttl {
  font-size: 5.3333333333rem;
  line-height: 1.25;
  -webkit-flex: 1;
  flex: 1;
  margin-bottom: 41px;
}

@media only screen and (max-width: 1200px) {
  .s-history_collaboration-dtl-ttl {
    font-size: calc((100vw - 376px) / 824 * 50 + 30px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-dtl-ttl {
    margin-bottom: 22px;
    font-size: calc((100vw - 376px) / 824 * 40 + 40px);
  }
}

@media only screen and (max-width: 375px) {
  .s-history_collaboration-dtl-ttl {
    font-size: calc(10px + 8vw);
  }
}

.s-history_collaboration-dtl-sbttl {
  font-size: 1.6rem;
  line-height: 1.5833333333;
  -webkit-flex: 1;
  flex: 1;
  margin-bottom: 28px;
}

@media only screen and (max-width: 1200px) {
  .s-history_collaboration-dtl-sbttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-history_collaboration-dtl-sbttl {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 375px) {
  .s-history_collaboration-dtl-sbttl {
    font-size: calc(10px + 2.6666666667vw);
    line-height: 1.9;
  }
}

.p-history-detail {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .l-main-type1 .secondary {
    font-size: 2.9333333333rem;
    line-height: 1.3636363636;
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 9.3333333333rem;
    margin-bottom: 60px;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: .9333333333rem;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-detail .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-history-detail .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-detail .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-history-detail .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-detail .l-main-type1 .secondary {
    font-size: calc((100vw - 376px) / 826 * 20 + 24px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-detail .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-detail .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-history-detail .s-top_main-visual-circle {
    top: 350px;
  }
}

.p-history-product {
  .c-cover {
    top: calc(100vh - 56px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 40px);
  }

  .s-top_main-visual {
    height: calc(100vh - 40px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 9.3333333333rem;
    margin-bottom: -15px;
    line-height: 1.1;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.4615384615;
    letter-spacing: .02em;
    font-size: 1.7333333333rem;
    margin-top: 18px;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 90px);
    left: auto;
    right: 9.53125vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-article-other {
    padding-top: 122px;

    p {
      margin-bottom: 129px;
    }
  }

  .l-article_ttl {
    font-size: 2.1333333333rem;
    line-height: 1.375;
    margin-bottom: 44px;
  }

  .l-content-description {
    margin-top: 160px;
  }

  .l-content-description_detail {
    margin-top: 12px;
  }

  .l-content-description_block figure {
    height: 62.5vw;
  }

  .l-article-next {
    a {
      padding-right: 15.625vw;

      &:after {
        top: calc(50% - 6px);
      }

      .l-article-next-inr {
        font-size: 6rem;
        line-height: 1.1111111111;
        margin-top: 0;
      }
    }

    p {
      width: 100%;
      font-size: 1.2rem;
      margin-top: 6px;
    }
  }

  .sub.large {
    letter-spacing: -.03em;
    margin-bottom: 13px;
  }

  .l-article_movie {
    width: 100%;
    height: 56.25vw;
    margin-bottom: 113px;
    position: relative;
    cursor: pointer;

    &:after {
      content: "";
      display: block;
      width: 9.0625vw;
      height: 9.0625vw;
      position: absolute;
      top: 50%;
      left: 50%;
      background-image: url("../img/ico_play.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 100% auto;
      -webkit-transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
      transition: transform 0.4s cubic-bezier(0.6, 0.25, 0, 1);
      -webkit-transform: translate(-50%, -50%) scale(1);
      transform: translate(-50%, -50%) scale(1);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      z-index: 2;
      pointer-events: none;
    }

    &:before {
      content: "PLAY MOVIE";
      width: 100%;
      font-size: .7333333333rem;
      position: absolute;
      top: calc(50% + 4.53vw + 16px);
      left: 0;
      color: #E3E0D9;
      text-align: center;
      z-index: 2;
      font-family: aktiv-grotesk-extended, sans-serif;
      font-weight: 700;
      font-style: normal;
      z-index: 2;
      pointer-events: none;
    }

    &.play {
      &:after, &:before {
        opacity: 0;
      }
    }
  }

  .l-article_movie-thumb {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 56.25vw;
    z-index: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.4);
      -webkit-transition: background-color 0.6s;
      transition: background-color 0.6s;
    }
  }

  .l-article_movie {
    &:hover {
      &:after {
        -webkit-transform: translate(-50%, -50%) scale(1.06);
        transform: translate(-50%, -50%) scale(1.06);
      }

      .l-article_movie-thumb:after {
        background-color: rgba(0, 0, 0, 0.6);
      }
    }

    figure {
      position: relative;
      padding-top: 56.25%;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  .l-article_large-figure {
    margin-bottom: 113px;
  }

  .l-article_img2col {
    h2 {
      margin-bottom: 28px;
    }

    p {
      margin-bottom: 0;
      font-size: 1rem;
      line-height: 1.8666666667;
    }
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .c-cover {
    top: calc(81.28vh - 11px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .p-history-product .c-cover {
    top: 420px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .s-top_main-contents-mainghost {
    height: 81.28vh;
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .p-history-product .s-top_main-contents-mainghost {
    height: 430px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .s-top_main-visual {
    height: 81.28vh;
    min-height: 430px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-product .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 64 + 76px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .s-top_main-visual-ttl {
    margin-bottom: 3px;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .s-top_main-visual-ttl {
    font-size: calc(10px + 17.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-product .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 6 + 20px);
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .s-top_main-visual-dtl {
    font-size: calc(10px + 2.6666666667vw);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(81.28vh - 50px);
    right: 9.8666666667vw;
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .p-history-product .s-top_main-visual-circle {
    top: 390px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article-other {
    padding-top: 102px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article-other p {
    margin-bottom: 90px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-product .l-article_ttl {
    font-size: calc((100vw - 376px) / 826 * 8 + 24px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_ttl {
    width: auto;
    margin: 0 0 24px 0;
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .l-article_ttl {
    font-size: calc(10px + 3.7333333333vw);
    line-height: 1.5;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-content-description {
    margin-top: -8px;
    margin-top: 90px;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .l-content-description p {
    line-height: 1.8571428571;
  }
}

@media only screen and (max-width: 750px) and (max-width: 1200px) {
  .p-history-product .l-content-description_detail-ttl {
    font-size: calc((100vw - 376px) / 826 * 8 + 20px);
  }
}

@media only screen and (max-width: 750px) and (max-width: 750px) {
  .p-history-product .l-content-description_detail-ttl {
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 750px) and (max-width: 375px) {
  .p-history-product .l-content-description_detail-ttl {
    font-size: calc(10px + 2.6666666667vw);
    line-height: 1.5;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-content-description_detail-ttl .num {
    margin-bottom: 9px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-content-description_block figure {
    height: 110.9333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article-next a {
    padding-right: 20.8vw;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-product .l-article-next a .l-article-next-inr {
    font-size: calc((100vw - 376px) / 826 * 40 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article-next a .l-article-next-inr {
    margin: 0;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .l-article-next a .l-article-next-inr {
    font-size: calc(10px + 10.6666666667vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-product .l-article-next p {
    font-size: calc((100vw - 376px) / 826 * 6 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .l-article-next p {
    font-size: calc(10px + .5333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .sub.large {
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_movie {
    width: calc(100% + 19.2vw);
    height: 88vw;
    margin: 0 -9.6vw 42px -9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_movie:after {
    width: 24vw;
    height: 24vw;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_movie:before {
    font-size: .6666666667rem;
    top: calc(50% + 12vw + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_movie-thumb {
    height: 88vw;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_movie figure {
    padding-top: 88%;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-product .l-article_img2col .ttl {
    font-size: calc((100vw - 376px) / 826 * 8 + 20px);
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .l-article_img2col .ttl {
    font-size: calc(10px + 2.6666666667vw);
    line-height: 1.5;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_large-figure {
    margin-bottom: 43px;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_img2col h2 {
    margin-bottom: 25px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-history-product .l-article_img2col p {
    font-size: calc((100vw - 376px) / 826 * 1 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_img2col p {
    margin-bottom: 0 !important;
  }
}

@media only screen and (max-width: 375px) {
  .p-history-product .l-article_img2col p {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.76;
  }
}

@media only screen and (max-width: 750px) {
  .p-history-product .l-article_img2col figure {
    margin-bottom: 30px;
  }
}

.l-slider_progress {
  background-color: #BFBCB4;
  position: absolute;
  top: 0;
  right: 0;
  height: 1px;
}

@media only screen and (max-width: 750px) {
  .l-slider_progress {
    display: none;
  }
}

.l-slider_progress-bar {
  background-color: #1F2C5C;
  width: 100%;
  height: 1px;
  transition: transform 0.5s cubic-bezier(0.55, 0.01, 0.45, 1);
  -webkit-transform-origin: left top;
  transform-origin: left top;
}

.s-product_mainsubttl {
  color: #E3E0D9;
  font-size: 1.6rem;
  line-height: .8333333333;
  letter-spacing: -.04em;
}

@media only screen and (max-width: 1200px) {
  .s-product_mainsubttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-product_mainsubttl {
    margin-bottom: 9px;
  }
}

@media only screen and (max-width: 375px) {
  .s-product_mainsubttl {
    font-size: calc(10px + 2.6666666667vw);
    line-height: .9;
  }
}

.s-product_main-ttl {
  position: absolute;
  top: calc(50% + 1.22vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .s-product_main-ttl {
    top: calc(50% - .4vh);
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-product_main-ttl {
    top: 50%;
  }
}

.s-product_ttl {
  text-align: center;
  margin-bottom: 80px;
}

@media only screen and (max-width: 750px) {
  .s-product_ttl {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_ttl .c-ttl-max .min {
    margin-top: 17px;
  }
}

.s-product_slider {
  margin: -9px 0 130px 9.375vw;
  position: relative;

  p {
    width: auto;
    margin: 0 9.375vw 0 35.15625vw;
  }

  .l-slider_progress {
    width: calc(100% - 244px - 20.78vw);
    top: 33px;
    right: 20.78vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_slider {
    margin: -31px -9.6vw 70px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_slider p {
    margin: 0 9.6vw 0 0;
  }
}

@media only screen and (min-width: 1281px) {
  .s-product_slider .l-slider_progress {
    width: calc(100% - 244px - 17.8vw - 60px);
    right: calc(60px + 17.8vw);
  }
}

.s-product_slider-ttl {
  width: 244px;
  font-size: 4rem;
  line-height: 1.1333333333;
  margin-bottom: 60px;

  .min {
    display: block;
    font-size: .6666666667rem;
    margin-top: 12px;
    letter-spacing: .02em;
  }
}

@media only screen and (max-width: 1200px) {
  .s-product_slider-ttl {
    font-size: calc((100vw - 376px) / 826 * 20 + 40px);
  }
}

@media only screen and (max-width: 750px) {
  .s-product_slider-ttl {
    margin: 0 9.6vw 42px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-product_slider-ttl {
    font-size: calc(10px + 8vw);
    line-height: 1;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_slider-ttl .min {
    margin-top: 14px;
  }
}

.s-product_slider-inr {
  width: 100%;
  padding-bottom: 66px;

  .slick-nav_arrow {
    bottom: auto;
    top: calc(-4.68vw + -90px);
  }

  .slick-nav_prev {
    right: 12.1875vw;
  }

  .slick-nav_next {
    right: 7.34375vw;
  }

  li .caption {
    display: block;
    margin-top: 16px;
    font-size: .9333333333rem;
  }

  &.js-cardmotion-active:hover img {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@media only screen and (max-width: 750px) {
  .s-product_slider-inr {
    padding-bottom: 37px;
  }
}

@media only screen and (min-width: 1281px) {
  .s-product_slider-inr .slick-nav_arrow {
    top: calc(-60px + -90px);
  }
}

@media only screen and (max-width: 1200px) {
  .s-product_slider-inr li .caption {
    font-size: calc((100vw - 376px) / 826 * 1 + 13px);
  }
}

@media only screen and (max-width: 375px) {
  .s-product_slider-inr li .caption {
    font-size: calc(10px + .8vw);
  }
}

.s-product_ttl-large {
  font-size: 6rem;
  line-height: .775;

  .min {
    display: block;
    font-size: .6666666667rem;
    letter-spacing: .02em;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-product_ttl-large {
    font-size: calc((100vw - 376px) / 826 * 30 + 50px);
  }
}

@media only screen and (max-width: 375px) {
  .s-product_ttl-large {
    font-size: calc(10px + 10.6666666667vw);
  }
}

@media only screen and (max-width: 375px) {
  .s-product_ttl-large .min {
    margin-top: 24px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center center;
    transform-origin: center center;
  }
}

.s-product_behind {
  padding-bottom: 41px;

  .l-article_img2col .num {
    font-size: .6666666667rem;
    display: block;
    margin-bottom: 16px;
    line-height: 1.28;
  }

  .l-article-other {
    padding-top: 0;
  }

  .l-article_img2col {
    -webkit-align-items: center;
    align-items: center;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_behind {
    padding-bottom: 31px;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_behind .l-article_img2col .num {
    margin-bottom: 5px;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_behind .l-article-other {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_behind p {
    margin-bottom: 64px !important;
  }
}

.s-product_lineup-top {
  margin-bottom: 120px;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_lineup-top {
    margin-bottom: 66px;
    height: 88vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_lineup-top img {
    object-fit: cover;
    height: 100%;
    object-position: center center;
  }
}

.s-product_line {
  margin: 0 0 0 14.0625vw;
  padding-bottom: 140px;
}

@media only screen and (max-width: 750px) {
  .s-product_line {
    margin: 0 0 0 9.6vw;
    padding-bottom: 97px;
  }
}

.s-product_line-large {
  margin: 0 0 0 7.03125vw;
  padding-bottom: 198px;

  .s-product_line-block p, .s-product_line-lineup {
    margin-right: 16.40625vw;
  }

  .s-product_line-slider .l-slider_progress {
    width: calc(100% - 58.12vw);
    top: auto;
    bottom: -12px;
    right: 27.81vw;
  }

  .s-product_line-slider-inr {
    .slick-nav_prev {
      right: 18.75vw;
    }

    .slick-nav_next {
      right: 14.0625vw;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-large {
    margin: 0 0 0 9.6vw;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-large .s-product_line-block p {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-large .s-product_line-lineup {
    margin-right: 9.6vw;
  }
}

.s-product_line-ttl {
  margin-bottom: 60px;

  .c-ttl-large {
    font-size: 5.3333333333rem;
    line-height: .85;
  }

  .min {
    display: block;
    font-size: 1.2rem;
    letter-spacing: .02em;
    margin-top: 28px;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-ttl {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-product_line-ttl .c-ttl-large {
    font-size: calc((100vw - 376px) / 826 * 40 + 40px);
  }
}

@media only screen and (max-width: 375px) {
  .s-product_line-ttl .c-ttl-large {
    font-size: calc(10px + 8vw);
    line-height: 1;
  }
}

@media only screen and (max-width: 375px) {
  .s-product_line-ttl .min {
    margin-top: 16px;
    font-size: calc(10px + 0vw);
    -webkit-transform: none;
    transform: none;
  }
}

.s-product_line-group {
  padding-bottom: 113px;
}

@media only screen and (max-width: 750px) {
  .s-product_line-group {
    padding-bottom: 64px;
  }
}

.s-product_line-slider {
  position: relative;

  ul {
    width: 100%;
  }

  .l-slider_progress {
    width: calc(100% - 51.09vw);
    top: auto;
    bottom: -12px;
    right: 20.78vw;
  }
}

.s-product_line-slider-inr {
  padding-bottom: 74px;

  .slick-nav_arrow {
    height: 60px;
    bottom: -41px;
  }

  .slick-nav_prev {
    right: 12.1875vw;
  }

  .slick-nav_next {
    right: 7.34375vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-slider-inr {
    padding-bottom: 46px;
  }
}

.s-product_line-block {
  display: flex;
  flex-wrap: wrap;

  p {
    -webkit-flex: 1;
    flex: 1;
    margin: 86px 9.375vw 0 0;
    font-size: 1.0666666667rem;
    line-height: 2;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-block {
    display: block;
    margin-right: 9.6vw;
  }
}

@media only screen and (max-width: 1200px) {
  .s-product_line-block p {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-block p {
    margin: 0 0 0 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-product_line-block p {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.8571428571;
  }
}

.s-product_line-block-ttl {
  width: 24.21875vw;
  margin-right: 6.09375vw;
  font-size: 1.8666666667rem;
  line-height: 1.3571428571;
}

@media only screen and (max-width: 1200px) {
  .s-product_line-block-ttl {
    font-size: calc((100vw - 376px) / 826 * 8 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-block-ttl {
    width: auto;
    margin-right: 0;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 375px) {
  .s-product_line-block-ttl {
    font-size: calc(10px + 2.6666666667vw);
    line-height: 1.5;
  }
}

.s-product_line-lineup {
  display: flex;
  flex-wrap: wrap;
  margin-right: 9.375vw;
  margin-left: -4.6875vw;
  margin-top: -20px;

  .c-item-name {
    margin-top: 27px;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-lineup {
    display: block;
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-lineup .c-item-name {
    margin-top: 26px;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-lineup .c-item-price {
    margin-top: 4px;
  }
}

.s-product_line-block-lineupttl {
  width: 26.09375vw;
  margin-right: .78125vw;
  font-size: 5.3333333333rem;
  line-height: .775;

  .min {
    display: block;
    font-size: .6666666667rem;
    letter-spacing: .02em;
    margin-top: 26px;
  }

  .sp {
    display: none;
  }
}

@media only screen and (max-width: 1200px) {
  .s-product_line-block-lineupttl {
    font-size: calc((100vw - 376px) / 824 * 60 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-block-lineupttl {
    width: auto;
    margin-right: 0;
    margin-bottom: 40px;
    font-size: calc((100vw - 376px) / 824 * 30 + 50px);
  }
}

@media only screen and (max-width: 375px) {
  .s-product_line-block-lineupttl {
    font-size: calc(10px + 10.6666666667vw);
    line-hezight: .8;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-block-lineupttl .min {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 375px) {
  .s-product_line-block-lineupttl .min {
    margin-top: 24px;
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-block-lineupttl .pc {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .s-product_line-block-lineupttl .sp {
    display: block;
  }
}

.p-brand-detail {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 9.3333333333rem;
    margin-bottom: 60px;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: .9333333333rem;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-main-type1 {
    .primary {
      margin: 0 0 17px 0;
      font-size: 2.6666666667rem;
      line-height: .75;
    }

    .inr {
      display: block;
      margin: 0;
      text-align: left;
    }
  }

  .l-content-item-choice2 {
    padding-bottom: 197px;
  }

  .l-article, .l-article_img2col, .l-article_large-figure {
    margin-bottom: 170px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-brand-detail .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-brand-detail .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-brand-detail .s-top_main-visual-circle {
    top: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .l-content-item-choice2 {
    padding-bottom: 116px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .l-article {
    margin-bottom: 93px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .l-article_img2col {
    margin-bottom: 62px;
  }
}

@media only screen and (max-width: 750px) {
  .p-brand-detail .l-article_large-figure {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .l-content-item-choice2_sp .c-btn-small a {
    margin-left: -9.6vw;
  }
}

.p-journal {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 8rem;
    margin-bottom: -4px;
    line-height: 1.1;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: .9333333333rem;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-pagenation {
    width: 81.25vw;
    margin: 0 auto;
    padding: 28px 0 200px 0;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-journal .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-journal .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-journal .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 44 + 76px);
  }
}

@media only screen and (max-width: 375px) {
  .p-journal .s-top_main-visual-ttl {
    font-size: calc(10px + 17.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-journal .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .p-journal .s-top_main-visual-dtl {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.8333333333;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-journal .s-top_main-visual-circle {
    top: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal .l-pagenation {
    padding: 54px 0 120px 0;
    width: auto;
    margin: 0 9.6vw;
  }
  .l-pagenation {
    font-size: 1rem;
  }
}

.s-journal_main-ttl {
  position: absolute;
  top: calc(50% + 3.9vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .s-journal_main-ttl {
    top: 50%;
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-journal_main-ttl {
    top: 50%;
  }
}

.s-journal_sort-wrap {
  width: 81.25vw;
  margin: 0 auto 48px auto;
  padding-top: 97px;
}

@media only screen and (max-width: 750px) {
  .s-journal_sort-wrap {
    padding-top: 66px;
    width: auto;
    margin: 0 9.6vw 30px 9.6vw;
  }
}

.s-journal_sort {
  position: relative;
  font-size: .9333333333rem;
  line-height: 1.2;
  text-align: right;

  ul {
    position: absolute;
    width: 240px;
    top: 34px;
    right: 0;
    padding: 17px 0 18px 0;
    text-align: left;
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 0;
    -webkit-transition: opacity 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
    transition: opacity 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
    background-color: #DCD8D0;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    z-index: 1;

    &.open {
      -webkit-transition: opacity 0.5s, transform 0.5s cubic-bezier(0.19, 0.82, 0.27, 1);
      transition: opacity 0.5s, transform 0.5s cubic-bezier(0.19, 0.82, 0.27, 1);
      -webkit-transform: scaleY(1);
      transform: scaleY(1);
      opacity: 1;

      li {
        opacity: 1;
      }
    }

    li {
      display: block;
      opacity: 0;
      -webkit-transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1);
      transition: opacity 0.3s cubic-bezier(0.6, 0.25, 0, 1);
      -webkit-transition-delay: .46s;
      transition-delay: .46s;

      &.current {
        background-color: #E3E0D9;
      }

      a {
        display: block;
        padding: 13px 2.34375vw 12px 2.34375vw;
        -webkit-transition: background-color 0.4s;
        transition: background-color 0.4s;

        &:hover {
          opacity: 1;
          background-color: #E3E0D9;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-journal_sort {
    font-size: calc((100vw - 376px) / 826 * 1 + 13px);
  }
}

@media only screen and (max-width: 375px) {
  .s-journal_sort {
    font-size: calc(10px + .8vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_sort ul {
    width: 194px;
    padding: 10px 0 10px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_sort ul li a {
    padding: 11px 20px;
  }
}

.s-journal_sort-ttl {
  display: inline-block;
  margin: 0 16px 0 0;
  position: relative;
  letter-spacing: normal;
}

@media only screen and (max-width: 375px) {
  .s-journal_sort-ttl {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
}

.s-journal_sort-this {
  display: inline-block;
  font-size: 1.0666666667rem;
  padding-right: 30px;
  position: relative;
  cursor: pointer;

  &:after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border: 1px solid #1F2C5C;
    border-bottom: none;
    border-left: none;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    -webkit-transform-origin: center center;
    transform-origin: center center;
    position: absolute;
    top: calc(50% - 3px);
    right: 0;
    -webkit-transition: top 0.4s, transform 0.4s cubic-bezier(0.19, 0.82, 0.27, 1);
    transition: top 0.4s, transform 0.4s cubic-bezier(0.19, 0.82, 0.27, 1);
  }

  &.btncurrent:after {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    top: calc(50%);
  }
}

@media only screen and (max-width: 1200px) {
  .s-journal_sort-this {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 375px) {
  .s-journal_sort-this {
    font-size: calc(10px + 1.0666666667vw);
  }
}

.s-journal_list {
  width: 81.25vw;
  margin: 0 auto 0 auto;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 375px) {
  .s-journal_list {
    display: block;
    width: auto;
    margin: 0 9.6vw 0 9.6vw;
  }
}

.s-journal_block {
  width: 28.84%;
  margin: 0 6.74% 114px 0;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  .sbttl {
    display: block;
    font-size: .7333333333rem;
    line-height: 1.8181818182;
    margin-bottom: 7px;
    letter-spacing: .02em;
  }

  .ttl {
    font-size: 2.4rem;
    line-height: 1;
    letter-spacing: -.02em;
    margin-bottom: 17px;
  }

  p {
    font-size: .8rem;
    line-height: 1.6666666667;
  }

  figure {
    margin-bottom: 36px;
    overflow: hidden;
  }

  a {
    display: block;

    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_block {
    width: 46.63%;
    margin: 0 6.74% 46px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-journal_block {
    width: auto;
    margin: 0 0 46px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_block:nth-of-type(3n) {
    margin-right: 6.74%;
  }
}

@media only screen and (max-width: 375px) {
  .s-journal_block:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_block:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-journal_block .sbttl {
    font-size: calc((100vw - 376px) / 826 * 1 + 10px);
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_block .sbttl {
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 375px) {
  .s-journal_block .sbttl {
    font-size: calc(10px + 0vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-journal_block .ttl {
    font-size: calc((100vw - 376px) / 826 * 2 + 34px);
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_block .ttl {
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .s-journal_block .ttl {
    font-size: calc(10px + 6.4vw);
    line-height: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .s-journal_block p {
    font-size: calc((100vw - 376px) / 826 * 1 + 11px);
  }
}

@media only screen and (max-width: 375px) {
  .s-journal_block p {
    font-size: calc(10px + .2666666667vw);
    line-height: 1.6363636364;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal_block figure {
    margin-bottom: 22px;
  }
}

.p-journal-detail {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:not(.no-bg):after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }

    &.no-bg figure:after {
      background-color: inherit;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 6rem;
    margin-bottom: -4px;

    .s-top_main-visual-ttl-row {
      display: block;
    }
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: 1.3333333333rem;
    margin-top: 32px;
    &.base{
      @apply text-base;
    }
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-article_img2col, .l-article_large-figure {
    margin-bottom: 170px;
  }

  .l-article_middle-figure {
    margin: 0 9.375vw 140px 9.375vw;
  }

  .l-article {
    margin-bottom: -3px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-journal-detail .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-journal-detail .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-journal-detail .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 30 + 60px);
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .s-top_main-visual-ttl {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 375px) {
  .p-journal-detail .s-top_main-visual-ttl {
    font-size: calc(10px + 13.3333333333vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-journal-detail .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 5 + 15px);
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .s-top_main-visual-dtl {
    margin-top: 12px;
  }
}

@media only screen and (max-width: 375px) {
  .p-journal-detail .s-top_main-visual-dtl {
    font-size: calc(10px + 1.3333333333vw);
    line-height: 1.8666666667;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-journal-detail .s-top_main-visual-circle {
    top: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .l-article_img2col {
    margin-bottom: 64px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .l-article_large-figure {
    margin-bottom: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .l-article_middle-figure {
    margin: 0 -9.6vw 65px -9.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .l-article {
    margin-bottom: 108px;
  }
}

@media only screen and (max-width: 750px) {
  .p-journal-detail .l-content-item-choice2 {
    padding-bottom: 118px;
  }
}

.s-journal-detail_main-ttl {
  position: absolute;
  top: calc(50% + 3.2vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;

  .txt {
    padding-bottom: .2em;
  }

  .s-top_main-visual-ttl-row {
    margin-top: -.1em;
  }
}

@media only screen and (max-width: 1024px) {
  .s-journal-detail_main-ttl.keito {
    top: unset;
    bottom: 0;
  }
}


@media only screen and (max-width: 750px) {
  .s-journal-detail_main-ttl {
    top: calc(50% - 1.2vh);

    &.keito {
      top: unset;
      bottom: 0;
    }
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-journal-detail_main-ttl {
    top: 50%;
  }
}

.s-journal-detail_mainsubttl {
  color: #E3E0D9;
  font-size: 1.6rem;
  letter-spacing: -.04em;
  line-height: 1.1;
  margin-bottom: 24px;
}

@media only screen and (max-width: 1200px) {
  .s-journal-detail_mainsubttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_mainsubttl {
    margin-bottom: 22px;
  }
}

@media only screen and (max-width: 375px) {
  .s-journal-detail_mainsubttl {
    font-size: calc(10px + 2.6666666667vw);
  }
}

.s-journal-detail_related {
  padding-bottom: 100px;
  margin-bottom: 100px;
  border-bottom: 1px solid #BFBCB4;

  .s-journal_block {
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related {
    padding-bottom: 70px;
    margin: -10px 0 69px 0;
    border-bottom: none;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 80.8vw;
      height: 1px;
      position: absolute;
      left: 9.6vw;
      bottom: 0;
      background-color: #BFBCB4;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related .s-journal_block {
    margin: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related .s-journal_block p {
    margin-right: 10.1333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related .s-journal_block figure {
    margin-bottom: 26px;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related .s-journal_block .ttl {
    margin-bottom: 17px;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related .s-journal_block .sbttl {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related .s-journal_list {
    width: auto;
    margin: 0 0 0 9.6vw;
  }
}

.s-journal-detail_related-ttl {
  margin-bottom: 80px;
}

@media only screen and (max-width: 750px) {
  .s-journal-detail_related-ttl {
    margin: 0 0 41px 9.6vw;

    .c-ttl-max {
      text-align: left;

      .min {
        -webkit-transform-origin: left top;
        transform-origin: left top;
        margin-top: 14px;
      }
    }
  }
}

.p-care {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 8rem;
    margin-bottom: 44px;
    letter-spacing: -.04em;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: .9333333333rem;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-article-other {
    padding-top: 113px;
  }

  .l-article_ttl {
    font-size: 2.1333333333rem;
    line-height: 1.375;
    margin-bottom: 44px;
  }

  .l-article_img3col {
    margin-top: -29px;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-care .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-care .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-care .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 64 + 76px);
  }
}

@media only screen and (max-width: 750px) {
  .p-care .s-top_main-visual-ttl {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 375px) {
  .p-care .s-top_main-visual-ttl {
    font-size: calc(10px + 17.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-care .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .p-care .s-top_main-visual-dtl {
    width: 66.6666666667vw;
    margin: 0 auto;

    br {
      display: none;
    }
  }
}

@media only screen and (max-width: 375px) {
  .p-care .s-top_main-visual-dtl {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.8333333333;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-care .s-top_main-visual-circle {
    top: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .l-article-other {
    padding-top: 91px;
    margin-bottom: 57px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-care .l-article_ttl {
    font-size: calc((100vw - 376px) / 826 * 10 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .p-care .l-article_ttl {
    width: auto;
    margin: 0 0 31px 0;
    text-align: center;
  }
}

@media only screen and (max-width: 375px) {
  .p-care .l-article_ttl {
    font-size: calc(10px + 3.2vw);
    line-height: 1.4545454545;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .l-article_img3col {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .l-article_img3col figcaption {
    text-align: center;
  }
}

@media only screen and (max-width: 750px) {
  .p-care .l-contents-care_dtl p {
    font-size: inherit;
  }
}

.s-care_main-ttl {
  position: absolute;
  top: calc(50% + 5.32vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .s-care_main-ttl {
    top: calc(50% + 1.8vh);
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-care_main-ttl {
    top: 50%;
  }
}

.s-care_ttl {
  margin-bottom: 80px;
}

@media only screen and (max-width: 750px) {
  .s-care_ttl {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_ttl .c-ttl-max .min {
    margin-top: 15px;
  }
}

.s-care_repair-block {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid #4A5377;
  padding: 40px 0 36px 0;
}

@media only screen and (max-width: 750px) {
  .s-care_repair-block {
    display: block;
    width: auto;
    padding: 20px 0 16px 0;
  }
}

.s-care_repair-block-txt {
  -webkit-flex: 1;
  flex: 1;
  text-align: right;
  margin: 0;
  font-size: 1.2rem;
  letter-spacing: normal;

  .unit {
    font-size: .8rem;
  }
}

@media only screen and (max-width: 1200px) {
  .s-care_repair-block-txt {
    font-size: calc((100vw - 376px) / 826 * 2 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_repair-block-txt {
    text-align: left;
  }
}

@media only screen and (max-width: 375px) {
  .s-care_repair-block-txt {
    font-size: calc(10px + 1.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-care_repair-block-txt .unit {
    font-size: calc((100vw - 376px) / 826 * 2 + 10px);
  }
}

@media only screen and (max-width: 375px) {
  .s-care_repair-block-txt .unit {
    font-size: calc(10px + 0vw);
  }
}

.s-care_repair-block-att {
  font-size: 12px !important;
  text-align: left;
  margin-top: 20px !important;
}

@media only screen and (max-width: 375px) {
  .s-care_repair-block-att {
    font-size: 11px !important;
    margin-top: 10px !important;
  }
}

.s-care_repair-block-ttl {
  width: 71.11%;
  text-align: left;
  font-size: 1.4666666667rem;
  padding-right: 20px;

  .min {
    font-size: .9333333333rem;
  }
}

@media only screen and (max-width: 1200px) {
  .s-care_repair-block-ttl {
    font-size: calc((100vw - 376px) / 826 * 6 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_repair-block-ttl {
    width: auto;
    padding-right: 0;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 375px) {
  .s-care_repair-block-ttl {
    font-size: calc(10px + 1.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-care_repair-block-ttl .min {
    font-size: calc((100vw - 376px) / 826 * 3 + 11px);
  }
}

@media only screen and (max-width: 375px) {
  .s-care_repair-block-ttl .min {
    font-size: calc(10px + .2666666667vw);
  }
}

.s-care_repair-block-fig {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  margin-top: 26px;
  margin-bottom: -6px;
  padding-left: 12.03125vw;

  figure {
    width: 40.54%;
    height: auto;
    margin: 0;

    figcaption {
      font-size: .6666666667rem;
      text-align: right;
      margin-top: 10px;
    }
  }

  .emp {
    width: 18.92%;
    position: relative;

    img {
      font-size: .6666666667rem;
      position: absolute;
      top: calc(50% - 1em);
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-care_repair-block-fig {
    padding-left: 0;
    margin-top: 17px;
    margin-bottom: 4px;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_repair-block-fig figure {
    width: 40.44%;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_repair-block-fig figure figcaption {
    text-align: left;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_repair-block-fig .emp {
    width: 19.12%;
  }
}

.s-care_info {
  width: 81.25vw;
  margin: 0 auto;
  padding: 80px 0 124px 0;

  a {
    border-bottom: 1px solid #1F2C5C;
  }

  .c-ttl-max {
    margin-bottom: 80px;
  }

  .s-item-detail_form-section-tel {
    border-bottom: none;
    margin-bottom: 17px;

    li {
      width: 56.71%;
      margin-left: 1em;

      &:nth-of-type(2n) {
        margin-left: 0;
        -webkit-flex: 1;
        flex: 1;
      }
    }

    .telnum {
      margin-top: 13px;
    }
  }

  .default li {
    font-size: .9333333333rem;
    letter-spacing: normal;
    text-indent: -1em;
    margin-left: 1em;
    margin-bottom: 0;
    line-height: 2;

    &:before {
      content: "・";
      margin-right: .5em;
    }
  }

  .small {
    font-size: .9333333333rem;
    margin-bottom: 63px;
    line-height: 2;
  }

  p + .default {
    margin-top: -59px;
    margin-bottom: 28px;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info {
    padding: 30px 0 73px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info .c-ttl-max {
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info .s-item-detail_form-section-tel {
    margin-left: 0;
    margin-bottom: 13px;
    padding-left: 1em;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info .s-item-detail_form-section-tel li {
    width: 50%;
    margin-left: 0;
    padding-right: 20px;
    margin-bottom: 28px;

    &:nth-of-type(2n) {
      padding-left: 1.3em;
      padding-right: 0;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info .s-item-detail_form-section-tel li:before {
    margin-right: 0.3em;
  }
}

@media only screen and (max-width: 1200px) {
  .s-care_info .default li {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info .default li {
    margin-bottom: 0;
    line-height: 2.1666666667;
  }
}

@media only screen and (max-width: 375px) {
  .s-care_info .default li {
    font-size: calc(10px + .5333333333vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-care_info .small {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info .small {
    margin-top: 2em;
    line-height: 2.1666666667;
    margin-bottom: 46px;
  }
}

@media only screen and (max-width: 375px) {
  .s-care_info .small {
    font-size: calc(10px + .5333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info p + .default {
    margin-top: -42px;
  }
}

.s-care_info-block {
  display: flex;
  flex-wrap: wrap;
  font-size: 1.0666666667rem;
  line-height: 2;
  padding: 72px 0 10px 0;
}

@media only screen and (max-width: 1200px) {
  .s-care_info-block {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info-block {
    display: block;
    padding: 33px 0 0 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-care_info-block {
    font-size: calc(10px + 1.0666666667vw);
  }
}

.s-care_info-ttl {
  width: 23.4375vw;
  margin-right: 5.46875vw;
  font-size: 1.8666666667rem;
  line-height: 1.5714285714;
}

@media only screen and (max-width: 1200px) {
  .s-care_info-ttl {
    font-size: calc((100vw - 376px) / 826 * 8 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info-ttl {
    width: auto;
    margin: 0 0 36px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-care_info-ttl {
    font-size: calc(10px + 2.6666666667vw);
    line-height: 1.6;
  }
}

.s-care_info-dtl {
  -webkit-flex: 1;
  flex: 1;

  p {
    margin-bottom: 60px;
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info-dtl p {
    margin-bottom: 42px;
    line-height: 1.8571428571;
  }
}

.s-care_info-sbttl {
  font-size: 1.3333333333rem;
  line-height: 1.6;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1200px) {
  .s-care_info-sbttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .s-care_info-sbttl {
    margin-bottom: 24px;
  }
}

@media only screen and (max-width: 375px) {
  .s-care_info-sbttl {
    font-size: calc(10px + 1.6vw);
    line-height: 1.5;
  }
}

@media only screen and (max-width: 1200px) {
  .s-care_info-small {
    font-size: calc((100vw - 376px) / 826 * 4 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .s-care_info-small {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.5;
  }
}

.s-shop_nav--sp .js-shopactive-list-main.current {
  border-bottom: 0;
}

.p-shop {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 8rem;
    margin-bottom: -4px;
    line-height: 1.1;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: .9333333333rem;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }
}

@media only screen and (max-width: 750px) {
  .p-shop .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-shop .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-shop .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-shop .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 44 + 76px);
  }
}

@media only screen and (max-width: 375px) {
  .p-shop .s-top_main-visual-ttl {
    font-size: calc(10px + 17.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-shop .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .p-shop .s-top_main-visual-dtl {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.8333333333;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-shop .s-top_main-visual-circle {
    top: 350px;
  }
}

.s-shop_main-ttl {
  position: absolute;
  top: calc(50% + 3.9vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .s-shop_main-ttl {
    top: 50%;
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-shop_main-ttl {
    top: 50%;
  }
}

.s-shop_nav {
  position: fixed;
  width: 15.625vw;
  bottom: 20px;
  left: 4.6875vw;
  z-index: 10;

  .current {
    border-bottom: 1px solid #1F2C5C;
  }
}

.s-shop_nav--pc {
  -webkit-transition: top 0.6s cubic-bezier(0.55, 0.01, 0.45, 1), opacity 0.2s;
  transition: top 0.6s cubic-bezier(0.55, 0.01, 0.45, 1), opacity 0.2s;

  &.js-hide {
    opacity: 0;
    pointer-events: none;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_nav--pc {
    display: none;
  }
}

.js-shopsticky-abs .s-shop_nav--pc {
  height: 0;
  overflow: hidden;
  pointer-events: none;
}

.s-shop_nav--abs {
  position: absolute;
  top: 107px;
  bottom: auto;
  height: 0;
  overflow: hidden;
  pointer-events: none;
}

@media only screen and (max-width: 750px) {
  .s-shop_nav--abs {
    display: none;
  }
}

.js-shopsticky-abs .s-shop_nav--abs {
  height: auto;
  overflow: visible;
  pointer-events: auto;
}

.s-shop_nav--sp {
  display: none;
}

@media only screen and (max-width: 750px) {
  .s-shop_nav--sp {
    display: block;
    position: static;
    width: auto;
    margin: 0;
    padding: 14px 0 52px 0;
  }
}

.s-shop_nav-primary {
  font-size: .8666666667rem;
  line-height: 1.3;

  li {
    margin-bottom: 15px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-shop_nav-primary {
    font-size: calc((100vw - 376px) / 826 * 1 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_nav-primary {
    font-size: calc(10px + .5333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_nav-primary li {
    display: inline-block;
    margin-right: 1.5em;

    &:last-child {
      margin-right: 0;
    }
  }
}

.s-shop_nav-secondary {
  margin: 16px 0 44px 1.2em;
}

.s-shop_nav-ttl {
  font-size: 1.8666666667rem;
  line-height: 2;
  margin-bottom: 21px;
  border-top: 1px solid #BFBCB4;
}

@media only screen and (max-width: 1200px) {
  .s-shop_nav-ttl {
    font-size: calc((100vw - 376px) / 826 * 2 + 26px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_nav-ttl {
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_nav-ttl {
    font-size: calc(10px + 4.2666666667vw);
    line-hezight: 2.1538461538;
  }
}

.s-shop_nav-inr {
  position: relative;
  height: 100%;
}

.s-shop_inr {
  position: relative;
  padding: 107px 9.375vw 0 28.125vw;
}

@media only screen and (max-width: 750px) {
  .s-shop_inr {
    padding: 67px 9.6vw 0 9.6vw;
  }
}

.s-shop_ttl {
  font-size: 1.8666666667rem;
  margin-bottom: 30px;
}

@media only screen and (max-width: 1200px) {
  .s-shop_ttl {
    font-size: calc((100vw - 376px) / 826 * 6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_ttl {
    margin-bottom: 31px;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_ttl {
    font-size: calc(10px + 3.2vw);
    line-height: 1.3636363636;
  }
}

.s-shop_sbttl {
  font-size: 1.0666666667rem;
  margin-bottom: 23px;
}

@media only screen and (max-width: 1200px) {
  .s-shop_sbttl {
    font-size: calc((100vw - 376px) / 826 * 1 + 15px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_sbttl {
    margin-bottom: 14px;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_sbttl {
    font-size: calc(10px + 1.3333333333vw);
  }
}

.s-shop_list {
  width: 100%;
  padding: 0 0 32px 0;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 375px) {
  .s-shop_list {
    width: auto;
    display: block;
    padding: 0 0 30px 0;
  }
}

.s-shop_block {
  width: 30%;
  margin: 0 5% 60px 0;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  .ttl {
    font-size: 1.1333333333rem;
    line-height: 1.2941176471;
    margin-bottom: 14px;
  }

  p {
    font-size: .7333333333rem;
    line-height: 1.4545454545;
  }

  figure {
    margin-bottom: 28px;
    overflow: hidden;
  }

  a {
    display: block;

    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_block {
    width: 47.5%;
    margin: 0 5% 33px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_block {
    width: auto;
    margin: 0 0 33px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_block:nth-of-type(3n) {
    margin-right: 5%;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_block:nth-of-type(3n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_block:nth-of-type(2n) {
    margin-right: 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-shop_block .ttl {
    font-size: calc((100vw - 376px) / 826 * 1 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_block .ttl {
    margin-bottom: 7px;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_block .ttl {
    font-size: calc(10px + 1.6vw);
    line-height: 1.375;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_block figure {
    margin-bottom: 22px;
  }
}

.s-shop_list-simple {
  padding: 63px 0 94px 0;

  .s-shop_ttl {
    margin-bottom: 36px;
  }

  a {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    -webkit-align-items: center;
    align-items: center;
    width: 100%;
    font-size: .8666666667rem;
    border-bottom: 1px solid #BFBCB4;
    padding: 19px 0;

    &:nth-of-type(1) {
      border-top: 1px solid #BFBCB4;
    }

    dt {
      width: 18.75vw;
      margin-right: 2.34375vw;
    }

    dd {
      -webkit-flex: 1;
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      -webkit-align-items: center;
      align-items: center;

      .tel {
        display: block;
        width: 120px;
        padding-right: 10px;
        white-space: nowrap;
      }

      .address {
        display: block;
        -webkit-flex: 1;
        flex: 1;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple {
    padding: 31px 0 65px 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-shop_list-simple dl {
    font-size: calc((100vw - 376px) / 826 * 2 + 11px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple dl {
    display: block;
    width: auto;
    padding: 17px 0 19px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop_list-simple dl {
    font-size: calc(10px + .2666666667vw);
    line-height: 1.6363636364;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple dl dt {
    width: auto;
    margin-right: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple dl dd {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple dl dd .tel {
    width: auto;
    padding-right: 0;
  }
}

.s-shop_list-simple--overseas {
  padding-bottom: 200px;

  dl {
    padding: 9px 0 9px 0;

    dt {
      width: 200px;
    }

    dd {
      .tel {
        width: 170px;
        white-space: nowrap;
      }

      .address {
        line-height: 1.7;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple--overseas {
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple--overseas dl {
    padding: 18px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple--overseas dl dt {
    width: auto;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop_list-simple--overseas dl dd .tel {
    width: auto;
  }
}

.p-shop-detail {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 6rem;
    margin-bottom: -4px;

    .s-top_main-visual-ttl_sp {
      display: none;
    }
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: 1.3333333333rem;
    margin-top: 12px;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }

  .l-article_large-figure {
    margin-bottom: 168px;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-shop-detail .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-shop-detail .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-shop-detail .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 40 + 50px);
  }
}

@media only screen and (max-width: 375px) {
  .p-shop-detail .s-top_main-visual-ttl {
    font-size: calc(10px + 10.6666666667vw);
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .s-top_main-visual-ttl .s-top_main-visual-ttl_pc {
    display: none;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .s-top_main-visual-ttl .s-top_main-visual-ttl_sp {
    display: block;
  }
}

@media only screen and (max-width: 1200px) {
  .p-shop-detail .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 5 + 15px);
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .s-top_main-visual-dtl {
    margin-top: 18px;
  }
}

@media only screen and (max-width: 375px) {
  .p-shop-detail .s-top_main-visual-dtl {
    font-size: calc(10px + 1.3333333333vw);
    line-height: 1.8666666667;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-shop-detail .s-top_main-visual-circle {
    top: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .p-shop-detail .l-article_large-figure {
    margin-bottom: 97px;
  }
}

.s-shop-detail_main-ttl {
  position: absolute;
  top: calc(50% + 3.2vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;

  .js-textrowmotion-txt {
    padding-bottom: .2em;
  }

  .js-textrowmotion-row {
    margin-top: -.1em;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_main-ttl {
    top: calc(50% - -.8vh);
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-shop-detail_main-ttl {
    top: 50%;
  }
}

.s-shop-detail_mainsubttl {
  color: #E3E0D9;
  font-size: 1.6rem;
  letter-spacing: -.04em;
  line-height: 1.1;
  margin-bottom: 29px;
}

@media only screen and (max-width: 1200px) {
  .s-shop-detail_mainsubttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 20px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_mainsubttl {
    margin-bottom: 19px;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop-detail_mainsubttl {
    font-size: calc(10px + 2.6666666667vw);
  }
}

.s-shop-detail_wrap {
  padding-top: 113px;
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_wrap {
    padding-top: 93px;
  }
}

.s-shop-detail_header {
  width: 48.4375vw;
  margin: 0 auto;
  padding-bottom: 132px;
  font-size: 1.0666666667rem;
  line-height: 2;

  a {
    border-bottom: 1px solid #1F2C5C;
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;

    dt {
      width: 12.34375vw;
    }

    dd {
      -webkit-flex: 1;
      flex: 1;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-shop-detail_header {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_header {
    width: auto;
    margin: 0 9.6vw;
    padding-bottom: 94px;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop-detail_header {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.8571428571;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_header dl {
    display: block;
    margin-bottom: 18px;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_header dl dt {
    width: auto;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_header dl dd {
    line-height: 1.2;
  }
}

.s-shop-detail_header-ttl {
  font-size: 1.3333333333rem;
  line-height: 2;
  padding: 50px 0 10px 0;
}

@media only screen and (max-width: 1200px) {
  .s-shop-detail_header-ttl {
    font-size: calc((100vw - 376px) / 826 * 2 + 18px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_header-ttl {
    padding: 45px 0 8px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop-detail_header-ttl {
    font-size: calc(10px + 2.1333333333vw);
  }
}

.s-shop-detail_name {
  font-size: 1.8666666667rem;
  line-height: 1.4285714286;
  margin-bottom: 45px;
  text-align: center;
}

@media only screen and (max-width: 1200px) {
  .s-shop-detail_name {
    font-size: calc((100vw - 376px) / 826 * 6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_name {
    margin-bottom: 29px;
    text-align: left;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop-detail_name {
    font-size: calc(10px + 3.2vw);
    line-height: 1.4545454545;
  }
}

.s-shop-detail_map-wrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 0 226px 0;

  figure {
    width: 50%;

    img {
      width: 100%;
    }
  }

  .map {
    width: 50%;
    -webkit-transform: translate(-18.75%, 60px);
    transform: translate(-18.75%, 60px);
  }

  .map-inr {
    position: relative;
    padding-top: 93.75%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .map_link {
    font-size: .8rem;
    margin-top: 12px;
    text-align: right;

    a {
      border-bottom: 1px solid #1F2C5C;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_map-wrap {
    display: block;
    width: auto;
    padding: 0 0 65px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_map-wrap figure {
    width: auto;
    margin-right: 19.2vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_map-wrap .map {
    width: auto;
    margin-left: 9.6vw;
    margin-top: -43.7333333333vw;
    -webkit-transform: none;
    transform: none;
  }
}

@media only screen and (max-width: 1200px) {
  .s-shop-detail_map-wrap .map_link {
    font-size: calc((100vw - 376px) / 826 * 1 + 11px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_map-wrap .map_link {
    margin-top: 10px;
    margin-right: 9.6vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop-detail_map-wrap .map_link {
    font-size: calc(10px + .2666666667vw);
  }
}

.s-shop-detail_note {
  padding-bottom: 130px;
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_note {
    padding-bottom: 114px;
  }
}

.s-shop-detail_note-ttl {
  margin-bottom: 77px;
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_note-ttl {
    margin: 0 0 42px 9.6vw;

    .c-ttl-max {
      text-align: left;

      .min {
        -webkit-transform-origin: left top;
        transform-origin: left top;
      }
    }
  }
}

@media only screen and (max-width: 750px) and (max-width: 750px) {
  .s-shop-detail_note-ttl .c-ttl-max .min {
    margin-top: 15px;
  }
}

.s-shop-detail_note-inr {
  display: flex;
  flex-wrap: wrap;
  width: 81.25vw;
  margin: 0 auto;
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_note-inr {
    display: block;
    width: auto;
    margin: 0 0 0 9.6vw;
  }
}

.s-shop-detail_note-block {
  width: 28.84%;
  margin-right: 6.74%;
  margin-bottom: 64px;

  &:nth-of-type(3n) {
    margin-right: 0;
  }

  a {
    display: block;
  }

  figure {
    overflow: hidden;
    margin-bottom: 39px;
  }

  .ttl {
    font-size: 1.3333333333rem;
    line-height: 1.2;
    margin-bottom: 23px;
    padding-right: 24px;
    position: relative;

    &:after {
      content: "";
      display: block;
      width: 12px;
      height: 11px;
      position: absolute;
      top: 3px;
      right: 0;
      background-image: url("../img/ico_arrow_b_dig.svg");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 100% auto;
    }
  }

  p {
    font-size: .8rem;
    line-height: 1.6666666667;
    margin-bottom: -1px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  time {
    font-size: .7333333333rem;
    line-height: 1;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_note-block {
    width: auto;
    margin-right: 0;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_note-block figure {
    margin-bottom: 26px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-shop-detail_note-block .ttl {
    font-size: calc((100vw - 376px) / 826 * 4 + 16px);
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_note-block .ttl {
    margin-bottom: 13px;
  }
}

@media only screen and (max-width: 375px) {
  .s-shop-detail_note-block .ttl {
    font-size: calc(10px + 1.6vw);
    line-height: 1.25;
  }
}

@media only screen and (max-width: 750px) {
  .s-shop-detail_note-block p {
    -webkit-line-clamp: 3;
    margin-right: 8vw;
  }
}

.p-company {
  .c-cover {
    top: calc(100vh - 219px);
  }

  .s-top_main-contents-mainghost {
    height: calc(100vh - 200px);
  }

  .s-top_main-visual {
    height: calc(100vh - 200px);
    min-height: 0;

    &:after {
      content: "";
      display: block;
      width: 100%;
      height: 105vh;
      position: absolute;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 0;
    }
  }

  .s-top_main-visual-ttl {
    position: static;
    font-size: 8rem;
    margin-bottom: -4px;
    line-height: 1.1;
  }

  .s-top_main-visual-dtl {
    position: static;
    width: auto;
    line-height: 1.8571428571;
    letter-spacing: .02em;
    font-size: .9333333333rem;
  }

  .s-top_main-visual-circle {
    width: 80px;
    height: 80px;
    position: absolute;
    top: calc(100vh - 250px);
    left: auto;
    right: 9.375vw;
    opacity: 1;
    transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1);
  }
}

@media only screen and (max-width: 750px) {
  .p-company .c-cover {
    top: calc(71.92vh - 35px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-company .c-cover {
    top: 380px;
  }
}

@media only screen and (max-width: 750px) {
  .p-company .s-top_main-contents-mainghost {
    height: calc(71.92vh - 21px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-company .s-top_main-contents-mainghost {
    height: 396px;
  }
}

@media only screen and (max-width: 750px) {
  .p-company .s-top_main-visual {
    height: 71.92vh;
    min-height: 396px;
  }
}

@media only screen and (max-width: 1200px) {
  .p-company .s-top_main-visual-ttl {
    font-size: calc((100vw - 376px) / 826 * 44 + 76px);
  }
}

@media only screen and (max-width: 375px) {
  .p-company .s-top_main-visual-ttl {
    font-size: calc(10px + 17.6vw);
  }
}

@media only screen and (max-width: 1200px) {
  .p-company .s-top_main-visual-dtl {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .p-company .s-top_main-visual-dtl {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.8333333333;
  }
}

@media only screen and (max-width: 750px) {
  .p-company .s-top_main-visual-circle {
    width: 16vw;
    max-width: 80px;
    height: 16vw;
    max-height: 80px;
    top: calc(71.92vh - 72px);
  }
}

@media only screen and (max-width: 750px) and (max-height: 550px) {
  .p-company .s-top_main-visual-circle {
    top: 350px;
  }
}

@media only screen and (max-width: 750px) {
  .s-company {
    padding-bottom: 120px;
  }
}

.s-company_main-ttl {
  position: absolute;
  top: calc(50% + 3.9vh);
  left: 0;
  width: 100%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
}

@media only screen and (max-width: 750px) {
  .s-company_main-ttl {
    top: 50%;
  }
}

@media only screen and (max-width: 750px) and (max-height: 520px) {
  .s-company_main-ttl {
    top: 50%;
  }
}

.s-company_inr {
  width: 62.5vw;
  margin: 0 auto;
  padding: 117px 0 170px 0;
  font-size: 1.0666666667rem;

  dl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  dt {
    width: 19.53125vw;
  }

  dd {
    -webkit-flex: 1;
    flex: 1;
  }
}

@media only screen and (max-width: 1200px) {
  .s-company_inr {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-company_inr {
    width: auto;
    margin: 0 9.6vw;
    padding: 93px 0 120px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-company_inr {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.4285714286;
  }
}

@media only screen and (max-width: 750px) {
  .s-company_inr dl {
    display: block;
    margin-bottom: 16px;
  }
}

@media only screen and (max-width: 750px) {
  .s-company_inr dt {
    width: auto;
  }
}

.s-company_ttl {
  font-size: 1.8666666667rem;
  line-height: 1.4285714286;
  padding-bottom: 26px;
  margin-bottom: 70px;
  border-bottom: 1px solid #BFBCB4;
}

@media only screen and (max-width: 1200px) {
  .s-company_ttl {
    font-size: calc((100vw - 376px) / 826 * 6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .s-company_ttl {
    padding-bottom: 20px;
    margin-bottom: 40px;
  }
}

@media only screen and (max-width: 375px) {
  .s-company_ttl {
    font-size: calc(10px + 3.2vw);
    line-height: 1.4545454545;
  }
}

.s-other {
  width: 62.5vw;
  margin: 0 auto;
  padding: 93px 0 162px 0;
}

@media only screen and (max-width: 750px) {
  .s-other {
    width: auto;
    margin: 0 9.6vw;
    padding: 66px 0 85px 0;
  }
}

.s-other_ttl {
  font-size: 1.8666666667rem;
  line-height: 1.4285714286;
  padding-bottom: 22px;
  margin-bottom: 63px;
  border-bottom: 1px solid #BFBCB4;
}

@media only screen and (max-width: 1200px) {
  .s-other_ttl {
    font-size: calc((100vw - 376px) / 826 * 6 + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .s-other_ttl {
    padding-bottom: 15px;
    margin-bottom: 37px;
  }
}

@media only screen and (max-width: 375px) {
  .s-other_ttl {
    font-size: calc(10px + 3.2vw);
  }
}

.s-other_subttl {
  font-size: 1.2rem;
  line-height: 1.5555555556;
  margin-bottom: 5px;
}

@media only screen and (max-width: 1200px) {
  .s-other_subttl {
    font-size: calc((100vw - 376px) / 826 * 3 + 15px);
  }
}

@media only screen and (max-width: 375px) {
  .s-other_subttl {
    font-size: calc(10px + 1.3333333333vw);
  }
}

.s-other_subttl2 {
  font-size: 1.0666666667rem;
  line-height: 2.125;
  margin-bottom: -.3em;
}

@media only screen and (max-width: 1200px) {
  .s-other_subttl2 {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 375px) {
  .s-other_subttl2 {
    font-size: calc(10px + 1.0666666667vw);
  }
}

.s-other_subttl3 {
  font-size: .9333333333rem;
  line-height: 2;
  margin-bottom: 1px;
}

@media only screen and (max-width: 1200px) {
  .s-other_subttl3 {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .s-other_subttl3 {
    font-size: calc(10px + .5333333333vw);
  }
}

.s-other {
  p {
    font-size: 1.0666666667rem;
    line-height: 1.75;
    margin-bottom: 55px;
  }

  ul {
    margin-bottom: 56px;

    li {
      position: relative;
      padding-left: 1em;

      &:before {
        display: block;
        content: "・";
        width: 1em;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  a {
    border-bottom: 1px solid #1F2C5C;
  }

  .middle {
    font-size: .9333333333rem;
    line-height: 2;
  }

  .small {
    font-size: .8rem;
    line-height: 2.1;
  }
}

@media only screen and (max-width: 1200px) {
  .s-other p {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-other p {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 375px) {
  .s-other p {
    font-size: calc(10px + 1.0666666667vw);
    line-height: 1.8571428571;
  }
}

@media only screen and (max-width: 750px) {
  .s-other ul {
    margin-bottom: 52px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-other .middle {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .s-other .middle {
    font-size: calc(10px + .5333333333vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-other .small {
    font-size: calc((100vw - 376px) / 826 * 1 + 11px);
  }
}

@media only screen and (max-width: 375px) {
  .s-other .small {
    font-size: calc(10px + .2666666667vw);
  }
}

.s-other_2col {
  display: flex;
  flex-wrap: wrap;

  p {
    font-size: .9333333333rem;
    line-height: 2;
  }
}

@media only screen and (max-width: 1200px) {
  .s-other_2col p {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 375px) {
  .s-other_2col p {
    font-size: calc(10px + .5333333333vw);
  }
}

.s-other_2col-block {
  padding-right: 16px;
  white-space: nowrap;
}

@media only screen and (max-width: 750px) {
  .s-other_2col-block {
    width: 50%;
    white-space: normal;
  }
}

.s-other {
  p + {
    ul {
      margin-top: -24px;
    }

    .s-other_subttl2 {
      margin-top: -37px;
    }

    .s-other_subttl3 {
      margin-top: -40px;
    }

    .s-other_2col {
      margin-top: -41px;
    }
  }

  .s-other_2col + p > .small {
    display: block;
    margin-top: -45px;
  }
}

@media only screen and (max-width: 750px) {
  .s-other p + ul {
    margin-top: -28px;
  }
}

@media only screen and (max-width: 750px) {
  .s-other p + .s-other_subttl2 {
    margin-top: -30px;
  }
}

@media only screen and (max-width: 750px) {
  .s-other p + .s-other_subttl3 {
    margin-top: -20px;
  }
}

@media only screen and (max-width: 750px) {
  .s-other p + .s-other_2col {
    margin-top: -10px;
  }
}

@media only screen and (max-width: 750px) {
  .s-other .s-other_2col + p > .small {
    margin-top: -10px;
  }
}

.s-other_adj01 {
  margin-top: -43px;
}

@media only screen and (max-width: 750px) {
  .s-other_adj01 {
    margin-top: -40px;
  }
}

.s-notfound_header {
  width: 54.6875vw;
  margin: 0 auto;
  padding: 66px 0 194px 0;
  text-align: center;
  font-size: 1.0666666667rem;
  line-height: 1.625;

  p {
    margin: -26px 0 49px 0;
  }

  .c-btn-small {
    margin: 0 auto;

    a {
      font-size: .9333333333rem;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-notfound_header {
    font-size: calc((100vw - 376px) / 826 * 4 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-notfound_header {
    width: auto;
    margin: 0 9.6vw;
    padding: 56px 0 116px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-notfound_header {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.8333333333;
  }
}

@media only screen and (max-width: 750px) {
  .s-notfound_header p {
    margin: 4px 0 39px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-notfound_header .c-btn-small a {
    font-size: .8rem;
  }
}

.s-notfound_header-ttl {
  font-size: 6.6666666667rem;
  line-height: 1.44;
  letter-spacing: -.04em;
}

@media only screen and (max-width: 1200px) {
  .s-notfound_header-ttl {
    font-size: calc((100vw - 376px) / 826 * 50 + 50px);
  }
}

@media only screen and (max-width: 375px) {
  .s-notfound_header-ttl {
    font-size: calc(10px + 10.6666666667vw);
  }
}

.s-login_wrap {
  display: flex;
  flex-wrap: wrap;
  background-color: #1F2C5C;
  pointer-events: auto;
  color: #E3E0D9;
  min-height: 100vh;

  .error_message {
    color: #F08300;
    font-size: .8rem;
    text-align: center;
  }

  a {
    color: #E3E0D9;
    border-bottom: 1px solid #E3E0D9;
  }

  label {
    display: block;
    color: #E3E0D9;
    font-size: .8666666667rem;
    margin-bottom: 6px;
    font-weight: bold;
  }

  input {
    &.hidden {
      display: none;
    }

    &[type=text], &[type=password] {
      background-color: #18234D;
      color: #E3E0D9;
      border: 1px solid #18234D;
      border-radius: 6px;
      padding: 14px 20px;
      margin-bottom: 14px;
    }

    &[type=text]:focus {
      outline: none;
      border-radius: 6px;
      border: 1px solid #E3E0D9;
    }

    &[type=password] {
      &:focus {
        outline: none;
        border-radius: 6px;
        border: 1px solid #E3E0D9;
      }

      margin-bottom: 18px;
    }
  }

  .forgetmenot {
    margin-bottom: 14px;

    input {
      display: none;

      + label {
        position: relative;
        display: inline-block;
        min-height: 20px;
        cursor: pointer;
        vertical-align: middle;
        padding: 0 0 0 30px;
        font-size: .7333333333rem;
      }

      &[type=checkbox] {
        + label {
          &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 20px;
            height: 20px;
            border-radius: 6px;
            background-color: #18234D;
          }

          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 4px;
            left: 4px;
            width: 12px;
            height: 6px;
            border: 2px solid #E3E0D9;
            border-top: none;
            border-right: none;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            -webkit-transform-origin: center center;
            transform-origin: center center;
            opacity: 0;
            -webkit-transition: opacity 0.2s ease-out;
            transition: opacity 0.2s ease-out;
          }
        }

        &:checked + label::after {
          opacity: 1;
        }
      }
    }
  }

  input[type=submit] {
    font-size: 1.0666666667rem;
    padding: 16px 0;
    margin-bottom: 12px;
    background-color: #F08300;
    border-radius: 6px;
    font-weight: bold;
    cursor: pointer;
    color: #1F2C5C;
    -webkit-transition: opacity 0.4s;
    transition: opacity 0.4s;

    &:hover {
      opacity: .7;
    }
  }

  .loginbox_msg {
    font-weight: bold;
    text-align: center;
    font-size: .8rem;

    li:nth-of-type(2):before {
      content: "まだ会員登録がお済みでない方";
      font-size: .8rem;
      line-height: 1.5;
      display: block;
      margin: 28px 0 16px 0;
    }
  }

  .btn_typeB {
    display: block;
    max-width: 270px;
    border: 1px solid #E3E0D9;
    font-size: .9333333333rem;
    margin: 0 auto;
    padding: 17px 0 14px 0;
    line-height: 1;
    border-radius: 6px;

    .btn_typeB-inr {
      display: inline-block;
      overflow: hidden;
    }

    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-login_wrap {
    display: block;
  }
}

@media only screen and (max-width: 750px) {
  .s-login_wrap input {
    &[type=text], &[type=password] {
      margin-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-login_wrap input[type=submit] {
    padding: 16px 0;
  }
}

@media only screen and (max-width: 750px) {
  .s-login_wrap .loginbox_msg {
    text-align: left;
  }
}

@media only screen and (max-width: 750px) {
  .s-login_wrap .loginbox_msg li:nth-of-type(2):before {
    width: 90px;
    margin: 20px 0 20px 9.0666666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .s-login_wrap .btn_typeB {
    width: auto;
    border: none;
    max-width: none;
    background-color: #E3E0D9;
    color: #1F2C5C;
    text-align: center;
    padding: 17px 0 16px 0;
  }
}

.s-login_figure {
  width: calc(50vw - (50vw - 50%));
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    object-position: center center;
  }
}

@media only screen and (max-width: 750px) {
  .s-login_figure {
    display: none;
  }
}

.s-login_form {
  -webkit-flex: 1;
  flex: 1;
  align-self: center;
  padding: 150px 9.375vw 106px 9.375vw;
  transition: padding-top 0.2s;
}

@media only screen and (max-width: 750px) {
  .s-login_form {
    padding: 130px 9.6vw 117px 9.6vw;
  }
}

.s-login_form-ttl {
  font-size: 4.6666666667rem;
  line-height: .8857142857;
  color: #E3E0D9;
  text-align: center;
  margin-bottom: 23px;

  .min {
    display: block;
    font-size: .6666666667rem;
    margin-top: 1px;
    letter-spacing: .02em;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1200px) {
  .s-login_form-ttl {
    font-size: calc((100vw - 376px) / 826 * 20 + 50px);
  }
}

@media only screen and (max-width: 750px) {
  .s-login_form-ttl {
    margin-bottom: 60px;
    text-align: left;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 375px) {
  .s-login_form-ttl {
    font-size: calc(10px + 10.6666666667vw);
    line-height: .88;
  }
}

@media only screen and (max-width: 375px) {
  .s-login_form-ttl .min {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: left top;
    transform-origin: left top;
  }
}

.p-search {
  .s-item-archives_list-group {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 0;
    grid-row-gap: 0;
  }

  .s-item-archives_list-block {
    width: 21.25%;
    margin-right: 5%;
    margin-bottom: 59px;

    &:nth-of-type(4n) {
      margin-right: 0;
    }

    &:nth-of-type(1) .c-item-price, &:nth-of-type(10) .c-item-price, &:nth-of-type(19) .c-item-price, &:nth-of-type(28) .c-item-price {
      -webkit-transform: none;
      transform: none;
      font-size: .6666666667rem;
      line-height: 1.6;
      margin: 0;
    }

    &:nth-of-type(1) .s-item-archives_list-block-fig-a, &:nth-of-type(10) .s-item-archives_list-block-fig-a, &:nth-of-type(19) .s-item-archives_list-block-fig-a, &:nth-of-type(28) .s-item-archives_list-block-fig-a {
      padding-top: 128.2352941176%;
      margin-bottom: 17px;
      position: relative;
    }

    &:nth-of-type(1) .s-item-archives_list-block-fig-a figure, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }

    &:nth-of-type(1) .s-item-archives_list-block-fig-a figure img, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure img, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure img, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure img {
      width: 100%;
    }

    &:nth-of-type(1) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure figcaption {
      display: inline-block;
      position: absolute;
      bottom: 5px;
      left: 3px;
      background-color: #F08300;
      padding: 8px 14px;
      border-radius: 50%;
      font-size: .6666666667rem;
      -webkit-transform: scale(0.8);
      transform: scale(0.8);
      -webkit-transform-origin: center center;
      transform-origin: center center;
      line-height: 1;
    }

    &:nth-of-type(1) .s-item-archives_list-itemname, &:nth-of-type(10) .s-item-archives_list-itemname, &:nth-of-type(19) .s-item-archives_list-itemname, &:nth-of-type(28) .s-item-archives_list-itemname {
      font-size: .8rem;
      margin-bottom: 5px;
      line-height: 1.3333333333;
      letter-spacing: normal;
    }

    &:nth-of-type(1) .s-item-archives_list-item-colorchip, &:nth-of-type(10) .s-item-archives_list-item-colorchip, &:nth-of-type(19) .s-item-archives_list-item-colorchip, &:nth-of-type(28) .s-item-archives_list-item-colorchip {
      margin-bottom: 13px;
    }

    &:nth-of-type(1) .s-item-archives_list-item-colorchip li, &:nth-of-type(10) .s-item-archives_list-item-colorchip li, &:nth-of-type(19) .s-item-archives_list-item-colorchip li, &:nth-of-type(28) .s-item-archives_list-item-colorchip li {
      width: 20px;
      height: 20px;
    }

    &:nth-of-type(1) .s-item-archives_list-item-colorchip li.col-current:before, &:nth-of-type(10) .s-item-archives_list-item-colorchip li.col-current:before, &:nth-of-type(19) .s-item-archives_list-item-colorchip li.col-current:before, &:nth-of-type(28) .s-item-archives_list-item-colorchip li.col-current:before {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
    }

    &:nth-of-type(1) .s-item-archives_list-item-colorchip li.outline:after, &:nth-of-type(10) .s-item-archives_list-item-colorchip li.outline:after, &:nth-of-type(19) .s-item-archives_list-item-colorchip li.outline:after, &:nth-of-type(28) .s-item-archives_list-item-colorchip li.outline:after {
      content: "";
      display: block;
      width: 8px;
      height: 8px;
    }
  }

  .s-item-archives {
    padding-top: 70px;
  }
}

@media only screen and (max-width: 750px) {
  .p-search .s-item-archives_list-block {
    width: 45.28%;
    margin: 0 2.36% 7.4666666667vw 2.36%;
  }
}

@media only screen and (max-width: 750px) {
  .p-search .s-item-archives_list-block:nth-of-type(4n) {
    margin-right: 2.36%;
  }
}

@media only screen and (max-width: 750px) {
  .p-search .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-block-fig-a, &:nth-of-type(10) .s-item-archives_list-block-fig-a, &:nth-of-type(19) .s-item-archives_list-block-fig-a, &:nth-of-type(28) .s-item-archives_list-block-fig-a {
      margin-bottom: 14px;
    }
  }
}

@media only screen and (max-width: 750px) {
  .p-search .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(10) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(19) .s-item-archives_list-block-fig-a figure figcaption, &:nth-of-type(28) .s-item-archives_list-block-fig-a figure figcaption {
      bottom: 6px;
      left: 4px;
      padding: 8px 13px;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .p-search .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-itemname, &:nth-of-type(10) .s-item-archives_list-itemname, &:nth-of-type(19) .s-item-archives_list-itemname, &:nth-of-type(28) .s-item-archives_list-itemname {
      font-size: calc((100vw - 376px) / 826 * 1 + 11px);
    }
  }
}

@media only screen and (max-width: 375px) {
  .p-search .s-item-archives_list-block {
    &:nth-of-type(1) .s-item-archives_list-itemname, &:nth-of-type(10) .s-item-archives_list-itemname, &:nth-of-type(19) .s-item-archives_list-itemname, &:nth-of-type(28) .s-item-archives_list-itemname {
      font-size: calc(10px + .2666666667vw);
    }
  }
}

@media only screen and (max-width: 1280px) {
  .p-search .s-item-archives {
    padding: calc((100vw - 750px) / 530 * 50 + 20px) 0;
  }
}

.s-search_title {
  font-size: .9333333333rem;
  text-align: center;
  vertical-align: bottom;
  line-height: 1.1818181818;

  .result {
    font-size: 1.4666666667rem;
    margin: 0 17px;
  }
}

@media only screen and (max-width: 1200px) {
  .s-search_title {
    font-size: calc((100vw - 376px) / 826 * 4 + 10px);
  }
}

@media only screen and (max-width: 750px) {
  .s-search_title {
    text-align: left;
    margin: 41px 9.6vw 0 9.6vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-search_title {
    font-size: calc(10px + 0vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-search_title .result {
    font-size: calc((100vw - 376px) / 826 * 6 + 16px);
  }
}

@media only screen and (max-width: 375px) {
  .s-search_title .result {
    font-size: calc(10px + 1.6vw);
  }
}

.required {
  color: #F08300;
  margin-left: 7px;
  vertical-align: top;
  font-size: 75%;
}

.s-contact_header {
  width: 54.6875vw;
  margin: 0 auto;
  padding-top: 36px;
  text-align: center;
  font-size: 1.0666666667rem;
  line-height: 1.625;

  a {
    border-bottom: 1px solid #1F2C5C;
  }
}

@media only screen and (max-width: 1200px) {
  .s-contact_header {
    font-size: calc((100vw - 376px) / 826 * 4 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_header {
    width: auto;
    padding-top: 49px;
    margin: 0 9.6vw;
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_header {
    font-size: calc(10px + .5333333333vw);
    line-height: 1.8333333333;
  }
}

.s-contact_header-ttl {
  font-size: 6.6666666667rem;
  line-height: 1.44;
  letter-spacing: -.04em;

  + p {
    margin-top: -2.03125vw;
  }
}

@media only screen and (max-width: 1200px) {
  .s-contact_header-ttl {
    font-size: calc((100vw - 376px) / 826 * 40 + 60px);
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_header-ttl {
    font-size: calc(10px + 13.3333333333vw);
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_header-ttl + p {
    margin-top: -1.8666666667vw;
  }
}

.s-contact_header-subttl {
  margin: 42px 0 10px 0;
  font-size: .9333333333rem;
}

@media only screen and (max-width: 1200px) {
  .s-contact_header-subttl {
    font-size: calc((100vw - 376px) / 826 * 2 + 12px);
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_header-subttl {
    margin: 29px 0 2px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_header-subttl {
    font-size: calc(10px + .5333333333vw);
  }
}

.s-contact_header-att {
  text-align: right;
  font-size: .7333333333rem;
  margin-bottom: 8px;
}

@media only screen and (max-width: 750px) {
  .s-contact_header-att {
    font-size: .6666666667rem;
    margin-bottom: 6px;
  }
}

.s-contact_header-tel {
  margin-bottom: 46px;
}

@media only screen and (max-width: 750px) {
  .s-contact_header-tel {
    margin-bottom: 39px;
  }
}

.s-contact_recaptcha {
  margin-bottom: 30px;
  padding-top: 6px;
}

@media only screen and (max-width: 750px) {
  .s-contact_recaptcha {
    margin-bottom: 27px;
  }
}

.s-contact_field-ttl {
  display: block;
  margin-bottom: 7px;
}

@media only screen and (max-width: 750px) {
  .s-contact_field-ttl {
    margin-bottom: 7px;
  }
}

.s-contact_form-wrap {
  width: 54.6875vw;
  margin: 0 auto;
  font-size: 1.0666666667rem;
  padding: 54px 0 220px 0;
  border-top: 1px solid #BFBCB4;

  input {
    &[type=text], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
      background-color: #DCD8D0;
      border: 1px solid #DCD8D0;
      border-radius: 6px;
      padding: 19px 30px 19px 30px;
      width: 100% !important;
      font-size: 1.0666666667rem;
    }
  }

  button, textarea, select {
    background-color: #DCD8D0;
    border: 1px solid #DCD8D0;
    border-radius: 6px;
    padding: 19px 30px 19px 30px;
    width: 100% !important;
    font-size: 1.0666666667rem;
  }

  input {
    &[type=text]:focus, &[type=tel]:focus, &[type=email]:focus, &[type=checkbox]:focus, &[type=radio]:focus, &[type=submit]:focus {
      outline: none;
      border: 1px solid #1F2C5C !important;
      border-radius: 6px;
    }
  }

  button:focus, textarea:focus, select:focus {
    outline: none;
    border: 1px solid #1F2C5C !important;
    border-radius: 6px;
  }

  textarea {
    padding: 24px 30px 19px 30px;
  }

  input {
    &[type=text]:focus, &[type=tel]:focus, &[type=email]:focus {
      border: 1px solid #1F2C5C;
    }
  }

  textarea:focus, select:focus {
    border: 1px solid #1F2C5C;
  }

  input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
    color: #8E8C86;
  }

  input:-moz-placeholder, textarea:-moz-placeholder, input::-moz-placeholder, textarea::-moz-placeholder {
    color: #8E8C86;
    opacity: 1;
  }

  input:-ms-input-placeholder {
    color: #8E8C86 !important;
  }

  textarea {
    &:-ms-input-placeholder {
      color: #8E8C86 !important;
    }

    height: 240px;
  }

  .field {
    margin-bottom: 24px;
    position: relative;

    input {
      &[type=text], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
        border-color: #DCD8D0;
      }
    }

    button, textarea, select {
      border-color: #DCD8D0;
    }

    .wpcf7-not-valid-tip {
      position: absolute;
      top: 0;
      right: 0;
    }

    .form_err {
      input, textarea, select {
        border-color: #F08300;
      }
    }

    .wpcf7-not-valid-tip {
      color: #F08300;
      font-size: .8rem;
      line-height: 1.2;
      font-weight: bold;
    }
  }
}

@media only screen and (max-width: 1200px) {
  .s-contact_form-wrap {
    font-size: calc((100vw - 376px) / 826 * 2 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_form-wrap {
    width: auto;
    margin: 0 9.6vw;
    padding: 35px 0 140px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_form-wrap {
    font-size: calc(10px + 1.0666666667vw);
  }
}

@media only screen and (max-width: 1200px) {
  .s-contact_form-wrap {
    input {
      &[type=text], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
        font-size: calc((100vw - 376px) / 826 * 2 + 14px);
      }
    }

    button, textarea, select {
      font-size: calc((100vw - 376px) / 826 * 2 + 14px);
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_form-wrap {
    input {
      &[type=text], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
        padding: 19px 20px 18px 20px;
      }
    }

    button, textarea, select {
      padding: 19px 20px 18px 20px;
    }
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_form-wrap {
    input {
      &[type=text], &[type=tel], &[type=email], &[type=checkbox], &[type=radio], &[type=submit] {
        font-size: calc(10px + 1.0666666667vw);
      }
    }

    button, textarea, select {
      font-size: calc(10px + 1.0666666667vw);
    }
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_form-wrap textarea {
    padding: 14px 20px 14px 20px;
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_form-wrap textarea {
    height: 168px;
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_form-wrap .field {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_form-wrap .field .wpcf7-not-valid-tip {
    position: static;
    text-align: right;
    margin: -17px 0 10px 0;
  }
}

@media only screen and (max-width: 1200px) {
  .s-contact_form-wrap .field .wpcf7-not-valid-tip {
    font-size: calc((100vw - 376px) / 826 * 2 + 10px);
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_form-wrap .field .wpcf7-not-valid-tip {
    font-size: calc(10px + 0vw);
  }
}

.s-contact_btn input[type=submit] {
  font-size: 1.2rem;
  padding: 28px 0 23px 0;
  background-color: #F08300;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;

  &:hover {
    opacity: .7;
  }
}

@media only screen and (max-width: 1200px) {
  .s-contact_btn input[type=submit] {
    font-size: calc((100vw - 376px) / 826 * 4 + 14px);
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_btn input[type=submit] {
    padding: 18px 0 17px 0;
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_btn input[type=submit] {
    font-size: calc(10px + 1.0666666667vw);
  }
}

.s-contact_btm-att {
  margin-top: 27px;
  text-align: center;
  font-size: .6666666667rem;
  line-height: 1.6;

  a {
    border-bottom: 1px solid #1F2C5C;
  }
}

@media only screen and (max-width: 750px) {
  .s-contact_btm-att {
    margin-top: 9px;
  }
}

@media only screen and (max-width: 375px) {
  .s-contact_btm-att {
    -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transform-origin: center top;
    transform-origin: center top;
  }
}

.s-contact_thankyou {
  margin-top: 38px;
}

@media only screen and (max-width: 750px) {
  .s-contact_thankyou {
    margin-top: 31px;
  }
}

.p-contact-thankyou {
  .wpcf7-form.sent .wpcf7-response-output {
    display: none !important;
  }

  .s-contact_header-ttl + p {
    margin-top: -1.015625vw;
  }

  .s-contact_header {
    padding-top: 66px;
    padding-bottom: 166px;
  }
}

@media only screen and (max-width: 750px) {
  .p-contact-thankyou .s-contact_header-ttl + p {
    margin-top: -1.8666666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .p-contact-thankyou .s-contact_header {
    padding-top: 49px;
    padding-bottom: 112px;
  }
}

dl.item-sku {
  padding: 0;
  border: none;

  dt {
    padding: 0;
    background-color: transparent;
    display: block;
    border: none;
  }

  dd {
    display: block;
    border: none;
    padding-left: 0;

    &:last-child {
      margin-bottom: 8px;
    }
  }
}

.item_size_name {
  display: none;
}

.wpcf7 .ajax-loader {
  display: block;
  margin: 10px auto 0 auto;
}

.anime-slideup {
  -webkit-transition: transform 0.4s cubic-bezier(0.11, 0.4, 0.33, 0.99) 0s, opacity 0.4s cubic-bezier(0.11, 0.4, 0.33, 0.99) 0s;
  transition: transform 0.4s cubic-bezier(0.11, 0.4, 0.33, 0.99) 0s, opacity 0.4s cubic-bezier(0.11, 0.4, 0.33, 0.99) 0s;
  -webkit-transform: translateY(40px);
  transform: translateY(40px);
  opacity: 0;
}

.js-loaded .anime-slideup.js-scrollaction-active {
  -webkit-transform: translateY(0);
  transform: translateY(0);
  opacity: 1;
}

#luxy {
  pointer-events: none;
}

.js-intersectionfadeout_wrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: height 0.2s;
  overflow: hidden;
}

.js-intersectionfadeout_inner {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #000;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 1;
    background-color: #000;
    transition: opacity 1.2s ease .3s;
  }
}

.js-loaderclose .js-intersectionfadeout_inner:after {
  opacity: 0;
}

.js-intersectionfadeout_body {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: top 0.2s;
  overflow: hidden;
}

.js-textrowmotion-wrapper {
  display: inline-block;
  overflow: hidden;
  position: relative;
  min-width: .3em;
  padding-right: 0.03em;
  margin-right: -0.03em;
  padding-bottom: .2em;
  margin-bottom: -.2em;
}

.js-textrowmotion-txt {
  display: inline-block;
  -webkit-transform: translateY(126%);
  transform: translateY(126%);
  transition: transform 1s cubic-bezier(0.55, 0.01, 0.45, 1);
}

.js-textrowmotion-active .js-textrowmotion-txt {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.js-cardmotion-target {
  opacity: 0;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  transition: opacity 1s cubic-bezier(0.55, 0.01, 0.45, 1), transform 1s cubic-bezier(0.55, 0.01, 0.45, 1);
}

.js-cardmotion.js-cardmotion-active .js-cardmotion-target {
  opacity: 1;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.js-cardmotion-target {
  a:hover {
    opacity: 1 !important;
  }

  figure {
    transition: transform 0.75s cubic-bezier(0, 0.15, 0.3, 1);
    -webkit-transform: scale(1);
    transform: scale(1);
    will-change: transform;
    overflow: hidden;
  }

  img {
    transition: transform 0.75s cubic-bezier(0, 0.15, 0.3, 1);
    -webkit-transform: scale(1);
    transform: scale(1);
    will-change: transform;
  }

  &:hover {
    figure {
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }

    img {
      -webkit-transform: scale(1.1);
      transform: scale(1.1);
    }
  }

  &.slick-cloned:not(.slick-cloned-animation) {
    opacity: 1 !important;
    -webkit-transform: translate3d(0, 0, 0) !important;
    transform: translate3d(0, 0, 0) !important;
  }
}

.js-spnavdivider {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: -100vh;
  left: 0;
  pointer-events: none;
}

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  visibility: visible;
  overflow: auto;
  max-width: 100%;
  max-height: 100%;
}

.simplebar-content {
  &:after, &:before {
    content: " ";
    display: table;
  }
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: inherit;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  right: 2px;
  width: 6px;
  min-height: 10px;

  &:before {
    position: absolute;
    content: "";
    background: #1F2C5C;
    border-radius: 4px;
    left: 0;
    right: 0;
    opacity: 0;
    transition: opacity 0.4s linear;
  }
}

.simplebar-track {
  .simplebar-scrollbar.simplebar-visible:before {
    opacity: .3;
    transition: opacity 0.4s linear;
  }

  &.simplebar-vertical {
    top: 0;
    width: 11px;

    .simplebar-scrollbar:before {
      top: 2px;
      bottom: 2px;
    }
  }

  &.simplebar-horizontal {
    left: 0;
    height: 11px;

    .simplebar-scrollbar {
      &:before {
        height: 100%;
        left: 2px;
        right: 2px;
      }

      right: auto;
      left: 0;
      top: 2px;
      height: 7px;
      min-height: 0;
      min-width: 10px;
      width: auto;
    }
  }
}

[data-simplebar-direction=rtl] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.l-detail-form_form {
  .simplebar-track {
    right: 4px;
  }

  .simplebar-scrollbar:before {
    background: #000;
    border-radius: 4px;
    opacity: 0.1;
  }

  .simplebar-track .simplebar-scrollbar.simplebar-visible:before {
    opacity: 0.15;
    transition: opacity 0s linear;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y pinch-zoom;
  touch-action: pan-y pinch-zoom;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
    outline: none;
  }

  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}

.slick-slider {
  .slick-track, .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before {
    content: "";
    display: table;
  }

  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide {
  img {
    display: block;
  }

  &.slick-loading img {
    display: none;
  }

  &.dragging img {
    pointer-events: none;
  }
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

@font-face {
  font-family: "slick";
  //src: url("./fonts/slick.eot");
  //src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

.slick-prev, .slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev {
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
}

.slick-next {
  &:hover, &:focus {
    outline: none;
    background: transparent;
    color: transparent;
  }
}

.slick-prev {
  &:hover:before, &:focus:before {
    opacity: 1;
  }
}

.slick-next {
  &:hover:before, &:focus:before {
    opacity: 1;
  }
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before, .slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: #fff;
  opacity: .75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;

  li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer;

    button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer;

      &:hover, &:focus {
        outline: none;
      }

      &:hover:before, &:focus:before {
        opacity: 1;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: #000;
        opacity: .25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }

    &.slick-active button:before {
      color: #000;
      opacity: .75;
    }
  }
}

.l-slider {
  .slick-list {
    padding-right: 4.765625vw;
  }

  .slick-slide {
    padding-right: 2.578125vw;

    img {
      width: 100%;
    }
  }
}

@media only screen and (max-width: 750px) {
  .l-slider .slick-list, .l-slider-5item .slick-list {
    padding-right: 29.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider .slick-slide {
    padding-right: 9.0666666667vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-3item .slick-list {
    padding-right: 18.9333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-3item .slick-slide {
    padding-right: 10.6666666667vw;
  }
}

.l-slider-3item .slick-slide img, .l-slider-3item-small .slick-slide img {
  width: 100%;
}

@media only screen and (max-width: 750px) {
  .l-slider-3item-small .slick-list {
    padding-right: 29.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-3item-small .slick-slide {
    padding-right: 9.0666666667vw;
  }
}

.l-slider-item-slide-large .slick-dots {
  bottom: -10px;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  line-height: 1;

  li {
    width: 6px;
    height: 10px;

    button {
      width: 6px;
      height: 10px;
      padding: 0;

      &:before {
        font-size: inherit;
        font-family: inherit;
        content: "";
        border-radius: 50%;
        background-color: #9D9B95;
        width: 6px;
        height: 6px;
        top: 0;
        left: 0;
        opacity: 1;
      }
    }
  }

  .slick-active button:before {
    background-color: #1F2C5C;
    opacity: 1;
  }
}

.l-slider-2item {
  .slick-list {
    padding-right: 20.3125vw;
  }

  .slick-slide {
    padding-right: 3.90625vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-2item .slick-list {
    padding-right: 29.6vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-2item .slick-slide {
    padding-right: 9.0666666667vw;
  }
}

.l-slider-1item {
  .slick-list {
    padding-right: 25.78125vw;

    img {
      width: 100%;
    }
  }

  .slick-slide {
    padding-right: 5.46875vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-1item .slick-list {
    padding-right: 7.7333333333vw;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-1item .slick-slide {
    padding-right: 8vw;
  }
}

.l-slider-1item--2 .slick-list {
  padding-right: 32.8125vw;

  img {
    width: 100%;
  }
}

@media only screen and (max-width: 750px) {
  .l-slider-1item--2 .slick-list {
    padding-right: 7.7333333333vw;
  }
}

@keyframes hover-arrow01 {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes hover-arrow02 {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  10% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
}

.slick-nav_arrow {
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  position: absolute;
  bottom: -2.03125vw;
  width: 4.6875vw;
  height: 4.6875vw;
  max-width: 60px;
  max-height: 60px;
  overflow: hidden;
  cursor: pointer;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  line-height: 0;

  img {
    width: 13px;
    height: 10px;
  }
}

.slick-nav_prev {
  right: 12.1875vw;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.slick-nav_next {
  right: 7.34375vw;
}

.slick-nav_arrow:hover {
  &:before {
    animation: hover-arrow01 1s cubic-bezier(0.19, 1, 0.22, 1);
  }

  &:after {
    animation: hover-arrow02 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
}

.slick-nav_counter {
  font-size: .8rem;
  position: absolute;
  width: 93px;
  height: 30px;
  top: -75px;
  right: calc(9.37vw + 22px);
  display: flex;
  flex-wrap: wrap;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  color: #00001A;
}

@media only screen and (max-width: 1200px) {
  .slick-nav_counter {
    right: calc(4.85vw + 22px);
  }
}

@media only screen and (max-width: 750px) {
  .slick-nav_counter {
    height: auto;
    top: calc(-39px + -2em);
    right: 10.38vw;
    width: auto;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
  }
}

.slick-nav_count-current {
  margin-right: 10px;
}

.slick-nav_count-total {
  margin-left: 10px;
}

// トップページのFeaturedを一番上に変更
@media only screen and (max-width: 750px) {
  .s-top_featured {
    padding-top: 92px;
  }
  .l-contents-intro {
    padding-top: 92px;
  }
}

.s-top_featured {
  padding-top: 117px;
  margin-bottom: 0;
}

.l-contents-intro {
  padding-top: 117px;
}

.l-journal-nav li {
  @apply inline-block;
  @apply relative;
  @apply mr-6;
  @apply align-middle;
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background-color: #1F2C5C;
    position: absolute;
    bottom: 0;
    left: 0;
    -webkit-transform-origin: right top;
    transform-origin: right top;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1);
    transition: transform 0.6s cubic-bezier(0.6, 0.25, 0, 1)
  }
  &:hover:after {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }

  &.js-current:after {
    -webkit-transform-origin: left top;
    transform-origin: left top;
    -webkit-transform: scaleX(1);
    transform: scaleX(1)
  }
}

#user-guide {
  @apply  mx-auto;
  @apply  max-w-none w-full pt-0 mt-0;
  // * {
  //   @apply text-2xl md:text-base;
  // }

  small {
    @apply  text-sm;
  }
  p {
    @apply  mb-8;
  }


  .user-guide_section  {
    @apply  mb-16;
  }
  .user-guide_subsection  {
    @apply  mb-8;
  }


  .user-guide_map {
    @apply  mb-16 md:mb-32;
    @apply  px-4 sm:px-8 md:px-16 lg:px-48 xl:px-56 2xl:px-80;
  }


  .user-guide_guide-imgs  {
    @apply  flex;
    @apply  flex-wrap md:flex-nowrap;
    @apply  mb-8;
    @apply  list-none;
  }
  .user-guide_guide-img-wrap {
    @apply  flex-auto;
    @apply  mb-4 md:mb-0;
    @apply  px-0 md:px-6 lg:px-8 xl:px-10 2xl:px-12;
    @apply  w-1/2 md:w-full;
    @apply  before:content-none; //liにかかってるcontent:"・"の打ち消し
  }
  .user-guide_guide-img {
    @apply  mx-auto;
    @apply  h-auto;
    @apply  w-11/12 md:w-full;
    @apply  cursor-pointer;
  }


  .user-guide_img-mobile-right {
    @apply  text-right md:text-center;
    @apply  max-md:pr-2;
  }
  .user-guide_img-mobile-left {
    @apply  text-left md:text-center;
    @apply  max-md:pl-2;
  }


  .user-guide_flow {
    @apply  flex max-md:flex-wrap;
    @apply  mx-auto mb-16;
    @apply  w-full;
  }
  .user-guide_flow-step {
    @apply  flex items-center justify-start md:justify-center;
    @apply  mx-8 mb-4 md:mx-4;
    @apply  border-none;
    @apply w-full;
    @apply text-center;
    @apply cursor-pointer;

    i{
      @apply  inline-block;

      @apply  left-0;
      @apply  mr-1.5;
      @apply  w-4 min-w-[1rem];
      @apply  h-4;
      background-image: url("../img/ico_arrow_b.svg");
      background-position: 0;
      background-size: 100% auto;
      @apply bg-no-repeat;
      @apply  before:content-none; //liにかかってるcontent:"・"の打ち消し
    }
  }

  .user-guide_text-box {
    @apply  border-2 border-solid rounded;
    @apply  mx-4 mb-32;
    @apply  p-8;


    p {
      @apply  mb-6;
    }
  }
  .user-guide_title {
    @apply  text-xl;
    @apply  font-bold;
  }
  .user-guide_definition{
    @apply  mb-8;
  }

  .user-guide_headline {
    @apply mx-0 md:mx-16 lg:mx-48 xl:mx-56 2xl:mx-80;
  }
  .user-guide_section-content,.user-guide_contact-section-content {
    @apply px-4 sm:px-8 md:px-16 lg:px-48 xl:px-56 2xl:px-80;
  }


  .user-guide_headline1 {
    @apply mb-6  md:mb-16;
    @apply  text-center text-2xl font-bold;
  }
  .user-guide_section-headline2 {
    @apply  border-b-2 border-solid;
    @apply  mb-4 md:mb-8;
    @apply  pb-3;
    @apply  indent-3;
    @apply  text-2xl md:text-3xl font-bold;
  }
  .user-guide_section-headline3 {
    @apply  mb-4;
    @apply  p-3;
    @apply bg-fth-blue;
    @apply  indent-3;
    @apply  text-xl md:text-2xl font-bold;
    color: rgba(225, 222, 214, 1);
  }
  .user-guide_section-headline4 {
    @apply  mb-2;
    @apply  text-lg font-bold;
  }
  .user-guide_section-contact-headline3 {
    @apply flex items-center;
    @apply  py-3 px-0;
    @apply  text-2xl font-bold;


    .user-guide_icon  {
      @apply inline-block;
      @apply mr-3;
      @apply w-10;
      @apply h-10;
    }
  }


  .user-guide_table {
    @apply  border border-collapse border-solid;
    @apply  mb-4;
    border-color: rgba(143, 149, 173, 1);
    @apply  min-w-fit;
  }
  .user-guide_table-th  {
    @apply  border border-solid;
    border-color: rgba(143, 149, 173, 1);
    @apply  p-3;
    background: rgba(99, 108, 141, 1);
    color: rgba(225, 222, 214, 1);
    @apply  text-center font-bold;
  }
  .user-guide_table-td  {
    @apply border border-solid;
    border-color: rgba(143, 149, 173, 1);
    @apply p-3;
  }
  .user-guide_table-size {
    @apply min-w-fit;
  }

  .user-guide_register-button-wrap {
    @apply rounded-lg;
    @apply mx-auto mb-8;
    @apply p-0;
    @apply w-4/5 md:w-2/5;
    @apply bg-fth-orange;
    @apply text-center;


    .user-guide_register-button {
      @apply block;
      @apply border-none;
      @apply text-xl font-bold;
      @apply py-4 px-6;
    }
  }


  .user-guide_contact-button-wrap {
    @apply rounded-lg;
    @apply mb-8;
    @apply p-0;
    @apply w-full md:w-2/5;
    background: rgba(187, 191, 206, 1);
    @apply text-center;


    .user-guide_contact-button {
      @apply block;
      @apply border-none;
      @apply py-4 px-6;
      @apply text-lg font-bold;
      color: rgba(31, 44, 92, 1);
    }
  }

  .user-guide_text-text-point-align {
    @apply  text-center;
  }
  .user-guide_text-point-color {
    @apply text-fth-orange;
  }

  .user-guide_shopping-img-wrap {
    @apply  text-center;
  }
  .user-guide_shopping-img  {
    @apply  border border-solid;
    @apply  w-3/4;
  }
  .user-guide_shopping-sp-img  {
    @apply inline md:hidden;
  }
  .user-guide_shopping-pc-img  {
    @apply hidden md:inline;
  }

  .user-guide_contact-link  {
    @apply font-bold text-fth-orange;
    @apply border-none;
  }

  .user-guide_question  {
    @apply  mb-4;
    @apply  text-xl font-bold;
  }
  .user-guide_answer  {
    @apply mb-8;
    @apply px-8 md:px-14;
    @apply md:text-xl;
    @apply flex;

    .user-guide_answer-arrow{
      @apply  w-1/12;
      @apply  inline-block;
      @apply  left-0;
      @apply  mr-1.5;
      @apply  pt-7 md:pt-6;
      @apply  w-4 min-w-[1rem];
      @apply  h-4;
      background-image: url("../img/ico_arrow_b.svg");
      background-position: 0;
      background-size: 100% auto;
      @apply bg-no-repeat;
      @apply  before:content-none; //liにかかってるcontent:"・"の打ち消し
    }
    .user-guide_answer-definition{
      @apply  w-10/12;
    }
  }

  .user-guide_cache-imgs  {
    @apply  flex flex-row flex-wrap md:flex-nowrap items-center;
    @apply  w-full md:w-11/12;
    @apply  list-none;
    @apply  mt-10;
  }
  .user-guide_cache-card-img-wrap {
    @apply  px-2;
    @apply  text-center;
    @apply  before:content-none; //liにかかってるcontent:"・"の打ち消し
  }
  .user-guide_cache-img-wrap {
    @apply  px-2 pb-4;
    @apply  text-center;
    @apply  before:content-none; //liにかかってるcontent:"・"の打ち消し
  }
  .user-guide_cache-img-wide-wrap {
    @apply  px-2 pb-4;
    @apply  text-center;
    @apply  before:content-none; //liにかかってるcontent:"・"の打ち消し
  }
  .user-guide_cache-img-wide {
    @apply h-12 align-middle;
  }
  .user-guide_cache-img {
    @apply  h-12 align-middle;
  }

  .user-guide_note-list {
    @apply  list-none;
  }
  .user-guide_note-list-item {
    @apply pl-0;
  }

}

.user-guide_text-point-style {
  @apply font-bold;
}

.user-guide_top-step-wrap{
  @apply flex flex-row-reverse sm:hidden;
  @apply fixed;
  @apply bottom-0;
  @apply w-full;
  @apply z-50;
}
.user-guide_top-step {
  @apply w-12 h-12;
  @apply p-2;
  @apply border-none;
  @apply cursor-pointer;
  background: rgba(187, 191, 206, 1);
  @apply text-center;
  i{
    @apply w-4 h-4;
    background-image: url("../img/ico_arrow_b.svg");
    @apply bg-center;
    @apply bg-no-repeat;
    @apply inline-block;
    @apply content-none;
    @apply -rotate-90;
    @apply align-middle;
    @apply bg-cover;
  }
}

  .description-arrow {
    @apply  inline-block;
    @apply  left-0;
    @apply  ml-1.5;
    @apply  w-4 min-w-[1rem];
    @apply  h-4;
    @apply bg-no-repeat;
    @apply bg-bottom;
    background-image: url("../img/ico_arrow_b.svg");
    background-size: 100% auto;
    @apply  before:content-none; //liにかかってるcontent:"・"の打ち消し
    &.orange{
      background-image: url("../img/ico_arrow_b2.svg");
    }
  }

.s-item_nav-img-set-ttl {
  @apply text-slate-100;
}

.s-item_nav-category-list {
  @apply ml-5;
  list-style: "-";
}

.s-item_nav-ttl {
  @apply text-sm;
  @apply mb-0;
  @apply cursor-pointer;
}

.current a {
  border-bottom: 1px solid #1F2C5C;
}
.current .s-item_nav-category-background a span{
  border-bottom: 1px solid #ffffff;
}
.current-child div span {
  border-bottom: 1px solid #1F2C5C;
}
.new a {
  color: #F08300;
}

.s-item_nav-subttl {
  @apply mb-3;
  @apply indent-1;
  @apply text-sm;
  @apply cursor-pointer;
}

.s-item_nav-line {
  @apply mt-3;
  border-bottom: 1px solid rgb(191, 188, 180);
}

.s-item_nav-ttl-border {
  border-bottom: 1px solid #BFBCB4;
}
.s-item_nav-child-pc {
  list-style: "-";
}

.s-item-nav-parent{
  position: relative;

  -webkit-transition: border-bottom 0s;
  transition: border-bottom 0s;
  -webkit-transition-delay: .3s;
  transition-delay: .3s;

  &:after {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border: 1px solid #1F2C5C;
    border-top: none;
    border-left: none;
    position: absolute;
    right: 10px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: top 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
    transition: top 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
  }
}
.s-item-nav-parent.open {
    -webkit-transition: border-bottom 0s;
    transition: border-bottom 0s;
  &:after {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}

.s-item-nav-parent2{
  position: relative;

  -webkit-transition: border-bottom 0s;
  transition: border-bottom 0s;
  -webkit-transition-delay: .3s;
  transition-delay: .3s;

  &:before {
    content: "";
    display: block;
    width: 5px;
    height: 5px;
    border: 1px solid #1F2C5C;
    border-top: none;
    border-left: none;
    position: absolute;
    left: 0px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition: top 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
    transition: top 0.3s, transform 0.3s cubic-bezier(0.19, 0.82, 0.27, 1);
  }
}
.s-item-nav-parent2.open {
    -webkit-transition: border-bottom 0s;
    transition: border-bottom 0s;
  &:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}

.s-item_nav-category-background {
  @apply  mb-0.5;
  @apply  bg-no-repeat;
  @apply  bg-left;
  @apply  bg-center;
  @apply  bg-cover;
}

.s-item_nav-ttl-border-none {
  @apply border-none;
  border-bottom: none;
  -webkit-transition: border-bottom 0s;
  transition: border-bottom 0s;
}

.s-item_nav-spacial {
  @apply mb-6;
}
.s-item_nav-sub {
  @apply ml-2;
}

.gnav_categorys {
  padding-bottom: 0.5rem !important;
}
.gnav_feature-title  {
  font-size: 1.5rem !important;
  margin-bottom: 10px !important;
}
.gnav_sidenav-aginggallery {
  padding-top: 13px !important;
}
.s-item-archives_nav-sub-list {
  margin-top: 0 !important;
}

.s-item-archives_nav-subttl-margin {
  margin-bottom: 10px !important;
}

.s-item_nav-sub-width-full {
  width: 100% !important;
}

.s-item_nav-sub-width-pc {
  width: 70% !important;
}

.l-gnav_item-menu {
  height: calc(100vh - 104px);
  padding-bottom: 79px;
  @apply overflow-y-scroll;
}

@import "keito_sp";
@import "vmd";
@import "vmd_tshirt";
@import "vmd_silver";
@import "itempage_banner";
@import "cart";
@import "aginggallery";
@import "jeansfeature";
@import "cart/cart";
