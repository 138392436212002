#vmd, #vmd_tshirt {
  .s-journal_main-ttl {
    .s-top_main-visual-ttl {
      line-height: .85;
      margin-bottom: 1.5rem;
    }

    .s-top_main-visual-ttl-row {
      display: block;
      margin-top: -0.1em;
    }
  }

  .s-top_main-contents .ttl {
    font-size: 1.5rem;
    line-height: 1.25;
  }

  .s-journal-detail_related {
    border: none;
    margin-bottom: 0;
  }

  .s-journal-detail_related + .l-content-item-choice2 {
    border-top: 1px solid #bfbcb4;
    padding-top: 100px;
  }

  .l-slider-5item a {
    padding: 0 .5rem;
  }
}

#vmd_detail_list {
  .detail-left {
    & > div:nth-child(1) {
      @apply order-2 md:order-1;
      @apply px-0 md:pr-12 flex flex-col justify-between;
    }

    & > div:nth-child(2) {
      @apply order-1 md:order-2;
      @apply px-8 md:pl-12 md:pr-0 mb-12 md:mb-0;
    }
  }

  .detail-right {
    & > div:nth-child(1) {
      @apply order-2 md:order-2;
      @apply px-0 md:pl-12 flex flex-col justify-between;
    }

    & > div:nth-child(2) {
      @apply order-1 md:order-1;
      @apply px-8 md:pr-12 md:pl-0 mb-12 md:mb-0;
    }
  }
}
